import { Modal, VStack,FormControl,Button, Input } from "native-base";
import React, { useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import LoginForm from './LoginForm';
import {useDispatch,useSelector} from 'react-redux';
import { useNavigation } from "@react-navigation/native";

import { createAction, isValidEmail,  isValidName, isValidPassword, isValidPhoneNo, passwordsMatch  } from "@utils";
import { FontFamily, Padding, Color, FontSize, Border, LocalizedString } from "@common";
import { Dialog } from "@rneui/base";
import ForgotPasswordForm from "./ForgotPasswordForm";
import AccountForm from "./AccountForm";
import {TERMS_AND_CONDITIONS_URL} from '@constants'
import DismissableToast from './DismissableToast';
import { useToast } from 'native-base';

const DesktopLoginModal = ({ modalVisible,setModalVisible,setShowSetting }) => {
  const navigation = useNavigation();
  const [_, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState('');
  const [isLoading, setLoading] = useState('');
  const language = useSelector((state) => state.config.language);
  const dispatch = useDispatch();
  const showModalLogin = useSelector((state) => state.member.showModalLogin);
  const isDesktopWeb = useSelector((state) => state.member.isDesktopWeb);
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [pageType, setPageType] = useState('login'); // login, forgotPassword, signup
  const toast = useToast();

  const loginButtonPressed = () => {
        
    if (!isValidEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }
  
    // Validate the password (for example, check if it has at least 8 characters)
    if (password.length < 8) {
      alert('Password must be at least 8 characters long.');
      return;
    }

    let callback = (eventObject) => {
      //console.log('eventObject',eventObject);
      //console.log('isDesktopWeb', isDesktopWeb)
      
      if (eventObject.success) {
        if (isDesktopWeb) {
          closeModalAndReset()
        }else{
          if (showModalLogin) {
            // navigation.popToTop();
            // Then, close the MainModal navigator and go back to the original screen
            navigation.goBack();
          }else{
            navigation.navigate('Home');
          }          
        }
       
      } else {
        alert(eventObject.message);
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
  
    }
  
    setLoading(true);
    dispatch(createAction('member/loadLogin')({"memberId": email, "password": password,"rememberMe":rememberMe,callback}));    
    
  };

  const handleResetPassword = () => {
    
    if (!isValidEmail(forgotEmail)) {
      // Alert or display a message that the email is not valid
      alert('Please enter a valid email address.');
      return;
    }
    // Proceed with the password reset action
    // This could involve calling an API to send a reset link to the email
    let callback = (eventObject) => {
      //console.log('eventObject',eventObject);
      
      setLoading(false);
      if (eventObject.success) {
        toast.show({
          placement: "top",
          duration: null,
          render: ({
            id
          }) => {
            return <DismissableToast id={id} toast={toast} description={LocalizedString.forgetPasswordSuccessful} />;
          }
        })
        
        //console.log(isDesktopWeb)

        setTimeout(() => {
          if (isDesktopWeb) {
            closeModalAndReset();
          } else {
            if (showModalLogin) {
              // navigation.popToTop();
              // Then, close the MainModal navigator and go back to the original screen
              navigation.goBack();
            } else {
              navigation.navigate('Home');
            }          
          }
        }, 500);        
      } else {
        alert(eventObject.message);
      }
      
    }
  
    setLoading(true);
    dispatch(createAction('member/loadResetPassword')({"memberId": forgotEmail,callback}));
  
  };  

  const onTermsAndConditionPressed = () => {
      window.open(TERMS_AND_CONDITIONS_URL, '_blank');
  }

  const forgotPasswordButtonPressed = () => {
      resetForm();
      setPageType('forgotPassword');
      
  }
 
  const createAccountButtonPressed = () => {
        resetForm();
        setPageType('signup');
        
  }

  const signInButtonPressed = () => {
        resetForm();
        setPageType('login');
        
  }

  const  onSignUpPressed = () => {
    
    //console.log(`onSignUpPressed`);

   
    if (!isValidEmail(email)) {
      alert(LocalizedString.invalidEmail);
      return;
    }
  
    if (!isValidName(name)) {
      alert(LocalizedString.invalidName);
      return;
    }
  
    if (!isValidPhoneNo(phoneNo)) {
      alert(LocalizedString.invalidPhoneNo);
      return;
    }
  
    if (!isValidPassword(password)) {
      alert(LocalizedString.invalidPassword);
      return;
    }
  
    if (!passwordsMatch(password, passwordConfirmation)) {
      alert(LocalizedString.passwordsNotMatch);
      return;
    }
  
    
    let callback = (eventObject) => {
      if (eventObject.success) {
        toast.show({
          placement: "top",
          duration: null,
          render: ({
            id
          }) => {
            return <DismissableToast id={id} toast={toast} description={LocalizedString.registrationSuccessful} onDismissed={onDismissed} />;
          }
        })

        let onDismissed = () => {
          if (isDesktopWeb) {
            setTimeout(() => {
              closeModalAndReset()
            }, 2000)
            
          }else{
            if (showModalLogin) {
              // navigation.popToTop();
              // Then, close the MainModal navigator and go back to the original screen
              setTimeout(() => {
                navigation.goBack();
              }, 2000)
              
            }else{
              setTimeout(() => {
                navigation.navigate('Home');
              }, 2000)
            }          
          }
        }
        
      } else {
        alert(eventObject.message);
      }

      setLoading(false);
    }

    dispatch(createAction('member/loadRegister')({name,email,password,phoneNo,callback}));
    setLoading(true);
          
  };

  const onSkipButtonPressed = () => {
    dispatch(createAction('member/saveSkipLoginToStorage')({skipLogin:true}))
    closeModalAndReset();
  }

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setForgotEmail('');
    setName('');
    setPhoneNo('');
    setPasswordConfirmation('');
  }

  const closeModalAndReset = () => {
    setModalVisible(false);

    if (setShowSetting !== undefined) {
      setShowSetting(false);
    }

    setTimeout(() => {
      setPageType('login');
      resetForm();
    }, 100);

  }
    
  return (
    
  <Modal isOpen={modalVisible} onClose={closeModalAndReset} avoidKeyboard size="lg">

    <Modal.Content>
      <Modal.CloseButton />
      <Modal.Body>
      <View style={styles.signup}>
      <View style={styles.container}>

        {pageType === 'login' ? 
           <LoginForm
           email={email}
           setEmail={setEmail}
           password={password}
           rememberMe={rememberMe}
           setPassword={setPassword}
           setRememberMe={setRememberMe}
           showModalLogin={showModalLogin}
           loginButtonPressed={loginButtonPressed}
           forgotPasswordButtonPressed={forgotPasswordButtonPressed}
           createAccountButtonPressed={createAccountButtonPressed}
           skipButtonPressed={onSkipButtonPressed}
         />        
        : undefined}

        {pageType === 'forgotPassword' ?
            <ForgotPasswordForm email={forgotEmail} setEmail={setForgotEmail} handleResetPassword={handleResetPassword} />    
            : undefined}

        {pageType === 'signup' ?
            <AccountForm onSignUpPressed={onSignUpPressed} onTermsAndConditionPressed={onTermsAndConditionPressed} 
              name={name} setName={setName} email={email} setEmail={setEmail} password={password} setPassword={setPassword}
              phoneNo={phoneNo} setPhoneNo={setPhoneNo} 
              passwordConfirmation={passwordConfirmation} setPasswordConfirmation={setPasswordConfirmation}
              signInButtonPressed={signInButtonPressed}              
              />
              : undefined}
        </View>
      </View>

      
   
      <Dialog overlayStyle={styles.dialog}  isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
      </Modal.Body>
    </Modal.Content>
  </Modal>
  
  )
 
};

const styles = StyleSheet.create({
    dialog:{
      maxWidth: 300,
    },
    container: {
      justifyContent: "center",
      alignItems: "flex-end",
    },
    signup: {
      flex: 1,
      width: "100%",    
      paddingHorizontal: Padding.p_5xl,
      paddingVertical: Padding.p_21xl,
      alignItems: "center",
      overflow: "hidden",
      backgroundColor: Color.white,
    },
});

export default DesktopLoginModal;
