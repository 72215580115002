import React from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { Color } from '@common';
const Breadcrumb = ({ items }) => {
  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <TouchableOpacity onPress={item.navigate}>
            <Text style={styles.text}>{item.label}</Text>
          </TouchableOpacity>
          {index < items.length - 1 && <Text style={styles.text}> / </Text>}
        </React.Fragment>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontStyle: 'italic',
    color: Color.darkgray_100
  },
});

export default Breadcrumb;
