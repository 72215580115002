import React, { useState , useEffect } from 'react';
import { Image, StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import { FontFamily, FontSize, Color, Padding, Border,CommonStyle, LocalizedString, windowWidth } from "@common";
import DesktopWrapper from "../components/DesktopWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import {DateTime} from 'luxon';
import DesktopNavbar from "../components/DesktopNavbar";
import DesktopFooter from '../components/DesktopFooter';
import BoardingPass from '../components/BoardingPass';
import { GUEST_ID } from "@constants";

const Verify = ({route, navigation}) => {
    const {refNo, status, date} = route.params

  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState('');
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  useEffect(() => {
    loadVerifyPayment()
  }, []);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
};

const loadVerifyPayment = () => {
    let formattedDate = date || DateTime.now().toFormat('dd-MM-yyyy')
    let callback = (eventObject) => {
      setLoading(false);
      if(eventObject.success && eventObject.result.paymentStatus === '0'){
        switch (eventObject.result.paymentStatus) {
            case '0':
                if (Platform.OS === 'web' && !isDesktop) {
                  navigation.navigate('Payment', {screen: 'PaymentSuccess', params: {refNo,date:formattedDate}})
                }
                else {
                  navigation.navigate('PaymentSuccess', {refNo,date:formattedDate})
                }
                break
            case '3':
              if (Platform.OS === 'web' && !isDesktop) {
                navigation.navigate('Payment', {screen: 'PaymentPending', params: {refNo}})
              }
              else {
                navigation.navigate('PaymentPending', {refNo})
              }
              break
            default:
              if (Platform.OS === 'web' && !isDesktop) {
                navigation.navigate('Payment', {screen: 'PaymentFailed', params: {refNo,status:eventObject.result.paymentStatus}})
              }
              else {
                navigation.navigate('PaymentFailed', {refNo,status:eventObject.result.paymentStatus})
              }
              break
        }
      }else{
        //console.log(eventObject.message)
        if (Platform.OS === 'web' && !isDesktop) {
          navigation.navigate('Payment', {screen: 'PaymentFailed', params: {refNo}})
        }
        else {
          navigation.navigate('PaymentFailed', {refNo})
        }
      }
    }
    setLoading(true);
    dispatch(createAction('trip/loadQueryPayment')({callback,memberId: memberId || GUEST_ID,authToken,refNo,date: formattedDate}))

  }

let containerStyle = isDesktop ? styles.containerDesktop : styles.container;

  return (
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="Verify"/>}>      
 
    <View style={styles.bookingDetails}>
      <View style={styles.centralize}>
        <Text style={CommonStyle.title}>{LocalizedString.bookingDetail}</Text>
      </View>
      <View style={[styles.frame,containerStyle]}>
        <View style={styles.frameParent}>
          <Text style={styles.verifyPayment}>{LocalizedString.verifyingPayment}</Text>
        </View>  
      </View>
    </View>
    <Desktop>
      <View style={CommonStyle.withDesktopFooter}></View>
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    </Desktop>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  fromToSeatTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  kprKamparLarTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.black,
    textAlign: "left",
  },
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout1: {
    height: 18,
    width: 18,
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  frameChild: {
    width: 38,
    height: 38,
  },
  coach: {
    color: Color.black,
    width: 135
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
  },
  groupParent: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  fromToSeat: {
    color: Color.black,
  },
  kprKamparLar: {
    marginLeft: 21,
    lineHeight: 24,
    fontSize: FontSize.size_sm,
    fontFamily: FontFamily.interRegular,
  },
  fromToSeatContactNoIcParent: {
    
    marginTop: 11,
    flexDirection: "row",
  },
  frameGroup: {
    height: 279,
    paddingBottom: Padding.p_5xl,
    alignItems: "center",    
  },
  boardingPassesText: {
    marginTop: 15,
    textAlign:"center"
  },

  text: {
    width: 58,
  },

  frameView: {
    marginTop: 16,
    flexDirection: "row",
  },
  frameParent: {    
    alignSelf: "center",   
    paddingHorizontal: 20,
    marginBottom: 20
  },
  icon: {
    width: 31,
    height: 9,
  },
  bookFlight: {
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    textAlign: "center",
    width: 192,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    paddingHorizontal: Padding.p_base,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  frame: {
    flex: 1,
  },
  bookingDetails: {
    flex: 1,
    width: "100%",    
    backgroundColor: Color.gray_100,
    alignItems:"center",
    justifyContent:"center",
    marginBottom: 20
  },
  centralize:{
    width:"100%",
    alignItems:"center",
    justifyContent:"center",
  },
  containerDesktop: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",        
    marginTop: 9,
    width: 800,
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingTop: 20,
  },
  container:{
    width: windowWidth,
    paddingTop: 20,
  },
  coachInfoDiv: {
    width: '100%'
  },
  coachInfo: {
    flexDirection: 'row'
  },
  verifyPayment: {
    fontFamily: FontFamily.interMedium,
    fontWeight: '600'
  }
});

export default Verify;
