import React, { useState , useEffect } from 'react';
import { Image, StyleSheet, View, Text, Pressable } from "react-native";
import DesktopWrapper from "../components/DesktopWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import { Color, FontFamily, Padding, FontSize, Border, LocalizedString, CommonStyle } from "@common";
import DesktopNavbar from "../components/DesktopNavbar";
import DesktopFooter from '../components/DesktopFooter';

const PaymentPending = ({route, navigation}) => {
  const {refNo} = route.params
  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState('');
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };

  const onBackToHomePressed = () => {
    if (isDesktop) {
      navigation.navigate("DesktopHome")
    }
    else {
      if (Platform.OS === 'web') {
        navigation.navigate('Home', {screen: 'MobileHome'})
      }
      else {
        navigation.navigate('MobileHome')
      }
    }
  }

  return (
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="PaymentSuccess"/>}>      
      <View style={[styles.paymentFailed, styles.topBarFlexBox]}>
          <View style={styles.parentFlexBox}>
            <View
              style={[
                styles.cercleclosedeletedismissreParent,
                styles.parentFlexBox,
              ]}
            >
              <Image
                style={styles.cercleclosedeletedismissreIcon}
                resizeMode="cover"
                source={require("../assets/clock-solid.png")}
              />
              <Image
                style={styles.flatCrossIcon1}
                resizeMode="cover"
                source={require("../assets/flat-cross-icon-1.png")}
              />
              <Text style={[styles.paymentFailed1, styles.bookFlightClr]}>
                {LocalizedString.paymentPendingTitle}
              </Text>
            </View>
            <Text style={styles.kprKamparLar}>{LocalizedString.paymentPending}</Text>
            <Text style={[styles.kprKamparLar, styles.failedRef]}>Ref: {refNo}</Text>
            <Pressable
              style={[styles.buttonBig, styles.headerFlexBox]}
              onPress={onBackToHomePressed}
            >
              <Text style={[styles.confirm, styles.confirmTypo]}>{LocalizedString.backToHome}</Text>
            </Pressable>
          </View>
        </View>
      <Desktop>
        <View style={CommonStyle.withDesktopFooter}></View>
        <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
      </Desktop>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  topBarFlexBox: {
    alignItems: "center",
    backgroundColor: Color.gray_100,
    overflow: "hidden",
  },
  wifiIconLayout: {
    height: 18,
    width: 18,
  },
  headerFlexBox: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  confirmTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  parentFlexBox: {
    width: 330,
    justifyContent: "center",
    alignItems: "center",
  },
  bookFlightClr: {
    color: Color.black,
    fontSize: FontSize.size_xl,
  },
  icon: {
    width: 31,
    height: 9,
  },
  wifiIcon: {
    overflow: "hidden",
  },
  frameChild: {
    marginLeft: 3.07,
  },
  batteryIcon: {
    width: 19,
    height: 19,
    marginLeft: 3.07,
    overflow: "hidden",
  },
  androidStatusBars1: {
    flexDirection: "row",
  },
  androidStatusBars2: {
    width: 360,
    justifyContent: "space-between",
    paddingVertical: 0,
    paddingHorizontal: Padding.p_base,
    height: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  androidStatusBars: {
    paddingTop: Padding.p_5xs,
  },
  bookFlight: {
    lineHeight: 32,
    width: 192,
    display: "none",
    color: Color.black,
    fontSize: FontSize.size_xl,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    paddingVertical: 0,
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    paddingHorizontal: Padding.p_base,
  },
  cercleclosedeletedismissreIcon: {
    width: 30,
    height: 30,
  },
  flatCrossIcon1: {
    width: 16,
    height: 16,
    marginLeft: 14,
    overflow: "hidden",
  },
  paymentFailed1: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    textAlign: "left",
    width: 203,
    lineHeight: 24,
    marginLeft: 14,
  },
  cercleclosedeletedismissreParent: {
    marginTop: 10,
    flexDirection: "row",
  },
  at41: {
    width: 181,
    height: 141,
    marginTop: 15,
  },
  confirm: {
    fontSize: FontSize.size_lg,
    color: Color.white,
    width: 120,
    lineHeight: 24,
  },
  buttonBig: {
    alignSelf: "stretch",
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_base,
    marginTop: 15,
  },
  paymentFailed: {
    flex: 1,
    width: "100%",
    height: 800,
  },
  kprKamparLar: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: FontSize.size_sm,
    fontFamily: FontFamily.interRegular,
  },
  failedRef: {
    textAlign: 'start',
    fontWeight: '700',
  }
});

export default PaymentPending;
