import React, { useState } from 'react';
import { Text, StyleSheet, View, TextInput, KeyboardAvoidingView, ScrollView,Platform } from "react-native";
import { Dialog, Button } from "@rneui/themed";
import { Padding, FontFamily, FontSize, Color, Border, LocalizedString } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";
import BtnLanguage from "../components/BtnLanguage";
import { createAction } from "@utils";
import {useDispatch,useSelector} from 'react-redux';
import { isValidEmail } from "@utils";
import LoginForm from '../components/LoginForm';

const EmailLogin = ({navigation}) => {
  const [_, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState('');
  const [isLoading, setLoading] = useState('');
  const language = useSelector((state) => state.config.language);
  const dispatch = useDispatch();
  const showModalLogin = useSelector((state) => state.member.showModalLogin);
  const isDesktop = useSelector((state) => state.config.isDesktopWeb);

  const languageSelected = (language) => {
    
      //console.log(`Language selected ${language}`);
      LocalizedString.setLanguage(language);
      forceUpdate({}); // Use this line to force a re-render    
  };

  const skipButtonPressed = () => {
    dispatch(createAction('member/saveSkipLoginToStorage')({skipLogin:true}));
    if (showModalLogin) {
      navigation.navigate('Seats')
    }
    else {
      navigation.navigate("MobileHome")
    }
    // if (Platform.OS === 'web') {
    //   navigation.navigate("MobileHome")
    // }else{
    //   dispatch(createAction('member/saveSkipLoginToStorage')({skipLogin:true}));
    // }
  }
  
   const createAccountButtonPressed = () => {
    
    navigation.navigate('Signup'); // The name of the Register screen in your navigator
  };

  const loginButtonPressed = () => {
        
    if (!isValidEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }
  
    // Validate the password (for example, check if it has at least 8 characters)
    if (password.length < 8) {
      alert('Password must be at least 8 characters long.');
      return;
    }

    let callback = (eventObject) => {
      //console.log('eventObject',eventObject);
      
      if (eventObject.success) {
        if (isDesktop) {
          if (showModalLogin) {
            navigation.goBack()
          }
          else {
            navigation.navigate('Home')
          }
        }
        else {
          navigation.goBack()
        }        
      } else {
        alert(eventObject.message);
      }
      setTimeout(() => {
        setLoading(false);
      }, 100);
  
    }
  
    setLoading(true);
    dispatch(createAction('member/loadLogin')({"memberId": email, "password": password,"rememberMe":rememberMe,callback}));    
  };
 

  const forgotPasswordButtonPressed = () => {
    
    navigation.navigate('ForgotPassword'); // The name of the Register screen in your navigator
  }
  
  return (
    <KeyboardAvoidingView
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={{ flex: 1 }}
  >    
    <ScrollView style={styles.scrollContainer}>
    <View style={styles.emailLogin}>
    <View style={styles.container}>   
    <BtnLanguage onSelectLanguage={languageSelected} language={language} />
    <LoginForm
              email={email}
              setEmail={setEmail}
              password={password}
              rememberMe={rememberMe}
              setPassword={setPassword}
              setRememberMe={setRememberMe}
              loginButtonPressed={loginButtonPressed}
              forgotPasswordButtonPressed={forgotPasswordButtonPressed}
              showModalLogin={showModalLogin}
              showSkipButton={true}
              skipButtonPressed={skipButtonPressed}
              createAccountButtonPressed={createAccountButtonPressed}
            />
      </View>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
    </View>
    </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  inputEmailTextInputInput: {
    height: 48,
  },
  inputPasswordTextInputInput: {
    height: 48,
  },
  btnLoginBtn: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
  },
  textinputtitleTypo: {
    height: 24,
    color: Color.black1,
    fontSize: FontSize.size_base,
    textAlign: "left",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
  },
  btnTypo: {
    textAlign: "center",
    height: 24,
    fontSize: FontSize.size_base,
    width: 312,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    lineHeight: 24,
  },
  login: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    width: 312,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    color: Color.black,
    textAlign: "left",
  },
  subtitle: {
    fontSize: FontSize.size_lg,
    lineHeight: 26,
    fontFamily: FontFamily.interRegular,
    marginTop: 12,
    color: Color.gray555,
    width: 312,
    textAlign: "left",
  },
  textinputtitle: {
    width: 312,
  },
  inputEmail: {
    borderRadius: Border.br_5xs,
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    marginTop: 8,
    alignSelf: "stretch",
  },
  email: {
    marginTop: 24,
  },
  btnForgotPassword: {
    textAlign: "right",
    width: 162,
    marginLeft: 4,
    color: Color.steelblue_200,
  },
  passwords: {
    flexDirection: "row",
  },
  password: {
    marginTop: 24,
  },
  btnSkip: {
    marginTop: 24,
    color: Color.gray555,
  },
  btnCreateAccount: {
    marginTop: 54,
    color: Color.steelblue_200,
  },
  containerForm: {
    paddingBottom: 99,
    marginTop: 30,
    alignSelf: "stretch",
    alignItems: "center",
  },
  emailLogin: {
    flex: 1,
    width: "100%",
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: Padding.p_21xl,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: Color.white,
  },
  container: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
});


export default EmailLogin;
