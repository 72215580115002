import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAction } from "@utils";
function getLanguage() {
  return AsyncStorage.getItem('@language', (err, result) => {
    // log('@profile ', result);
    if (err) {
      log('@language Error: ', err);
    }

    return result;
  });
}

function saveLanguage(language) {
  AsyncStorage.setItem('@language', language);
}

export default {
  namespace: 'config',
  state: {
    language: 'en',
  },
  reducers: {            
    setLanguage(state, {payload}) {
        //console.log('setLanguage', payload.language);
        saveLanguage(payload.language); // Use payload.language
        return {
          ...state,
          language: payload.language ?? null, // Use payload.language
        };
    },       
  },
  effects: {  
    *updateLanguage({payload}, {call, put, select}) {
        try {
          const {language} = payload;
          yield put(createAction('setLanguage')({language}));          
        } catch (err) {
          //console.log('err', err);

        }
    },
    *loadLanguageFromCache({payload}, {call, put, select}) {      
        try {
          const language = yield call(getLanguage);

          //console.log('language', language);
          if (language) {
            yield put(createAction('setLanguage')({language}));          
          }
        } catch (err) {
          //console.log('err', err);

        }
      },        
  },
};
