import { Alert, VStack, HStack, IconButton, CloseIcon } from 'native-base'
import { Text, StyleSheet } from 'react-native'
import { LocalizedString, FontFamily } from '@common'

const DismissableToast = ({toast, description, onDismissed}) => {
    const onDismissedCallback = () => {
      toast.closeAll()
      onDismissed && onDismissed()
    }
  
    return (
      <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status="info">
        <VStack space={1} flexShrink={1} w="100%">
          <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
            <HStack space={2} flexShrink={1} alignItems="center">
              <Alert.Icon />
              <Text color={styles.text}>
                {description}
              </Text>
            </HStack>
            <IconButton icon={<CloseIcon size="3" />} _icon={{
            color: "warmGray.50"
          }} onPress={onDismissedCallback} />
          </HStack>
        </VStack>
      </Alert>
    )
  };

  const styles = StyleSheet.create({
    text: {
      fontFamily: FontFamily.interRegular
    }
  })

  export default DismissableToast;