/**
 * @format
 */
import 'react-native-gesture-handler';
import {AppRegistry , Platform} from 'react-native';
import App from './App';
import {name as appName} from './app.json';
import {registerRootComponent} from 'expo';


if (Platform.OS === 'web') {
    registerRootComponent(App);
} else {
    AppRegistry.registerComponent(appName, () => App);
}