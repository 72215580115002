import React, { useState, useEffect } from "react";
import { Image, StyleSheet, View, Pressable, Text, TextInput, KeyboardAvoidingView, ScrollView,Platform } from "react-native";
import { Padding, FontSize, FontFamily, Color, Border, CommonStyle, LocalizedString } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useSelector, useDispatch } from "react-redux";
import { createAction, Default, Desktop } from "@utils";
import DesktopNavbar from "../components/DesktopNavbar";
import TripComponent from "../components/TripComponent";
import TicketDetailsSection from "../components/TicketDetailsSection";
import TicketFeeSummary from "../components/TicketFeeSummary";
import DesktopPaymentFooter from "../components/DesktopPaymentFooter";
import DesktopWrapper from '../components/DesktopWrapper';
import Breadcrumb from "../components/Breadcrumb";

const PersonalInfo = ({navigation}) => {

  const dispatch = useDispatch();
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  
  const language = useSelector((state) => state.config.language); 
  const [_, forceUpdate] = useState();

  const memberId = useSelector((state) => state.member.memberId);

  const [contactNumber, setContactNumber] = useState("");
  const [icPassportNumber, setIcPassportNumber] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [passengerName, setPassengerName] = useState("");
  const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
  const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
  const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
  const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);
  const includeInsurance = useSelector((state) => state.trip.includeInsurance);
  const selectedTrip = useSelector((state) => state.trip.selectedTrip);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const seatResult = useSelector((state) => state.trip.seatResult);
  const seats = useSelector((state) => state.trip.selectedSeats);

  const allTickets = [
    ...Array(adultTicketQuantity).fill({ type: 'Adult', icNumber: '', name: '' }),
    ...Array(childTicketQuantity).fill({ type: 'Child', icNumber: '', name: '' }),
    ...Array(seniorTicketQuantity).fill({ type: 'Senior', icNumber: '', name: '' }),
    ...Array(okuTicketQuantity).fill({ type: 'OKU', icNumber: '', name: '' }),
  ];

  let fare =  0
  let adultTotal = 0
  let childTotal = 0
  let seniorTotal = 0
  let okuTotal = 0
  let subTotal = 0
  let totalTickets = 0
  let fareFacility = 0
  let fareOther = 0
  let fareInsurance = 0
  let grandTotal = 0
  let seatString = ""
  if (seatResult !== null) {
    fare =  seatResult.fare
    adultTotal = adultTicketQuantity*fare.adult
    childTotal = childTicketQuantity*fare.child
    seniorTotal = seniorTicketQuantity*fare.senior
    okuTotal = okuTicketQuantity*fare.disabled
    subTotal = adultTotal+childTotal+seniorTotal+okuTotal
    totalTickets = adultTicketQuantity+childTicketQuantity+seniorTicketQuantity+okuTicketQuantity
    fareFacility = fare.facility * totalTickets
    fareOther = fare.other * totalTickets
    fareInsurance = includeInsurance ? fare.insurance * totalTickets : 0
    grandTotal = subTotal+fareFacility+fareOther+fareInsurance
    seatString = seats.map((seat) => seat).join(",");
  }


  const ticketTypes = [
    { type: 'Adult', quantity: adultTicketQuantity, fare: fare.adult, total: adultTotal },
    { type: 'Child', quantity: childTicketQuantity, fare: fare.child, total: childTotal },
    { type: 'Senior', quantity: seniorTicketQuantity, fare: fare.senior, total: seniorTotal },
    { type: 'Disabled', quantity: okuTicketQuantity, fare: fare.disabled, total: okuTotal },
  ];

  
  const [icPassportNumbers, setIcPassportNumbers] = useState(allTickets);

  useEffect(() => {
    if (isDesktop) {
      if (seats === null || selectedDate === null || selectedTrip === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
    }



  });
  
  const handleInputChange = (text, index) => {
    const newIcPassportNumbers = [...icPassportNumbers];
    newIcPassportNumbers[index] = { ...newIcPassportNumbers[index], icNumber: text };
    setIcPassportNumbers(newIcPassportNumbers);
  };

  const handleNameInputChange = (text, index) => {
    const newIcPassportNumbers = [...icPassportNumbers];
    newIcPassportNumbers[index] = { ...newIcPassportNumbers[index], name: text };
    setIcPassportNumbers(newIcPassportNumbers);
  }

  const toggleCheckbox = () => {
    //console.log('toggleCheckbox')
    dispatch(createAction('trip/setIncludeInsurance')(!includeInsurance))
  };
  
  
  const languageSelected = (language) => {

    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };

  const cancelButtonPressed = () => {
    navigation.goBack();
  }

  const confirmButtonPressed = () => {
    if (contactNumber.length < 5) {
      alert(LocalizedString.contactNoAlert)
      return
    }

    if (icPassportNumber.length < 7) {
      alert(LocalizedString.idNoAlert)
      return
    }

    if (contactEmail.length < 7  && !memberId) {
      alert(LocalizedString.contactEmailAlert)
      return
    }

    let allValid = true;
    const invalidTickets = [];
  
    icPassportNumbers.forEach((ticket, index) => {
      if (ticket.icNumber.length < 7) {
        allValid = false;
        invalidTickets.push(`Passenger #${index + 1} (${ticket.type})`);
      }
    });
  
    if (!allValid) {
      // Show an error message with the invalid ticket types
      const invalidTicketsString = invalidTickets.join(', ');
      alert(`${LocalizedString.idNoPassengerWarning} ${invalidTicketsString}`);
      return
    }

    const passengerIds = icPassportNumbers.map(ticket => ticket.icNumber);
    const passengerNames = icPassportNumbers.map(ticket => ticket.name);
    const passengerTypes = icPassportNumbers.map(ticket => ticket.type);


    //console.log("All IC/Passport Numbers:", passengerIds);
    //console.log("All Passenger Names:", passengerNames);
    //console.log("All Passenger Types:", passengerTypes);


    dispatch(createAction('trip/setContactInfo')({contactNo:contactNumber,contactId:icPassportNumber,passengerId:passengerIds,passengerName:passengerNames,passengerType:passengerTypes,contactEmail:contactEmail}));

    navigation.navigate("MakePayment");
  }

  let containerStyle = isDesktop ? styles.containerDesktop : styles.container;

  if (seats === null || selectedDate === null || selectedTrip === null) {
    return (<View></View>)
  }
  return (
    <KeyboardAvoidingView
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={{ flex: 1, backgroundColor: Color.gray_100, }}
  >
     <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="Personal Info"/>}> 
      <Desktop>
        
        <View style={CommonStyle.desktopSearchView}>        
        <Breadcrumb
              items={[
                { label: LocalizedString.home, navigate: () => navigation.navigate('DesktopHome') },
                { label: LocalizedString.searchResults, navigate: () => navigation.navigate('SearchResult') },
                { label: LocalizedString.tripDetails, navigate: () =>  navigation.navigate('FlightDetails') },
                // { label: LocalizedString.contactInfo, navigate: () => //console.log('Category clicked') },
              ]}
            />          
          <TripComponent trip={selectedTrip} fromSelectedStation={fromSelectedStation} toSelectedStation={toSelectedStation} selectedDate={selectedDate} handleItemPress={null} />            
        </View>  
      </Desktop>
      
    <ScrollView>
    <View style={styles.personalInfo}> 
      <View style={containerStyle}>
        <Text style={styles.title}>
          {LocalizedString.enterContactInfo}
        </Text>
        <View style={styles.form}>
          <View style={styles.containerInputContact}>
            <Text style={styles.label} numberOfLines={1}>
              {LocalizedString.contactNumber}
            </Text>
            <TextInput style={[styles.input, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
            value={contactNumber}
            onChangeText={(text) => setContactNumber(text)}
            maxLength={12}
            />          
          </View>
          <View style={styles.containerInputIc}>
            <Text style={styles.label} numberOfLines={1}>
              {LocalizedString.icPassportNumber}
            </Text>
            <TextInput style={[styles.input, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
              value={icPassportNumber}
              onChangeText={(text) => setIcPassportNumber(text)}
              maxLength={14}
            />
          </View>
          {memberId === undefined || memberId === null ? <View style={styles.containerInputIc}>
            <Text style={styles.label} numberOfLines={1}>
              {LocalizedString.contactEmail}
            </Text>
            <TextInput style={[styles.input, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
              value={contactEmail}
              onChangeText={(text) => setContactEmail(text)}
              maxLength={30}
            />
          </View> : undefined}
          {icPassportNumbers.map((ticket, index) => (
            <View key={index}>
              <View style={styles.containerInputIc}>
                <Text style={styles.label} numberOfLines={1}>
                  {LocalizedString.formatString(LocalizedString.passengerIcPassportNumber,{index:index+1,type:LocalizedString[ticket.type.toLowerCase()]})}
                </Text>
                <TextInput
                  style={[styles.input, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                  value={ticket.icNumber}
                  onChangeText={(text) => handleInputChange(text, index)}
                  maxLength={14}
                />
              </View>
              <View style={styles.containerInputIc}>
                <Text style={styles.label} numberOfLines={1}>
                {LocalizedString.formatString(LocalizedString.passengerName,{index:index+1,type:LocalizedString[ticket.type.toLowerCase()]})}
                </Text>
                <TextInput
                  style={[styles.input, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                  value={ticket.name}
                  onChangeText={(text) => handleNameInputChange(text, index)}
                />
              </View>
            </View>
          ))}
          {/* <View style={styles.rowSame}>
            <Image
              style={styles.iconCheckboxLine}
              resizeMode="cover"
              source={require("../assets/-icon-checkbox-line.png")}
            />
            <Text style={styles.sameAsContact}>Same as Contact ID</Text>
          </View> */}
    
        </View>
        <Default>
          <TouchableOpacity style={styles.form} onPress={confirmButtonPressed}>
            <View style={styles.loginprimary}>
              <Text style={styles.label5}>{LocalizedString.confirm}</Text>
            </View>
          </TouchableOpacity>
        </Default>
      </View>
      <Desktop>
      <View style={styles.detail}>
        <View style={styles.ticketDetail}>
          <TicketDetailsSection selectedTrip={selectedTrip}
            toSelectedStation={toSelectedStation}
            fromSelectedStation={fromSelectedStation}
            seatString={seatString}
            selectedDate={selectedDate}
            contactNo={contactNumber}
            showContactInfo={false}
            contactId={icPassportNumber}
            shouldShowInsuranceCheckBox={seatResult.withInsurance === "true"}
            includeInsurance={includeInsurance}
            toggleCheckbox={toggleCheckbox}
            insuranceFee={fare.insurance}
          />
        </View>
       
          <TicketFeeSummary fare={fare} ticketTypes={ticketTypes} fareFacility={fareFacility} fareOther={fareOther} 
          fareInsurance={fareInsurance} grandTotal={grandTotal} subTotal={subTotal} />

      </View>
      </Desktop>
    </View>
     
    </ScrollView>
    <Desktop>
      <View style={CommonStyle.withDesktopPaymentFooter}></View>
        <DesktopPaymentFooter navigation={navigation} buttonName={LocalizedString.confirm} buttonPressed={confirmButtonPressed} 
        cancelButtonPressed={cancelButtonPressed} cancelName={LocalizedString.cancel} grandTotal={`${fare.currency} ${grandTotal.toFixed(2)}`}/>
      </Desktop>
      </DesktopWrapper>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  detail:{
    marginHorizontal:60,
  },
  icon: {
    position: "relative",
    width: 31,
    height: 9,
  },  
  leftArrowIcon: {
    position: "relative",
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  leftArrow: {
    flexDirection: "row",
    padding: Padding.p_9xs,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bookFlight: {
    position: "relative",
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "center",
    width: 192,
  },
  header: {
    flexDirection: "row",
    paddingHorizontal: Padding.p_17xl,
    paddingVertical: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  menuIcon: {
    position: "relative",
    width: 24,
    height: 24,
    overflow: "hidden",
    display: "none",
  },
  content: {
    flexDirection: "row",
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    alignItems: "center",
    justifyContent: "center",
  },

  title: {
    position: "relative",
    fontSize: FontSize.size_xl,
    lineHeight: 24,
    fontWeight: "700",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "left",
    width: 310,
  },
  label: {
    position: "relative",
    fontSize: FontSize.size_base,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black1,
    textAlign: "left",
    overflow: "hidden",
    width: 312,
    height: 24,
  },
  text: {
    position: "relative",
    fontSize: FontSize.size_base,
    lineHeight: 22,
    fontFamily: FontFamily.interRegular,
    color: Color.gray999,
    textAlign: "left",
    width: 280,
    height: 24,
    display: "none",
  },
  input: {
    borderRadius: Border.br_5xs,
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    height: 48,
    flexDirection: "row",
    width: 320,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 8,
  },
  containerInputContact: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  text1: {
    position: "relative",
    fontSize: FontSize.size_base,
    lineHeight: 22,
    fontFamily: FontFamily.interRegular,
    color: Color.lightslategray,
    textAlign: "left",
    width: 280,
    height: 24,
    display: "none",
  },
  containerInputIc: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 16,
  },
  text2: {
    position: "relative",
    fontSize: FontSize.size_9xs,
    letterSpacing: 4,
    lineHeight: 24,
    fontFamily: FontFamily.interRegular,
    color: Color.gray01,
    textAlign: "left",
    width: 232,
    height: 24,
    display: "none",
  },
  iconCheckboxLine: {
    position: "relative",
    width: 25,
    height: 19,
  },
  sameAsContact: {
    position: "relative",
    fontSize: FontSize.size_base,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "left",
    width: 298,
    marginLeft: 11,
  },
  rowSame: {
    width: 312,
    height: 31,
    overflow: "hidden",
    flexDirection: "row",
    paddingLeft: 9,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 16,
  },
  form: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 16,
  },
  label5: {
    position: "relative",
    fontSize: FontSize.size_base,
    lineHeight: 16,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  loginprimary: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 312,
    height: 48,
    flexDirection: "row",
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",    
    marginTop: 9,
  },
  containerDesktop: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",        
    marginTop: 9,
    width: 650,
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingTop: 40,
  },
  personalInfo: {  
    marginTop: 40,  
    backgroundColor: Color.gray_100,
    flex: 1,
    width: "100%",
    flexDirection: "row",    
    justifyContent: "center",
  },
  ticketDetail: {
    width: 330
  },
});

export default PersonalInfo;
