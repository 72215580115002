import AsyncStorage from '@react-native-async-storage/async-storage';

import EventObject from './event_object';
import handleApiCall from '../shared/util';
import {loginUser,logout,registerUser, viewProfile, refreshToken, updateProfile, activation, resetPassword, changePassword, listTransactionHistory, printTicket } from '../../webservice/putrajaya_api';
import { createAction } from "@utils";
import { call, put, select } from 'redux-saga/effects';

function getUserFromStorage() {
  return AsyncStorage.getItem('@profile', (err, result) => {
    // log('@profile ', result);
    if (err) {
      log('@profile Error: ', err);
    }

    return result;
  });
}

// function getSkipLoginFromStorage() {
//   return AsyncStorage.getItem('@skipLogin', (err, result) => {
//     // log('@profile ', result);
//     if (err) {
//       log('@skipLogin Error: ', err);
//     }

//     return result;
//   });
// }

// function saveSkipLoginToStorage(skip) {
//   AsyncStorage.setItem('@skipLogin', skip);
// }


function saveUserToStorage(data) {
  AsyncStorage.setItem('@profile', JSON.stringify(data));
}

async function refreshAuthToken(memberId, authToken) {
  try {
    //console.log('refreshToken', authToken);
    const json = await refreshToken({memberId,authToken});
              
    //console.log('refreshToken result', JSON.stringify(json));
    const eventObject = new EventObject(json);

    if (eventObject.success === true) {
      await saveAuthTokenToStorage(eventObject.result);
      return eventObject.result.authToken
      await createAction('setAuthToken')(eventObject.result);
    }
  } catch (err) {
    //console.log('err', err);
  }
  return null; // Return null if refresh fails
}


async function saveAuthTokenToStorage(data) {
  let userString = await getUserFromStorage()
  if (userString) {
    let user = JSON.parse(userString)
    if (user) {
      //console.log(user)
      user.authToken = data.authToken;
      saveUserToStorage(user);
    }
  }
}

function clearCache() {
  AsyncStorage.clear();
}

const getAuthToken = (state) => state.member.authToken;


export default {
  namespace: 'member',
  state: {
    memberId: null,
    authToken: null,
    isLoading: true,    
    otpMemberId: null,
    bookings: null,
    skipLogin:false,
    isSkipLoading:true,
    showModalLogin:false,
    isDesktopWeb:null,
  },
  reducers: {    
  
    destroyUser(state, {payload}) {
      clearCache();
      return {
        ...state,
        memberId: null,      
        authToken: null,   
        skipLogin: false 
      };
    },    
    setLoadingCompleted(state, {payload}) {      
      return {
        ...state,
        isLoading: false
      };
    },
    setIsDesktopWeb(state, {payload}) {
      return {
        ...state,
        isDesktopWeb: payload
      };
    },
    setShowModalLogin(state, {payload}) {
      return {
        ...state,
        showModalLogin: payload
      };
    },
    setSkipLoadingCompleted(state, {payload}) {      
      return {
        ...state,
        isSkipLoading: false
      };
    },
    setUser(state, {payload}) {      
      //console.log('setUser', payload);
      //console.log('memberId', payload.memberId);
      //console.log('authToken', payload.authToken);
      return {
        ...state,
        memberId: payload.memberId ?? null,   
        authToken: payload.authToken ?? null,    
      };
    },
    setAuthToken(state, {payload}) {
      //console.log('setAuthToken', payload);
      //console.log('memberId', payload.memberId);
      //console.log('newAuthToken', payload.authToken);
      return {
        ...state,
        authToken: payload.authToken ?? null,    
      };
    },
    setSkipLogin(state, {payload}) {      
      //console.log('setSkipLogin', payload);
      
      

      return {
        ...state,
        skipLogin: payload.skipLogin
      };
    }, 
    setOtpMemberId(state, {payload}) {      

      return {
        ...state,
        otpMemberId: payload.memberId ?? null,           
      };
    }
  },
  effects: {  
    *loadLogin({payload}, {call, put, select}) {      
        try {
          const {memberId,password,rememberMe, callback} = payload;
        
          const json = yield call(loginUser, {memberId,password,rememberMe});
                    
          const eventObject = new EventObject(json);

          if (eventObject.success === true) {
            saveUserToStorage(eventObject.result);
            yield put(createAction('setUser')(eventObject.result));
          }
          typeof callback === 'function' && callback(eventObject);
        } catch (err) {
          //console.log('err', err);

        }
      },    
    
      *loadUserFromCache({payload}, {call, put, select}) {      
        try {

          const userJson = yield call(getUserFromStorage);
          const json = JSON.parse(userJson);
          //console.log('loaded user', json);


          if (json) {
            yield put(createAction('setUser')(json));                 
          }
          yield put(createAction('setLoadingCompleted')({}));     
          typeof callback === 'function' && callback(json);     
        } catch (err) {
          //console.log('err', err);

        }
      }, 
      *saveSkipLoginToStorage({payload}, {call, put, select}) {      
        try {
          const {skipLogin} = payload;

          yield put(createAction('setSkipLogin')({skipLogin}));     
          //saveSkipLoginToStorage( skipLogin)
        } catch (err) {
          //console.log('err', err);

        }
      },   
      *loadSkipLoginFromCache({payload}, {call, put, select}) {      
        try {
          // const skipLogin = yield call(getSkipLoginFromStorage);

          // if (skipLogin) {

          //   yield put(createAction('setSkipLogin')(skipLogin));                 
          // }
          yield put(createAction('setSkipLoadingCompleted')({}));     
                       
        } catch (err) {
          //console.log('err', err);

        }
      },   
    *loadLogout({payload}, {call, put, select}) {
      try {
        const {memberId, callback} = payload;
        const authToken = yield select(getAuthToken);
               
        const json = yield call(logout, {memberId,authToken});
        
        const eventObject = new EventObject(json);

        if (eventObject.success === true || eventObject.tokenExpired === true) {
          yield put(createAction('destroyUser')({}));
          yield put(createAction('trip/resetTripInfo')({}));
          yield put (createAction('trip/setToSelectedStation')(null));
          yield put(createAction('trip/setSelectedDate')(null));
          //console.log('destroyUser');
        }
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadActivation({payload}, {call, put, select}) {
      try {
        const {code,memberId, callback} = payload;
               
        const json = yield call(activation, {code,memberId});
                  
        const eventObject = new EventObject(json);

        if (eventObject.success === true) {
          saveUserToStorage(eventObject.result);
          yield put(createAction('setUser')(eventObject.result));
        }
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadRegister({payload}, {call, put, select}) {
      try {
        const {name,email,phoneNo,password, callback} = payload;
      
        const json = yield call(registerUser, {name,email,phoneNo,password});
                  
        const eventObject = new EventObject(json);
    
        if (eventObject.success === true) {
          yield put(createAction('setOtpMemberId')(eventObject.result));
          
        }

        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);
      }
    },
    *loadResetPassword({payload}, {call, put, select}) {
      try {
        const {memberId, callback} = payload;
      
        
        const json = yield call(resetPassword, {memberId});
                  
        const eventObject = new EventObject(json);
    
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadRefreshToken({payload}, {call, put, select}) {
      try {
        const {memberId, callback} = payload;
        const authToken = yield select(getAuthToken);

        // //console.log('refreshToken', authToken);
        // const json = yield call(refreshToken, {memberId,authToken});
                  
        // const eventObject = new EventObject(json);
    
        // if (eventObject.success === true) {
        //   saveAuthTokenToStorage(eventObject.result);
        //   yield put(createAction('setAuthToken')(eventObject.result));
        // }
        // typeof callback === 'function' && callback(eventObject);

        yield refreshAuthToken(memberId, authToken);
      } catch (err) {
        //console.log('err', err);
      }
    },
    *loadChangePassword({payload}, {call, put, select}) {
      try {
        const {memberId,oldPassword,newPassword, callback} = payload;
        const authToken = yield select(getAuthToken);

        const json = yield call(handleApiCall, changePassword, {memberId,authToken,oldPassword,newPassword});
                  
        const eventObject = new EventObject(json);
    
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadViewProfile({payload}, {call, put, select}) {

      
      try {
        const {memberId, callback} = payload;
        let authToken = yield select(getAuthToken);

        //console.log('loadViewProfile', authToken);

        const params = { memberId, authToken };
    
        const eventObject = yield call(handleApiCall, viewProfile, params);
    
        if (typeof callback === 'function') {
          callback(eventObject);
        }
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadUpdateProfile({payload}, {call, put, select}) {
      try {
        
        const {name,memberId,phoneNo,address,idNo, callback} = payload;

        const authToken = yield select(getAuthToken);
        const json = yield call(handleApiCall, updateProfile, {name,memberId,phoneNo,address,idNo,authToken});
        
                  
        const eventObject = new EventObject(json);
    
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadTransactionHistory({payload}, {call, put, select}) {
      try {
        const {memberId,pageNo,recordPerPage,refNo, callback} = payload;
        const authToken = yield select(getAuthToken);
        const json = yield call(handleApiCall, listTransactionHistory, {memberId,pageNo,recordPerPage,refNo,authToken});
                  
        const eventObject = new EventObject(json);
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },
    *loadPrintTicket({payload}, {call, put, select}) {
      try {
        const {memberId,refNo,date,callback} = payload;
        const authToken = yield select(getAuthToken);
        const json = yield call(handleApiCall, printTicket, {memberId,authToken,refNo,date});

        const eventObject = new EventObject(json);

        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);
      }
    }
  },
};
