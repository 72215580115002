/* fonts */
export const FontFamily = {
    interMedium: "Poppins-Medium",
    interRegular: "Poppins-Regular",
    interSemiBold: "Poppins-SemiBold",
    interLight: "Poppins-Light",
    interBold: "Poppins-Bold",
    title: "ClanPro-News",
    body:"ClanPro-Book"
  };
  /* font sizes */
  export const FontSize = {
    size_lg: 18,
    size_base: 16,
    size_9xl: 28,
    size_xs: 12,
    size_9xs: 4,
    size_xl: 20,
    size_sm: 14,
    size_3xs: 10,
    size_smi: 13,
    size_mini: 15,
  };
  /* Colors */
  export const Color = {
    gray04: "#e4e7eb",
    grayD9D9D9: "#d9d9d9",
    dimgray_100: "#7c7270",
    dimgray_200: "#666",
    gray02: "#4b5768",
    gray555: "#555",
    gray_100: "#f9fbfa",
    black1: "#191d23",
    black: "#191919",
    mistyrose: "#fad3ca",
    white: "#fff",
    secondary: "#ec441e",
    darkgray_100: "#a6a6a6",
    gray01: "#999da3",
    darkgray_200: "#212529",
    gray999: "#999",
    lightslategray: "#928fa6",
    steelblue_100: "#0483b8",
    steelblue_200: "#0072b6",
    dodgerblue: "rgba(59, 119, 220, 0.05)",
    deeppink: "#e42789",
    whitesmoke_100: "#f3f3f3",
    gray05: "#ebeef2",
    black: "#000",
    aliceblue_100: "rgba(236, 243, 253, 0.5)",
    blue: "#2f4eff",
    red: "#EC441E"
  };
  /* Paddings */
  export const Padding = {
    p_base: 16,
    p_xs: 12,
    p_5xs: 8,
    p_85xl: 104,
    p_9xs: 4,
    p_17xl: 36,
    p_sm: 14,
    p_5xl: 24,
    p_8xs: 5,
    p_21xl: 40,
    p_3xs: 10,
    p_13xl: 32,
    p_11xs: 2,
    p_7xs_5: 6,
  };
  /* border radiuses */
  export const Border = {
    br_5xs: 8,
    br_9xs: 4,
    br_base: 16,
    br_13xl: 32,
    br_11xs: 2,
    br_8xs: 5,
  };

export const FooterHeight = {
  payment: 90,
  button:90,
}