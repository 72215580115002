import React, { useState } from 'react';
import { Text, StyleSheet, View, Image, TouchableOpacity, TextInput, KeyboardAvoidingView ,ScrollView, Platform} from "react-native";
import { Border, FontSize, FontFamily, Color, Padding, LocalizedString, CommonStyle } from "@common";


const ProfileForm = ({
  updateButtonPressed,  
  name,
  setName,
  email,
  setEmail,
  phoneNo,
  setPhoneNo, // Assuming you want to use this in the future
  address,
  setAddress,
  idNo,
  setIdNo,
}) => {  

  return (
    
    <View style={[styles.formCreateAccount, styles.btnSignUpFlexBox]}>   
      <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.name}
      </Text>
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={name}
        onChangeText={(text) => setName(text)}
        placeholderTextColor={Color.gray999} 
        placeholder="Enter your name"
      />
      </View>   
    <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.idNo}
      </Text>   
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={idNo}
        onChangeText={(text) => setIdNo(text)}     
        placeholderTextColor={Color.gray999} 
        autoCapitalize="none"
      />
    </View>
    <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.phoneNo}
      </Text>   
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={phoneNo}
        onChangeText={(text) => setPhoneNo(text)}     
        placeholderTextColor={Color.gray999}    
        keyboardType="phone-pad"
        autoCapitalize="none"
      />
    </View>
    <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.address}
      </Text>   
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={address}
        onChangeText={(text) => setAddress(text)}      
        placeholderTextColor={Color.gray999}   
        autoCapitalize="none"
      />
    </View>
      <TouchableOpacity style={[styles.btnSignUp, styles.inputNameLayout]} onPress={updateButtonPressed}>
        <Text style={[styles.label, styles.labelTypo]}>{LocalizedString.updateProfile}</Text>
      </TouchableOpacity>
    </View>

  );
};

const styles = StyleSheet.create({
  btnSignUpFlexBox: {
    alignItems: "center",
    width: 312,
  },
  textNameSpaceBlock: {
    marginTop: 16,
    textAlign: "left",
    width: 312,
  },
  inputNameLayout: {
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
    marginTop: 16,
  },
  textLayout: {
    width: 280,
    lineHeight: 22,
    height: 24,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
  labelTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  textName: {
    
    height: 24,
    overflow: "hidden",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
    lineHeight: 24,
  },
  text: {    
    color: Color.black1,
  },
  inputName: {
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
  },
  label: {
    lineHeight: 16,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  btnSignUp: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    justifyContent: "center",
    alignItems: "center",
    width: 312,
  },
});

export default ProfileForm;