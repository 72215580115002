import React, { useState , useEffect } from 'react';
import { StyleSheet, Text, View, Pressable, Linking, Platform } from "react-native";
import { FontFamily, FontSize, Color, Padding, Border,CommonStyle, LocalizedString } from "@common";
import DesktopWrapper from "../components/DesktopWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import {DateTime} from 'luxon';
import DesktopNavbar from "../components/DesktopNavbar";
import DesktopFooter from '../components/DesktopFooter';
import Breadcrumb from '../components/Breadcrumb';
import PassengerSummary from '../components/PassengerSummary';
import TicketDetailsSection from "../components/TicketDetailsSection"
import TicketFeeSummary from "../components/TicketFeeSummary";
import { PAYMENT_GATEWAY_URL, GUEST_ID } from '@constants';

const Payment = ({navigation}) => {  

  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState(false);
  const memberId = useSelector((state) => state.member.memberId);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const contactNumber = useSelector((state) => state.trip.contactNo);
  const icPassportNumber = useSelector((state) => state.trip.contactId);
  const contactEmail = useSelector((state) => state.trip.contactEmail);
  const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
  const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
  const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
  const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);
  const includeInsurance = useSelector((state) => state.trip.includeInsurance);
  const selectedTrip = useSelector((state) => state.trip.selectedTrip);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const seatResult = useSelector((state) => state.trip.seatResult);
  const seats = useSelector((state) => state.trip.selectedSeats);

  const passengerIds = useSelector((state) => state.trip.passengerId);
  const passengerNames = useSelector((state) => state.trip.passengerName);
  const passengerTypes = useSelector((state) => state.trip.passengerType);

  //console.log('isDesktop', isDesktop)

  const toggleCheckbox = () => {
    //console.log('toggleCheckbox')
    dispatch(createAction('trip/setIncludeInsurance')(!includeInsurance))
  };

  const cancelPressed = () => {
    dispatch(createAction('trip/resetTripInfo')({}));
    if (isDesktop) {
      navigation.navigate("DesktopHome")
    }
    else {
      if (Platform.OS === 'web') {
        navigation.navigate("MobileHome")
      }
      else {
        navigation.navigate("Home")
      }
    }
  }

  const makePaymentPressed = () => {
    let callback = (eventObject) => {
      setLoading(false);
      if (eventObject.success) {
        let seatConfirmReference = eventObject.result
        let url = `${PAYMENT_GATEWAY_URL}consumer/select-payment?memberId=${memberId || GUEST_ID}&refNo=${seatConfirmReference.refNo}&issuedBy=${seatConfirmReference.issuedBy}&issuedDate=${seatConfirmReference.issuedDate}`
        //console.log(url)
        Linking.canOpenURL(url).then(supported => {
          if (supported) {
            Linking.openURL(url, '_self');
          } else {
            //console.log("Don't know how to open URI: " + url);
          }
        });
      } else {
        alert(eventObject.message);
      }
    }

    const oldDate = DateTime.fromISO(selectedDate);

    // Format the DateTime object to the desired string format
    const newDateString = oldDate.toFormat("dd-MM-yyyy");

    dispatch(createAction('trip/loadConfirmSeat')({memberId:memberId === undefined || memberId === null ? GUEST_ID : memberId,date:newDateString,trip:selectedTrip.code,seat:seats,
      passengerType:passengerTypes,passengerId:passengerIds,passengerName:passengerNames,
      totalPayableAmount:grandTotal,insurance:includeInsurance,adult:adultTicketQuantity,
      child:childTicketQuantity,senior:seniorTicketQuantity,oku:okuTicketQuantity,
      contactId:icPassportNumber,contactNo:contactNumber,contactEmail:contactEmail,
      isBooking:'N',callback}));    
    setLoading(true);
  }
  

  useEffect(() => {
    if (isDesktop) {
      if (seats === null || selectedDate === null || selectedTrip === null || icPassportNumber === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
    }
    //console.log(PAYMENT_GATEWAY_URL)
  }, [dispatch]);


  let fare =  0
  let adultTotal = 0
  let childTotal = 0
  let seniorTotal = 0
  let okuTotal = 0
  let subTotal = 0
  let totalTickets = 0
  let fareFacility = 0
  let fareOther = 0
  let fareInsurance = 0
  let grandTotal = 0
  let seatString = ""
  if (seatResult !== null) {
    fare =  seatResult.fare
    adultTotal = adultTicketQuantity*fare.adult
    childTotal = childTicketQuantity*fare.child
    seniorTotal = seniorTicketQuantity*fare.senior
    okuTotal = okuTicketQuantity*fare.disabled
    subTotal = adultTotal+childTotal+seniorTotal+okuTotal
    totalTickets = adultTicketQuantity+childTicketQuantity+seniorTicketQuantity+okuTicketQuantity
    fareFacility = fare.facility * totalTickets
    fareOther = fare.other * totalTickets
    fareInsurance = includeInsurance ? fare.insurance * totalTickets : 0
    grandTotal = subTotal+fareFacility+fareOther+fareInsurance
    seatString = seats.map((seat) => seat).join(",");
  }


  const ticketTypes = [
    { type: 'Adult', quantity: adultTicketQuantity, fare: fare.adult, total: adultTotal },
    { type: 'Child', quantity: childTicketQuantity, fare: fare.child, total: childTotal },
    { type: 'Senior', quantity: seniorTicketQuantity, fare: fare.senior, total: seniorTotal },
    { type: 'Disabled', quantity: okuTicketQuantity, fare: fare.disabled, total: okuTotal },
  ];


  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };

  const buildPassengerDetail = (index) => {
    return (
      <PassengerSummary 
        key={index}
        type={passengerTypes[index]} 
        id={passengerIds[index]}
        seat={seats[index]}
        name={passengerNames[index]}
        fare={fare} />
    )
  }

let containerStyle = isDesktop ? styles.containerDesktop : undefined;

if (seats === null || selectedDate === null || selectedTrip === null || icPassportNumber === null) {
  return (<View></View>)
}

  return (
    <DesktopWrapper isLoading={isLoading} navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="Payment"/>}>      
    

    <Desktop>
      <View style={CommonStyle.breadcrumb}>
         <Breadcrumb 
              items={[
                { label: LocalizedString.home, navigate: () => navigation.navigate('DesktopHome') },
                { label: LocalizedString.searchResults, navigate: () => navigation.navigate('SearchResult') },
                { label: LocalizedString.tripDetails, navigate: () =>  navigation.navigate('FlightDetails') },
                { label: LocalizedString.contactInfo, navigate: () => navigation.navigate('PersonalInfo') },
                // { label: LocalizedString.payment, navigate: () => //console.log('Category clicked') },
              ]}
            />   
        </View>
    </Desktop> 
    
    <View style={[styles.paymentContainer]}>   
  
      <View style={[isDesktop ? styles.containerFlexBox : styles.containerFlexBoxMobile,containerStyle]}>
        <View style={styles.paymentTitle}>
          <Text style={[CommonStyle.title, styles.titleCenter]}>{LocalizedString.confirmPurchase}</Text>
          <View style={isDesktop ? undefined : styles.mobileDetail}>
            <TicketDetailsSection selectedTrip={selectedTrip}
                toSelectedStation={toSelectedStation}
                fromSelectedStation={fromSelectedStation}
                seatString={seatString}
                selectedDate={selectedDate}
                contactNo={contactNumber}
                showContactInfo={true}
                contactId={icPassportNumber}
                contactEmail={contactEmail}
                includeInsurance={includeInsurance}
                shouldShowInsuranceCheckBox={seatResult && seatResult.withInsurance === "true" && !isDesktop}
                toggleCheckbox={toggleCheckbox}
                insuranceFee={fare.insurance}
              />
          </View>
        </View>
        
        <Text style={[CommonStyle.title, styles.titleCenter]}>{LocalizedString.passengerDetails}</Text>
        {seats.map((value, index) => buildPassengerDetail(index))}
        {includeInsurance && <View style={isDesktop ? styles.quantityFlexBox : styles.quantityFlexBoxMobile}>
          <View style={styles.adultParent}>
            <Text style={[styles.passengerInfo, styles.xTypo]}>{LocalizedString.insuranceCoverage}</Text>
          </View>
          <Text style={styles.textTypo}>{fare.currency} {fareInsurance.toFixed(2)}</Text>
        </View>}
        <View style={[isDesktop ? styles.summaryTotal : styles.summaryTotalMobile, styles.formPaymentShadowBox]}>
          <Text style={[styles.passengerInfo, styles.xTypo, styles.textTypo]}>{LocalizedString.facilityFee}</Text>
          <Text style={styles.textTypo}>{fare.currency} {fareFacility.toFixed(2)}</Text>
        </View>
        <View style={[isDesktop ? styles.summaryTotal : styles.summaryTotalMobile, styles.formPaymentShadowBox]}>
          <Text style={[styles.passengerInfo, styles.xTypo, styles.textTypo]}>{LocalizedString.otherFee}</Text>
          <Text style={styles.textTypo}>{fare.currency} {fareOther.toFixed(2)}</Text>
        </View>
        <View style={[isDesktop ? styles.summaryTotal : styles.summaryTotalMobile, styles.formPaymentShadowBox]}>
          <Text style={[styles.textTypo, styles.totalRm]}>{LocalizedString.totalRm}</Text>
          <Text style={[styles.textTypo, styles.text]}>{grandTotal.toFixed(2)}</Text>
        </View>
        <View style={[styles.formPayment, styles.formPaymentShadowBox]}>
          <View style={styles.button}>
            <Pressable
              style={[styles.buttonBig, styles.buttonFlexBox]}
              onPress={makePaymentPressed}
            >
              <Text style={[styles.confirm, styles.confirmTypo]}>{LocalizedString.proceedToPayment}</Text>
            </Pressable>
            <Pressable
              style={[styles.buttonBig1, styles.buttonFlexBox]}
              onPress={cancelPressed}
            >
              <Text style={[styles.confirm1, styles.confirmTypo]}>{LocalizedString.cancel}</Text>
            </Pressable>
          </View>
        </View>
      </View>
      <Desktop>
      <View style={styles.detail}>
        <TicketDetailsSection selectedTrip={selectedTrip}
          toSelectedStation={toSelectedStation}
          fromSelectedStation={fromSelectedStation}
          seatString={seatString}
          selectedDate={selectedDate}
          contactNo={contactNumber}
          showContactInfo={false}
          contactId={icPassportNumber}
          includeInsurance={includeInsurance}
          shouldShowInsuranceCheckBox={seatResult && seatResult.withInsurance === "true"}
          toggleCheckbox={toggleCheckbox}
          insuranceFee={fare.insurance}
        />
       
          <TicketFeeSummary fare={fare} ticketTypes={ticketTypes} fareFacility={fareFacility} fareOther={fareOther} 
          fareInsurance={fareInsurance} grandTotal={grandTotal} subTotal={subTotal} />

      </View>
      </Desktop>
    </View>
    <Desktop>
      <View style={CommonStyle.withDesktopFooter}></View>
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    </Desktop>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({

  totalRmParentFlexBox: {
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  wifiIconLayout: {
    height: 18,
    width: 18,
  },
  leftArrowSpaceBlock: {
    padding: Padding.p_9xs,
    flexDirection: "row",
  },
  containerFlexBox: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 20,
  },
  containerFlexBoxMobile: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 20,
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  formPaymentShadowBox: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
  containerContactDetail: {
    width: '100%',
    marginLeft: 10,
  },
  textContactDetail: {
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
  totalRmClr: {
    color: Color.black,
    textAlign: "left",
  },
  lineViewBorder: {
    marginTop: 6,
    borderTopWidth: 0.8,
    borderColor: "#e6e8e7",
    height: 1,
    borderStyle: "solid",
  },
  logoIconSpaceBlock: {
    marginLeft: 16,
    height: 20,
    overflow: "hidden",
  },
  buttonFlexBox: {
    paddingVertical: Padding.p_base,
    paddingHorizontal: Padding.p_85xl,
    width: '100%',
    borderRadius: Border.br_5xs,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmTypo: {
    width: 120,
    lineHeight: 24,
    fontSize: FontSize.size_lg,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  icon: {
    height: 9,
    width: 31,
  },
  wifiIcon: {
    overflow: "hidden",
  },
  frameChild: {
    marginLeft: 3.07,
  },
  batteryIcon: {
    width: 19,
    height: 19,
    marginLeft: 3.07,
    overflow: "hidden",
  },
  androidStatusBars1: {
    flexDirection: "row",
  },
  androidStatusBars2: {
    paddingVertical: 0,
    paddingHorizontal: Padding.p_base,
    height: 24,
    justifyContent: "space-between",
    width: 360,
  },
  androidStatusBars: {
    paddingTop: Padding.p_5xs,
  },
  leftArrow: {
    alignItems: "center",
  },
  bookFlight: {
    lineHeight: 32,
    width: 192,
    textAlign: "center",
    color: Color.black,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_xl,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    padding: Padding.p_9xs,
    flexDirection: "row",
  },
  content: {
    paddingVertical: Padding.p_xs,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    flexDirection: "row",
    alignItems: "center",
  },
  topBar: {
    alignItems: "center",
    overflow: "hidden",
  },
  totalRm: {
    textAlign: "left",
    color: Color.black,
    fontSize: FontSize.size_xl,
    fontWeight: "600",
    color: Color.steelblue_200,
  },
  text: {
    fontSize: 18,
    color: Color.steelblue_200,
    textAlign: "right",
  },
  totalRmParent: {
    alignSelf: "stretch",
  },
  summaryTotalChild: {
    borderColor: "#84b6f8",
    borderTopWidth: 1,
    opacity: 0.15,
    marginTop: 16,
    height: 1,
    width: 297,
    borderStyle: "solid",
  },
  summaryTotal: {
    marginTop: 10,
    paddingTop: Padding.p_11xs,
    paddingBottom: Padding.p_base,
    paddingLeft: 45,
    paddingRight: 10,
    width: '100%',
  },
  summaryTotalMobile: {
    marginTop: 10,
    paddingTop: Padding.p_11xs,
    paddingBottom: Padding.p_base,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
  cardNumber: {
    fontSize: FontSize.size_sm,
    textAlign: "left",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    color: Color.black,
  },
  text1: {
    fontSize: FontSize.size_base,
    fontWeight: "300",
    fontFamily: FontFamily.interLight,
    color: Color.darkgray_100,
    textAlign: "left",
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  frameWrapper: {
    paddingHorizontal: Padding.p_9xs,
    paddingVertical: 0,
  },
  frameItem: {
    width: 297,
    borderTopWidth: 0.8,
    borderColor: "#e6e8e7",
  },
  frameParent: {
    marginTop: 10,
  },
  cardHolderNameParent: {
    marginTop: 20,
  },
  lineView: {
    width: 133,
  },
  expiryDateParent: {
    marginLeft: 32,
  },
  frameView: {
    marginTop: 20,
    flexDirection: "row",
  },
  mastercardLogoIcon: {
    height: 20,
    width: 26,
    overflow: "hidden",
  },
  visaLogoIcon: {
    width: 26,
    marginLeft: 16,
  },
  amexLogoIcon: {
    width: 31,
  },
  paymentOptions: {
    marginTop: 32,
    flexDirection: "row",
  },
  cardDetails: {
    justifyContent: "center",
  },
  confirm: {
    color: Color.white,
  },
  buttonBig: {
    backgroundColor: Color.steelblue_200,
  },
  confirm1: {
    color: Color.secondary,
  },
  buttonBig1: {
    borderColor: "#ec441e",
    borderWidth: 1,
    marginTop: 20,
    borderStyle: "solid",
    paddingVertical: Padding.p_base,
    paddingHorizontal: Padding.p_85xl,
    width: 296,
    borderRadius: Border.br_5xs,
  },
  button: {
    marginTop: 40,
    width: '100%'
  },
  formPayment: {
    padding: Padding.p_base,
    marginTop: 16,
  },
  paymentContainer: {
    marginTop: 20,
    flex: 1,
    width: "100%",    
    alignItems: "center",
    justifyContent:"center",
    overflow: "hidden",
    backgroundColor: Color.gray_100,
    flexDirection: "row",    
  },
  containerDesktop: {
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",        
    marginTop: 9,
    width: 600,
    backgroundColor: Color.white,
  },
  standingMan1Icon: {
    width: 50,
    height: 50,
    overflow: "hidden",
  },
  textFlexBox: {
    textAlign: "left",
    color: Color.black,
  },
  adult: {
    fontSize: FontSize.size_sm,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
  },
  rm5500: {
    color: Color.steelblue_200,
    width: 105,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_xs,
  },
  rm5500SpaceBlock: {
    marginTop: 4,
    textAlign: "left",
  },
  quantityFlexBox: {
    borderColor: "#84b6f8",
    borderTopWidth: 1,
    borderStyle: "solid",
    width: "100%",
    paddingTop: 20,
    marginTop: 10,
    paddingRight: 10,
    paddingLeft: 45,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  quantityFlexBoxMobile: {
    borderColor: "#84b6f8",
    borderTopWidth: 1,
    borderStyle: "solid",
    width: "100%",
    paddingTop: 20,
    marginTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  otherFeeFlexBox: {
    width: "100%",
    marginTop: 10,
    paddingRight: 10,
    paddingLeft: 45,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  otherFeeFlexBoxMobile: {
    marginTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  passengerInfo: {
    textAlign: "left",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_sm,
  },
  adultParent: {
    width: 178,
    marginLeft: 14,
    justifyContent: "center",
  },
  xTypo: {
    fontSize: FontSize.size_sm,
    color: Color.black,
  },
  textTypo: {
    marginLeft: 14,
    fontSize: FontSize.size_sm,
    color: Color.black,
    textAlign: "right",
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  quantityLayout: {
    alignItems: "center",
    height: 31,
    width: 39,
  },
  titleCenter: {
    textAlign: "center",
  },
  detail: {
    maxWidth: 330
  },
  mobileDetail: {
    width: 330
  },
  paymentTitle: {
    borderWidth: 1,
    borderColor: Color.black,
    borderRadius: 5,
  }
});

export default Payment;
