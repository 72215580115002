import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet ,Image} from 'react-native';
import { Color, LocalizedString,Border,Padding, FooterHeight } from '@common';
import { DateTime } from "luxon";

const DesktopButtonFooter = ({buttonName,buttonPressed}) => {

  return (
    <View style={styles.navBar}>           
      <View style={styles.container}>
          <TouchableOpacity
            style={styles.buttonSmall1}
            onPress={buttonPressed}
          >
            <Text style={styles.confirm1}>{buttonName}</Text>
          </TouchableOpacity>
          </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navBar:{
    flexDirection: "row",
    width: '100%',
    height: FooterHeight.button,
    backgroundColor: Color.white,
    position: 'absolute',
    bottom: 0,    
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonSmall1: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    height: 60,
    width: 150,
    flexDirection: "row",
    paddingVertical: Padding.p_base,    
    alignItems: "center",
    justifyContent: "center",
    position: 'absolute',
    right: 100,    
  },
  container:{
    width: 1000,
    flexDirection: "row",
    alignItems: 'center',
    backgroundColor: Color.white,
  },
  confirm1:{
    color: Color.white,
  }

  
});

export default DesktopButtonFooter;
