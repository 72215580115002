export const isValidEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

export const isValidName = (name) => name.trim().length > 0;

export const isValidPhoneNo = (phoneNo) => {
  // Assuming phone numbers should be 10 digits
  return  phoneNo.trim().length > 8;
};

export const isValidPassword = (password) => password.length >= 8; // Example: at least 8 characters

export const passwordsMatch = (password, passwordConfirmation) =>
  password === passwordConfirmation;