import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, View, TouchableOpacity, KeyboardAvoidingView, ScrollView,Platform } from "react-native";
import ProfileForm from "../components/ProfileForm";
import { useToast } from 'native-base';
import { Padding, FontFamily, FontSize, Color,LocalizedString, CommonStyle } from "@common";
import { Dialog } from "@rneui/themed";
import { isValidEmail,createAction ,isValidName,isValidPhoneNo,isValidPassword,passwordsMatch, Desktop } from "@utils";
import {useDispatch,useSelector} from 'react-redux';
import DesktopFooter from '../components/DesktopFooter';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopWrapper from '../components/DesktopWrapper';

const MyProfile = ({navigation}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState('');
  const [name, setName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [address, setAddress] = useState('');
  const [idNo, setIdNo] = useState('');
  
  const [_, forceUpdate] = useState();
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const toast = useToast();

  useEffect(() => {
    if (isDesktop) {
      if (authToken === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
    }

    let callback = (eventObject) => {
      setLoading(false);
      if (eventObject.success) {
        const {name,email,phoneNo,address,idNo} = eventObject.result;

        setName(name);
        setEmail(email);
        setPhoneNo(phoneNo);
        setAddress(address);
        setIdNo(idNo);
      } else {
        alert(eventObject.message);
      }
    }
    dispatch(createAction('member/loadViewProfile')({memberId,callback}));    
  }, [dispatch]);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };


  const  updateButtonPressed = () => {
      
    if (!isValidName(name)) {
      alert(LocalizedString.invalidName);
      return;
    }
  
    if (!isValidPhoneNo(phoneNo)) {
      alert(LocalizedString.invalidPhoneNo);
      return;
    }

    let callback = (eventObject) => {
      setLoading(false);
      if (eventObject.success) {
        setLoading(false); 
        toast.show({
          description: `${LocalizedString.updateProfileSuccess}`,
          placement: "top"
        })
      } else {
        alert(eventObject.message);
      }
    }

    dispatch(createAction('member/loadUpdateProfile')({memberId,phoneNo,address,idNo,name,callback}));    
    setLoading(true);          
  };


  return (
    <KeyboardAvoidingView
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={{ flex: 1 }}
  >    
  
    <ScrollView style={styles.container}>
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="MyProfile"/>}> 
    <View style={styles.signup}>
      <View style={styles.container}>    
      <Desktop>
      <Text style={CommonStyle.title}>{LocalizedString.myProfile}</Text>
        </Desktop>  
        <ProfileForm updateButtonPressed={updateButtonPressed} 
          name={name} setName={setName} 
          email={email} setEmail={setEmail} 
          phoneNo={phoneNo} setPhoneNo={setPhoneNo}
          address={address} setAddress={setAddress}
          idNo={idNo} setIdNo={setIdNo}
        />        
      </View>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
      </View>
      </DesktopWrapper>
      </ScrollView>
      <Desktop>
        <View style={CommonStyle.withDesktopFooter}></View>
        <DesktopFooter navigation={navigation} isDesktop={isDesktop}/>
      </Desktop>      
    </KeyboardAvoidingView>    
  );
};

const styles = StyleSheet.create({

  alreadyHaveAn: {
    fontFamily: FontFamily.interRegular,
    color: Color.gray01,
  },
  signInHere: {
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
    color: Color.steelblue_200,
  },
  textSignIn: {
    fontSize: FontSize.size_base,
    textAlign: "center",
    width: 312,
    height: 24,
    marginTop: 20,
    lineHeight: 24,
    overflow: "hidden",
  },
  container: {
    flex: 1,
    backgroundColor: Color.white,
  },
  signup: {
    flex: 1,
    width: "100%",    
    paddingHorizontal: Padding.p_5xl,
    alignItems: "center",
    overflow: "hidden",    
  },
});

export default MyProfile;