import React, {useState , useEffect} from "react";
import { Image, StyleSheet, View, Pressable, Text,TouchableOpacity, Platform } from "react-native";
import ChooseTicketQuantityForm from "../components/ChooseTicketQuantityForm";
import { Padding, FontSize, FontFamily, Color, Border, LocalizedString, CommonStyle } from "@common";
import { useDispatch, useSelector } from 'react-redux';
import { MAX_PAX } from "@constants";
import { createAction, Desktop ,Default } from "@utils";
import {DateTime} from 'luxon';
import DesktopButtonFooter from "../components/DesktopButtonFooter";
import DesktopFooter from "../components/DesktopFooter";
import DesktopNavbar from "../components/DesktopNavbar";
import TripComponent from "../components/TripComponent";
import SeatLegend from "../components/SeatLegend";
import SeatMap from "../components/SeatMap";
import TicketDetailsSection from "../components/TicketDetailsSection";
import TicketFeeSummary from "../components/TicketFeeSummary";
import DesktopWrapper from '../components/DesktopWrapper';
import { ScrollView } from "react-native-gesture-handler";
import Breadcrumb from '../components/Breadcrumb';

const FlightDetails = ({navigation}) => {
  
  const dispatch = useDispatch();
  const language = useSelector((state) => state.config.language); 
  const [_, forceUpdate] = useState();

  const [showSetting,setShowSetting] = useState(false);

  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const memberId = useSelector((state) => state.member.memberId);
  const skipLogin = useSelector((state) => state.member.skipLogin);
  const selectedTrip = useSelector((state) => state.trip.selectedTrip);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  
  const [contactNumber, setContactNumber] = useState("");
  const [icPassportNumber, setIcPassportNumber] = useState("");
  const includeInsurance = useSelector((state) => state.trip.includeInsurance);
  const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
  const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
  const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
  const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);
  const seatResult = useSelector((state) => state.trip.seatResult);
  
  const selectedSeats = useSelector((state) => state.trip.selectedSeats);

  let fare =  0
  let adultTotal = 0
  let childTotal = 0
  let seniorTotal = 0
  let okuTotal = 0
  let subTotal = 0
  let totalTickets = 0
  let fareFacility = 0
  let fareOther = 0
  let fareInsurance = 0
  let grandTotal = 0
  let seatString = ""
  if (seatResult !== null) {
    fare =  seatResult.fare
    adultTotal = adultTicketQuantity*fare.adult
    childTotal = childTicketQuantity*fare.child
    seniorTotal = seniorTicketQuantity*fare.senior
    okuTotal = okuTicketQuantity*fare.disabled
    subTotal = adultTotal+childTotal+seniorTotal+okuTotal
    totalTickets = adultTicketQuantity+childTicketQuantity+seniorTicketQuantity+okuTicketQuantity
    fareFacility = fare.facility * totalTickets
    fareOther = fare.other * totalTickets
    fareInsurance = includeInsurance ? fare.insurance * totalTickets : 0
    grandTotal = subTotal+fareFacility+fareOther+fareInsurance
    seatString = selectedSeats.map((seat) => seat).join(",");
  }


  const ticketTypes = [
    { type: 'Adult', quantity: adultTicketQuantity, fare: fare.adult, total: adultTotal },
    { type: 'Child', quantity: childTicketQuantity, fare: fare.child, total: childTotal },
    { type: 'Senior', quantity: seniorTicketQuantity, fare: fare.senior, total: seniorTotal },
    { type: 'Disabled', quantity: okuTicketQuantity, fare: fare.disabled, total: okuTotal },
  ];

  useEffect(() => {
    if (isDesktop) {
      if (selectedDate === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
      
      if (skipLogin) {
        confirmButtonPressed()
      }
    }

    const oldDate = DateTime.fromISO(selectedDate);

    // Format the DateTime object to the desired string format
    const newDateString = oldDate.toFormat("dd-MM-yyyy");
    dispatch(createAction("trip/loadGetSeat")({date:newDateString,trip:selectedTrip.code}));

  }, [dispatch, selectedTrip, skipLogin]);

     
  const languageSelected = (language) => {

    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };


  const updateTicketQuantityHelper = (currentQuantity, plus, ticketType, dispatchAction) => {
    let localCount = currentQuantity;
  
    if (plus) {
      localCount++;
    } else {
      localCount--;
    }
  
    if (localCount < 0) {
      localCount = 0;
    }
  
    let counts = {
      adult: adultTicketQuantity,
      child: childTicketQuantity,
      senior: seniorTicketQuantity,
      oku: okuTicketQuantity
    };
  
    counts[ticketType] = localCount;
  
    if (!ticketQuantityPassed(counts)) {
      alert(LocalizedString.pleaseSelectSeat);
      return;
    }
  
    dispatch(dispatchAction(localCount));
  };


  const confirmButtonPressed = () => {
    let total = adultTicketQuantity + childTicketQuantity + seniorTicketQuantity + okuTicketQuantity;

    if (total === 0) {
      alert(LocalizedString.minimum1Ticket)
      return
    }

 
    if (memberId == null && !skipLogin) {

      if (isDesktop){
        setShowSetting(true);
      }else{
        navigation.navigate("MainModal");
        dispatch(createAction('member/setShowModalLogin')(true));  
      }
      
      return
    }else{
      if (isDesktop){
        if (selectedSeats.length !== total) {
          
          alert(LocalizedString.pleaseSelectSeat)
          return
        }
  
        navigation.navigate('PersonalInfo')
      }else{
        navigation.navigate("Seats")
      }
    }

    // if (isDesktop){
    //   if (selectedSeats.length !== total) {
        
    //     alert(LocalizedString.pleaseSelectSeat)
    //     return
    //   }

    //   navigation.navigate('PersonalInfo')
    // }else{
    //   navigation.navigate("Seats")
    // }


  }

  
  const seniorButtonPressed = (plus) => {
    updateTicketQuantityHelper(seniorTicketQuantity, plus, 'senior', createAction("trip/setSeniorTicketQuantity"));
  };
  
  const adultButtonPressed = (plus) => {
    updateTicketQuantityHelper(adultTicketQuantity, plus, 'adult', createAction("trip/setAdultTicketQuantity"));
  };
  
  const childButtonPressed = (plus) => {
    updateTicketQuantityHelper(childTicketQuantity, plus, 'child', createAction("trip/setChildTicketQuantity"));
  };
  
  const okuButtonPressed = (plus) => {
    updateTicketQuantityHelper(okuTicketQuantity, plus, 'oku', createAction("trip/setOkuTicketQuantity"));
  };

  const toggleCheckbox = () => {
    //console.log('toggleCheckbox')
    dispatch(createAction('trip/setIncludeInsurance')(!includeInsurance))
  };

  const ticketQuantityPassed = (counts) => {
    let { adult, child, senior, oku } = counts;
    let totalQuantity = adult + child + senior + oku;
    
    return totalQuantity <= selectedTrip.vac && totalQuantity <= MAX_PAX;
  };  

  const renderMobileSummary = () => (
    <View style={styles.containerSummary}>
    <Text style={styles.title}>{selectedTrip && selectedTrip.brandName}</Text>
    <View style={styles.containerFromTo}>
      <View style={styles.frameParent}>
        <View style={styles.fromWrapper}>
          <Text style={styles.from}>{LocalizedString.fromC}</Text>
        </View>
        <Image
          style={styles.frameItem}
          resizeMode="cover"
          source={require("../assets/group-45.png")}
        />
        <View style={styles.toWrapper}>
          <Text style={styles.to}>{LocalizedString.toC}</Text>
        </View>
      </View>
      <View style={styles.kprKamparParent}>
        <Text style={styles.kprKampar}>
          <Text style={styles.kpr}>{fromSelectedStation.stationCode}{`\n`}</Text>
          <Text style={styles.kampar}>{fromSelectedStation.stationName}</Text>
        </Text>
        <Text style={styles.jbLarkinLarContainer}>
        <Text style={styles.kampar}>{toSelectedStation.stationName}{`\n`}</Text>
          <Text style={styles.kpr}>{toSelectedStation.stationCode}</Text>                
        </Text>
      </View>
    </View>
    <View style={styles.containerSummaryChild} />
    <View style={styles.frameGroup}>
      <View style={styles.frameContainer}>
        <View style={styles.frameView}>
          <View style={styles.dateWrapper}>
            <Text style={styles.date}>{LocalizedString.date}</Text>
          </View>
          <View style={styles.calenderParent}>
            <Image
              style={styles.calenderIcon}
              resizeMode="cover"
              source={require("../assets/calender.png")}
            />
            <View style={styles.wrapper}>
              <Text style={styles.text}>{selectedDate}</Text>
            </View>
          </View>
        </View>
        <View style={styles.frameInner} />
      </View>
      <View style={styles.frameParent1}>
        <View style={styles.frameView}>
          <View style={styles.dateWrapper}>
            <Text style={styles.date}>{LocalizedString.time}</Text>
          </View>
          <View style={styles.calenderParent}>
            <Image
              style={styles.calenderIcon}
              resizeMode="cover"
              source={require("../assets/clock.png")}
            />
            <View style={styles.wrapper}>
              <Text style={styles.text}>{selectedTrip && selectedTrip.time}</Text>
            </View>
          </View>
        </View>
        <View style={styles.frameInner} />
      </View>
    </View>
  </View>
    );

  const renderSeatQuantitySelection = () => (
    <View style={styles.containerSeatsSelection}>
      <Text style={styles.totalVacantSeats}>{LocalizedString.totalVacantSeats}{selectedTrip && selectedTrip.vac}</Text>

      {seatResult !== null ? 
            <ChooseTicketQuantityForm 
            adultPrice={seatResult.fare.adult} childPrice={seatResult.fare.child} currency={seatResult.fare.currency} seniorPrice={seatResult.fare.senior} okuPrice={seatResult.fare.disabled} 
            adultQuantity={adultTicketQuantity} childQuantity={childTicketQuantity}  seniorQuantity={seniorTicketQuantity} okuQuantity={okuTicketQuantity} 
            adultButtonPressed={adultButtonPressed} childButtonPressed={childButtonPressed} okuButtonPressed={okuButtonPressed} seniorButtonPressed={seniorButtonPressed}            
          />        
        : 
        undefined
      }
    </View>
    );

    const renderSeatSelection = () => (
      seatResult == null ? undefined :
      <Desktop>          
        <View style={styles.seats}>
          <SeatLegend />
          <View style={[seatResult.upper && seatResult.upper.seatDetails.length > 0 ? styles.seatSelectionDoubleRow : styles.seatSelection]}>        
            <View style={styles.seatMap}>
            {seatResult.lower && seatResult.lower.seatDetails.length > 0 ?  
            <TouchableOpacity
              style={[ styles.buttonFlexBox]}
            >
              <Text style={[styles.confirmTypo]}>{LocalizedString.lowerDeck}</Text>
            </TouchableOpacity>
            : undefined }
              <SeatMap row={seatResult.lower.row} col={seatResult.lower.col} seatDetails={seatResult.lower.seatDetails} selectedSeats={selectedSeats}  dispatch={dispatch} useSelector={useSelector}/>            
            </View>

            {seatResult.upper && seatResult.upper.seatDetails.length > 0 ?  
            <View style={styles.seatMap}>
              <TouchableOpacity
                style={[styles.buttonFlexBox]}
              >
                <Text style={[styles.confirmTypo]}>{LocalizedString.upperDeck}</Text>
              </TouchableOpacity>
                <SeatMap row={seatResult.upper.row} col={seatResult.upper.col} seatDetails={seatResult.upper.seatDetails} selectedSeats={selectedSeats}  dispatch={dispatch} useSelector={useSelector}/>     
            </View> : undefined
            }          
          </View>
        </View>  
      </Desktop>  
    )

    const renderSummary = () => (
      <Desktop>
        <View style={styles.detail}>
          <View style={styles.ticketDetail}>
            <TicketDetailsSection selectedTrip={selectedTrip}
              toSelectedStation={toSelectedStation}
              fromSelectedStation={fromSelectedStation}
              seatString={seatString}
              selectedDate={selectedDate}
              contactNo={contactNumber}
              showContactInfo={false}
              contactId={icPassportNumber}
              shouldShowInsuranceCheckBox={seatResult && seatResult.withInsurance === "true"}
              includeInsurance={includeInsurance}
              toggleCheckbox={toggleCheckbox}
              insuranceFee={fare.insurance}
            />
          </View>
        
            <TicketFeeSummary fare={fare} ticketTypes={ticketTypes} fareFacility={fareFacility} fareOther={fareOther} 
            fareInsurance={fareInsurance} grandTotal={grandTotal} subTotal={subTotal} />

        </View>
      </Desktop>
    )

    let seatContainerStyle = isDesktop ? styles.desktopSeatContainer : styles.mobileSeatContainer;
  
    if (selectedDate === null) {
      return (<View></View>)
    }
    return (
    <View style={CommonStyle.container}>   
      <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={<DesktopFooter navigation={navigation} isDesktop={isDesktop} />} showSetting={showSetting} setShowSetting={setShowSetting} 
          navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="FlightDetails"/>}> 
    
    <Desktop>
             
        <View style={CommonStyle.desktopSearchView}>              
          <Breadcrumb
              items={[
                { label: LocalizedString.home, navigate: () => navigation.navigate('DesktopHome') },
                { label: LocalizedString.searchResults, navigate: () => navigation.navigate('SearchResult') },
                // { label: LocalizedString.tripDetails, navigate: () =>  //console.log('Category clicked') },
              ]}
            />    
          <TripComponent trip={selectedTrip} fromSelectedStation={fromSelectedStation} toSelectedStation={toSelectedStation} selectedDate={selectedDate} handleItemPress={null} />            
        </View>        
      
      </Desktop>
      
      <ScrollView>
      <View style={styles.tripDetails}> 
        <View style={styles.container}>
        <Default>
          {renderMobileSummary()}
        </Default>
        <View style={seatContainerStyle}>
          {renderSeatQuantitySelection()}     
          {renderSeatSelection()}
          {renderSummary()}
        </View>
        <Default>
        <View style={styles.containerButton}>         
          <Pressable
            style={styles.buttonSmall1}
            onPress={confirmButtonPressed}
          >
            <Text style={styles.confirm1}>{LocalizedString.confirm}</Text>
          </Pressable>
        </View>
        </Default>
        </View>
        </View>
        </ScrollView>
         <Desktop>
      <View style={CommonStyle.withDesktopButtonFooter}></View>
        <DesktopButtonFooter navigation={navigation} buttonName={LocalizedString.confirm} buttonPressed={confirmButtonPressed} />
      </Desktop>
      </DesktopWrapper>

    </View>
  );
};

const styles = StyleSheet.create({
  desktopSeatContainer:{
    flexDirection: "row",
    justifyContent: "space-between",
    
  },
  seatSelection:{
    flexDirection: "row",
  },
  seatSelectionDoubleRow: {
    flexDirection: "column",
  },
  seats:{
    marginHorizontal:10,
    //padding:50,
    backgroundColor: Color.white,
    borderRadius: Border.br_base,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingHorizontal: 20,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    marginBottom: 10,
  },
  seatMap:{
    marginHorizontal: 20,
  },  
  mobileSeatContainer:{

  },
  icon: {
    position: "relative",
    width: 31,
    height: 9,
  },
  frameChild: {
    position: "relative",
    width: 18,
    height: 18,
    marginLeft: 3.07,
  },
  leftArrowIcon: {
    position: "relative",
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  leftArrow: {
    flexDirection: "row",
    padding: Padding.p_9xs,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  bookFlight: {
    position: "relative",
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "center",
    width: 192,
  },
  header: {
    flexDirection: "row",
    paddingHorizontal: Padding.p_17xl,
    paddingVertical: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  menuIcon: {
    position: "relative",
    width: 24,
    height: 24,
    overflow: "hidden",
    display: "none",
  },
  hamburgerMenu: {
    flexDirection: "row",
    padding: Padding.p_9xs,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  content: {
    flexDirection: "row",
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    alignItems: "center",
    justifyContent: "center",
  },
  topNavBar: {
    alignSelf: "stretch",
    backgroundColor: Color.gray_100,
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    position: "relative",
    fontSize: FontSize.size_xl,
    lineHeight: 24,
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.steelblue_100,
    textAlign: "left",
  },
  from: {
    position: "relative",
    fontSize: FontSize.size_base,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "left",
  },
  fromWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  frameItem: {
    position: "relative",
    width: 193,
    height: 38,
    marginLeft: 14,
  },
  buttonFlexBox: {
    paddingVertical: Padding.p_base,
    marginTop: 14,
    borderRadius: Border.br_5xs,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Color.whitesmoke_100,    
  },
  confirmTypo: {
    width: 120,
    lineHeight: 24,
    fontSize: FontSize.size_lg,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    color: Color.gray555,
  },
  to: {
    position: "relative",
    fontSize: FontSize.size_base,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "right",
  },
  toWrapper: {
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    marginLeft: 14,
  },
  frameParent: {
    width: 296,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  kpr: {
    fontSize: FontSize.size_base,
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
  },
  kampar: {
    fontSize: FontSize.size_xs,
    fontWeight: "300",
    fontFamily: FontFamily.interLight,
  },
  kprKampar: {
    position: "relative",
    color: Color.dimgray_200,
    textAlign: "left",
    width: 97,
  },
  jbLarkinLarContainer: {
    position: "relative",
    color: Color.dimgray_200,
    textAlign: "right",
    width: 105,
    marginLeft: 94,
  },
  kprKamparParent: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 12,
  },
  containerFromTo: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 21,
  },
  containerSummaryChild: {
    position: "relative",
    borderStyle: "solid",
    borderColor: "#e6e8e7",
    borderTopWidth: 1,
    width: 329,
    height: 1,
    marginTop: 21,
  },
  date: {
    position: "relative",
    fontSize: FontSize.size_xs,
    lineHeight: 16,
    fontWeight: "300",
    fontFamily: FontFamily.interLight,
    color: Color.gray555,
    textAlign: "left",
  },
  dateWrapper: {
    borderRadius: Border.br_13xl,
    backgroundColor: Color.white,
    flexDirection: "row",
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: 0,
    marginBottom: 10,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  calenderIcon: {
    position: "relative",
    width: 20,
    height: 20,
    overflow: "hidden",
  },
  text: {
    position: "relative",
    fontSize: FontSize.size_sm,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "left",    
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginLeft: 10,
  },
  calenderParent: {
    flexDirection: "row",
    paddingLeft: Padding.p_5xs,
    paddingTop: Padding.p_9xs,
    paddingRight: Padding.p_9xs,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  frameView: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  frameInner: {
    position: "relative",
    borderRadius: Border.br_5xs,
    borderStyle: "solid",
    borderColor: "#e6e8e7",
    borderWidth: 1,
    width: 140,
    height: 46,
    marginTop: -36,
  },
  frameContainer: {
    width: 140,
    height: 54,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  frameParent1: {
    width: 140,
    height: 54,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: 16,
  },
  frameGroup: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 21,
  },
  containerSummary: {
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    flexDirection: "column",
    paddingTop: Padding.p_base,
    paddingBottom: Padding.p_5xl,
    alignItems: "center",
    justifyContent: "flex-start",
  },
  totalVacantSeats: {
    fontSize: FontSize.size_xl,
    lineHeight: 24,
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.black,
    textAlign: "left",
    //width: 270,
    height: 24,
    marginLeft: 7,
  },
  containerSeatsSelection: {
    width: 328,
    height: 364,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 14,
  },
  confirm: {
    position: "relative",
    fontSize: FontSize.size_lg,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.secondary,
    textAlign: "center",
    width: 88,
  },
  buttonSmall: {
    borderRadius: Border.br_5xs,
    borderStyle: "solid",
    borderColor: "#ec441e",
    borderWidth: 1,
    width: 156,
    flexDirection: "row",
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    justifyContent: "center",
  },
  confirm1: {
    position: "relative",
    fontSize: FontSize.size_lg,
    lineHeight: 24,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.white,
    textAlign: "center",
    width: 88,
  },
  buttonSmall1: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 156,
    flexDirection: "row",
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 16,
  },
  containerButton: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 14,
  },
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  tripDetails: {
    backgroundColor: Color.gray_100,
    flex: 1,
    width: "100%",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  detail:{
    marginBottom: 10,
  },
  ticketDetail: {
    width: 330
  },
});

export default FlightDetails;
