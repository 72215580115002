import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet ,Image} from 'react-native';
import { Color, LocalizedString } from '@common';
import BtnLanguage from '../components/BtnLanguage';
import { Box, Menu } from 'native-base';
import { DUMMY} from '@constants';

const DesktopNavbar = ({ languageSelected, language,memberId, settingPressed, logoutPressed,navigation, selectedRoute }) => {
    
  function homePressed() {
    navigation.navigate('DesktopHome')
  }

  function insurancePressed(){
    navigation.navigate('Insurance')
  }

  function myBookingPressed() {
    navigation.navigate('MyBookings')
  }

  function changePasswordPressed() {
    navigation.navigate('ResetPassword');
  }

  function myProfilePressed() {
    navigation.navigate('MyProfile');
  }

  function faqPressed() {
    navigation.navigate('Faq');
  }

  function contactUsPressed() {
    navigation.navigate('ContactUs');
  }



  function getButtons() {
    if (DUMMY){
     return (
      <View style={{flexDirection:'row'}}>
      <TouchableOpacity onPress={homePressed} style={styles.button} >
        <Text style={ selectedRoute == 'DesktopHome' ? styles.selected : styles.unselected }>{LocalizedString.home}</Text>
    </TouchableOpacity>
    <TouchableOpacity onPress={faqPressed} style={styles.button} >
            <Text style={ selectedRoute == 'Faq' ? styles.selected : styles.unselected }>{LocalizedString.faq}</Text>
          </TouchableOpacity>
    <TouchableOpacity onPress={insurancePressed} style={styles.button} >
      <Text style={ selectedRoute == 'Insurance' ? styles.selected : styles.unselected }>{LocalizedString.insurance}</Text>
    </TouchableOpacity>
    <TouchableOpacity onPress={contactUsPressed} style={styles.button} >
      <Text style={ selectedRoute == 'ContactUs' ? styles.selected : styles.unselected }>{LocalizedString.contactUs}</Text>
    </TouchableOpacity>
    </View>
     )
    }
    if (memberId == null) {
      return (   
        <View style={{flexDirection:'row'}}>
            <TouchableOpacity onPress={settingPressed} style={styles.button}>
              <Text>Login/Create Account</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={homePressed} style={styles.button} >
              <Text style={ selectedRoute == 'DesktopHome' ? styles.selected : styles.unselected }>{LocalizedString.home}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={faqPressed} style={styles.button} >
            <Text style={ selectedRoute == 'Faq' ? styles.selected : styles.unselected }>{LocalizedString.faq}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={insurancePressed} style={styles.button} >
            <Text style={ selectedRoute == 'Insurance' ? styles.selected : styles.unselected }>{LocalizedString.insurance}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={contactUsPressed} style={styles.button} >
            <Text style={ selectedRoute == 'ContactUs' ? styles.selected : styles.unselected }>{LocalizedString.contactUs}</Text>
          </TouchableOpacity>
      </View>
      )
      } else {
        return (   
          <View style={{flexDirection:'row'}}>
           <TouchableOpacity onPress={homePressed} style={styles.button} >
            <Text style={ selectedRoute == 'DesktopHome' ? styles.selected : styles.unselected }>{LocalizedString.home}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={myBookingPressed} style={styles.button}>
            <Text style={ selectedRoute == 'MyBookings' ? styles.selected : styles.unselected }>{LocalizedString.myBookings}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={faqPressed} style={styles.button} >
            <Text style={ selectedRoute == 'Faq' ? styles.selected : styles.unselected }>{LocalizedString.faq}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={insurancePressed} style={styles.button} >
            <Text style={ selectedRoute == 'Insurance' ? styles.selected : styles.unselected }>{LocalizedString.insurance}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={contactUsPressed} style={styles.button} >
            <Text style={ selectedRoute == 'ContactUs' ? styles.selected : styles.unselected }>{LocalizedString.contactUs}</Text>
          </TouchableOpacity>
          <Box  alignItems="center">
            <Menu w="190" trigger={triggerProps => {
            return <TouchableOpacity accessibilityLabel="More options menu" {...triggerProps} style={styles.button}>
                    <Text>{memberId}</Text>
                  </TouchableOpacity>;
          }}>
              <Menu.Item  onPress={changePasswordPressed}>{LocalizedString.changePassword}</Menu.Item>
              <Menu.Item onPress={myProfilePressed}>{LocalizedString.myProfile}</Menu.Item>
              <Menu.Item onPress={logoutPressed}>{LocalizedString.logout}</Menu.Item>            
            </Menu>
          </Box>
      </View>
      )
    }
  }
  
  return (
    <View style={styles.navBar}>
            <TouchableOpacity onPress={homePressed}>
              <Image style={{width:200,height:70}} source={require('../assets/logo-1.png')} />
            </TouchableOpacity>
            <View style={styles.navBarRight}>
            {getButtons()}
            <BtnLanguage onSelectLanguage={languageSelected} language={language} />
            </View>
        </View>
  );
};

const styles = StyleSheet.create({
  navBar:{
    flexDirection: "row",
    width: '100%',
    alignItems: "center",
    height: 90,
    backgroundColor: 'white',
    paddingHorizontal: 20,
  },
  button:{
    paddingVertical: 10,
    paddingRight: 30,
  },  
  navBarRight:{
      flexDirection: "row",
      position: 'absolute',
      alignItems: "center",
      height: 70,
      right: 0,
  },
  selected:{
    color: Color.blue
  },
  unselected:{
    color: Color.black
  }
});

export default DesktopNavbar;
