import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { Color, FontSize, FontFamily, Padding } from '@common';
import { useDispatch } from 'react-redux';
import { createAction } from '@utils';

const BtnLanguage = ({ onSelectLanguage, language }) => {
    
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    
    setSelectedLanguage(language);
    handleLanguageSelection(language) //added this , make sure it press already
  }, [language]);

  const handleLanguageSelection = (language) => {
    setSelectedLanguage(language);
    onSelectLanguage(language);
    dispatch(createAction('config/setLanguage')({ language }));
  };


  return (
    <View style={styles.btnLanguage}>
      <TouchableOpacity
        style={[styles.bgEng, styles.bgBmSpaceBlock, selectedLanguage === 'en' ? styles.selected : styles.unselected]}
        onPress={() => handleLanguageSelection('en')}>
        <Text style={[styles.en, styles.enTypo, selectedLanguage === 'en' ? styles.selectedText : styles.unselectedText]}>EN</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.bgBm, styles.bgBmSpaceBlock, selectedLanguage === 'ms' ? styles.selected : styles.unselected]}
        onPress={() => handleLanguageSelection('ms')}>
        <Text style={[styles.bm, selectedLanguage === 'ms' ? styles.selectedText : styles.unselectedText]}>BM</Text>
      </TouchableOpacity>
  </View>
  );
};

const styles = StyleSheet.create({
  btnLanguage: {
    width: 80,
    alignItems: 'center',
    height: 28,
    flexDirection: 'row',
  },
  bgEng: {
    
  },
  bgBm: {
    marginLeft: 8,    
  },
  selected: {
    backgroundColor: Color.steelblue_200,
    color: Color.white,
  },
  unselected: {
    backgroundColor: Color.white,
    color: Color.black,
  },
  selectedText: {
    color: Color.white,
  },
  unselectedText: {
    color: Color.black,
  },
  en: {    
    textAlign: 'left',
  },
  enTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    lineHeight: 24,
    fontSize: FontSize.size_xs,
  },
  bm: {    
    textAlign: 'left',
    fontFamily: FontFamily.interMedium,
    fontWeight: '500',
    lineHeight: 24,
    fontSize: FontSize.size_xs,
  },
  bgBmSpaceBlock: {
    paddingVertical: 0,
    paddingHorizontal: Padding.p_8xs,
    width: 30,
    height: 28,
    alignItems: 'flex-end',
    justifyContent: 'center',
    overflow: 'hidden',
  },
});

export default BtnLanguage;
