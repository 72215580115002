import React, {useEffect, useState} from "react";
import { StyleSheet, View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { Border, FontFamily, FontSize, Color, Padding, LocalizedString } from "@common";
import { useDispatch, useSelector } from "react-redux";
import { createAction } from "@utils";
import SeatMap from "../components/SeatMap";
import SeatLegend from "../components/SeatLegend";

const Seats = ({navigation}) => {

  const dispatch = useDispatch();  

  const [lowerDeckSelected, setLowerDeckSelected] = useState(true);
  const selectedTrip = useSelector((state) => state.trip.selectedTrip);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  
  const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
  const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
  const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
  const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);
  const seatResult = useSelector((state) => state.trip.seatResult);
  const selectedSeats = useSelector((state) => state.trip.selectedSeats);

  const {fare, brand, brandName, trip,coachClass,upper,lower } = seatResult
  let showButton = upper.seatDetails.length > 0 && lower.seatDetails.length > 0;

  useEffect(() => {

  }, [dispatch]);

  const onLowerDeckSelected = () => {
    setLowerDeckSelected(true)
  }

  const onUpperDeckSelected = () => {
    setLowerDeckSelected(false)
  }

  const confirmButtonPressed = () => {
    let total = getTotalQuantity()

    if (selectedSeats.length !== total) {
      alert(LocalizedString.pleaseSelectSeat)
      return
    }

    navigation.navigate('PersonalInfo')
  }

  const getTotalQuantity = () => {
    return okuTicketQuantity+childTicketQuantity+adultTicketQuantity+seniorTicketQuantity;
  }



  const DeckButtons = () => (
    <View
    style={[styles.containerSelection, styles.rectangleParentFlexBox]}
  >
    <TouchableOpacity
      style={[styles.buttonBig, styles.buttonFlexBox, lowerDeckSelected ? styles.deckSelected : styles.deckUnselected]}
      onPress={onLowerDeckSelected}
    >
      <Text style={[lowerDeckSelected ? styles.confirm : styles.confirm1, styles.confirmTypo]}>{LocalizedString.lowerDeck}</Text>
    </TouchableOpacity>
    <TouchableOpacity
      style={[styles.buttonBig, styles.buttonFlexBox, lowerDeckSelected ? styles.deckUnselected : styles.deckSelected]}
      onPress={onUpperDeckSelected}
    >
      <Text style={[lowerDeckSelected ? styles.confirm1 : styles.confirm, styles.confirmTypo]}>
        {LocalizedString.upperDeck}
      </Text>
    </TouchableOpacity>
  </View>  
  )
  


let data = lowerDeckSelected ? seatResult.lower : seatResult.upper;

const { row, col, seatDetails } = data;

  return (
    <ScrollView style={styles.seats} contentContainerStyle={{alignItems: 'center'}}>
      <SeatLegend />
      <View style={[styles.frame]}>
      <Text>{LocalizedString.noOfSeats}{okuTicketQuantity+childTicketQuantity+adultTicketQuantity+seniorTicketQuantity}</Text>
        { showButton ?
             DeckButtons()
        : undefined}
        <SeatMap row={row} col={col} seatDetails={seatDetails} selectedSeats={selectedSeats}  dispatch={dispatch} useSelector={useSelector}/>
        <TouchableOpacity
          style={[styles.btnConfirm, styles.buttonFlexBox]}
          onPress={confirmButtonPressed}
        >
          <Text style={[styles.confirm, styles.confirmTypo]}>{LocalizedString.confirm}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  containerSelection: {
    height: 56,
  },
  buttonFlexBox: {
    paddingVertical: Padding.p_base,
    borderRadius: Border.br_5xs,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  confirmTypo: {
    width: 120,
    lineHeight: 24,
    fontSize: FontSize.size_lg,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  confirm: {
    color: Color.white,
  },
  confirm1: {
    color: Color.gray555,
  },
  rectangleParentFlexBox: {
    marginTop: 15,
    alignItems: "center",
    flexDirection: "row",
  },
  deckUnselected: {
    backgroundColor: Color.whitesmoke_100,
  },
  deckSelected: {
    backgroundColor: Color.steelblue_200,
  },
  btnConfirm: {
    width: 296,
    marginTop: 74,
    backgroundColor: Color.steelblue_200,
    paddingVertical: Padding.p_base,
    paddingHorizontal: Padding.p_85xl,
    borderRadius: Border.br_5xs,
  },
  frame: {    
    paddingHorizontal: Padding.p_sm,
    paddingVertical: 10,
    alignItems: "center", 
  },
  seats: {
    flex: 1,
    width: "100%",    
    backgroundColor: Color.gray_100,
  },
});


export default Seats;
