import { post, get, put, del } from './webservice_helper';
import { CLIENT_ID } from '@constants';
import CryptoJS from 'crypto-js';

export async function loginUser({memberId,password,rememberMe}){

    const endpoint = "login";
    let md5Password = CryptoJS.MD5(password).toString(CryptoJS.enc.Hex);

    //Assuming the request requires authentication
    const body = {
        "clientId": CLIENT_ID,
        "memberId": memberId,
        "password": md5Password,
        "rememberMe": rememberMe || false
    };
    

    // const body = {
    //     "clientId": CLIENT_ID,
    //     "memberId": "ali122a@qyahoo.com.mm",
    //     "password": CryptoJS.MD5("dP0Zp1OS").toString(CryptoJS.enc.Hex),
    //     "rememberMe": true
    // };
    
    
    // Assuming the request requires authentication
    const authenticated = true;
    
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
        
        return { success: true, result };
      } catch (error) {
        //console.log('error', error);
        
        return { success: false, error };
      }
    
};

export async function registerUser ({name,email,phoneNo,password}) {  
      
    const endpoint = "registration";

    // Assuming the request requires authentication
    // const body = {
    //     clientId: CLIENT_ID,
    //     name: "pfoebie manel-songno",
    //     email: "husky@web.www",
    //     phoneNo: "0112239999",
    //     password: "91b5f6f2faab80abc81d9bd8ee65539d",
    //     confirmPassword: "91b5f6f2faab80abc81d9bd8ee65539d"
    // };

    let md5Password = CryptoJS.MD5(password).toString(CryptoJS.enc.Hex);
    
    const body = {
        clientId: CLIENT_ID,
        name: name,
        email: email,
        phoneNo: phoneNo,
        password: md5Password,
        confirmPassword: md5Password
    };

    const authenticated = true;
    
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
};

export async function activation({memberId}) {  
        
     const endpoint = "activation";
    
     // Assuming the request requires authentication
     const body = {
          clientId: CLIENT_ID,
          memberId: memberId
     }

     const authenticated = true;
    
     try {
         const result = await post(endpoint, body, authenticated); // Await for the result
     
         return { success: true, result };
     } catch (error) {
         //console.log('error', error);
     
         return { success: false, error };
     }
}


export async function viewProfile({memberId,authToken}) {  
    //console.log(authToken)
        
    const endpoint = "view-profile";
   
    //console.log(`viewProfile memberId ${memberId} - ${authToken}`);
    // Assuming the request requires authentication
    const body = {
         clientId: CLIENT_ID,
         memberId: memberId,
            authToken: authToken
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}



export async function updateProfile({memberId,name,phoneNo,address,idNo,authToken}) {  
        
    const endpoint = "update-profile";
   
    // Assuming the request requires authentication
    const body = {
         clientId: CLIENT_ID,
         authToken: authToken,
         memberId: memberId,        
            name: name,
            phoneNo: phoneNo,
            address: address,
            idNo: idNo
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}


export async function changePassword({memberId,oldPassword,newPassword,authToken}) {  
        
    const endpoint = "change-password";
   
    let md5OldPassword = CryptoJS.MD5(oldPassword).toString(CryptoJS.enc.Hex);
    let md5NewPassword = CryptoJS.MD5(newPassword).toString(CryptoJS.enc.Hex);
    // Assuming the request requires authentication
    const body = {
         clientId: CLIENT_ID,
         memberId: memberId,
        oldPassword: md5OldPassword,
        newPassword: md5NewPassword,
        authToken: authToken
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}

export async function refreshToken({memberId,authToken}) {
    const endpoint = "refresh-token";

    const body = {
        clientId: CLIENT_ID,
        memberId: memberId,
        authToken: authToken
    };

    const authenticated = true;

    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}



export async function resetPassword({memberId}) {  
        
    const endpoint = "reset-password";
   
    // Assuming the request requires authentication
    const body = {
         clientId: CLIENT_ID,
         memberId: memberId
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}


export async function logout({memberId,authToken}) {  
        
    const endpoint = "logout";
   
    // Assuming the request requires authentication
    const body = {
         clientId: CLIENT_ID,
         memberId: memberId,
         authToken: authToken
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}

export async function listTransactionHistory({memberId,pageNo,recordPerPage,refNo,authToken}) {  
        
    const endpoint = "list-trxHistory";
   
    // Assuming the request requires authentication
    // const body = {
    //      clientId: CLIENT_ID,
    //      memberId: memberId,
    //         month: month,
    //         year: year,
    //         refNo: refNo
    // }
    const body = {
        "clientId": CLIENT_ID,    
        "memberId": memberId,
        "pageNo": pageNo,
        "recordPerPage": recordPerPage,
        "refNo": "",
        "authToken":authToken
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}

export async function printTicket({memberId,authToken,refNo,date}) {
    const endpoint = "print-ticket";

    const body = {
        "clientId": CLIENT_ID,    
        "memberId": memberId,
        "refNo": refNo,
        "date": date,
        "authToken":authToken
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}



export async function getRoute() {  
        
    const endpoint = `get-route?clientId=${CLIENT_ID}`;
   

    const authenticated = true;
   
    try {
        const result = await get(endpoint, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}



export async function getTrip({date,from,to}) {  
        
    const endpoint = `get-trip?clientId=${CLIENT_ID}&date=${date}&from=${from}&to=${to}`;
   

    const authenticated = true;
   
    try {
        const result = await get(endpoint, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}

export async function getSeat({date,trip}) {  
        
    const endpoint = `get-seat?clientId=${CLIENT_ID}&date=${date}&trip=${encodeURIComponent(trip)}`;
   
    const authenticated = true;
   
    try {
        const result = await get(endpoint, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}


export async function confirmSeat({
    memberId,date,trip,seat,
    passengerType,passengerId,passengerName,totalPayableAmount,
    insurance,adult,child,senior,oku
    ,contactId,contactNo,contactEmail,isBooking,authToken}) {  
        
      
    const endpoint = "confirm-seat";
   
    // Assuming the request requires authentication
    const body = {
        clientId: CLIENT_ID,
        memberId: memberId,
        date: date,
        trip: trip,
        seat: seat,
        passengerType: passengerType,
        passengerId: passengerId,
        passengerName: passengerName,
        totalPayableAmount: totalPayableAmount,
        insurance: insurance,
        adult: adult,
        child: child,
        senior: senior,
        oku: oku,
        contactId: contactId,
        contactNo: contactNo,
        isBooking: isBooking,
        authToken: authToken,
        contactEmail: contactEmail,   
    }

    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}

export async function queryPayment({memberId,refNo,date,authToken}) {  
        
    const endpoint = `query-payment`;

    const body = {
        "clientId": CLIENT_ID,    
        "memberId": memberId,
        "refNo": refNo,
        "date": date,
        "authToken":authToken
    }
   
    const authenticated = true;
   
    try {
        const result = await post(endpoint, body, authenticated); // Await for the result
    
        return { success: true, result };
    } catch (error) {
        //console.log('error', error);
    
        return { success: false, error };
    }
}