import React, { useState , useEffect } from 'react';
import { TouchableOpacity, StyleSheet, View, FlatList, Text, RefreshControl, Platform, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Color, Padding, FontSize, FontFamily, Border, LocalizedString, CommonStyle } from "@common";
import { createAction } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from "@react-navigation/native";
import { ScrollView } from 'react-native-gesture-handler';
import DesktopFooter from '../components/DesktopFooter';

const ChooseRoute = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { direction } = route.params;

  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const [keyword, setKeyword] = useState('');

  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.member.memberId);
  const routes = useSelector((state) => state.trip.routes);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const [filteredRoutes, setFilteredRoutes] = useState(routes);

  useEffect(() => {
    loadRoutes()
    
  }, []);

  const onItemPress = (item) => {
    // Navigate and pass parameters to RouteDetail screen

    if(direction === 'from'){
        dispatch(createAction('trip/setFromSelectedStation')(item));
    }else{
        dispatch(createAction('trip/setToSelectedStation')(item));
    }
    navigation.goBack()
  };

  const onChangeText = (text) => {
    setKeyword(text)
    if (text === undefined || text === '') {
      setFilteredRoutes(routes)
    }
    else {
      setFilteredRoutes(routes.filter((route) => route.stationName.toLowerCase().includes(keyword.toLowerCase())))
    }
  }

  const loadRoutes = () => {
    let callback = (eventObject) => {
      setRefreshing(false);
    }
    dispatch(createAction('trip/loadGetRoute')({}));
  }

  const handleRefresh = () => {
    setRefreshing(true);
    loadRoutes()
 
  };

  const handleLoadMore = () => {
    setLoadingMore(true);

    // Fetch more data and update the state
    // Simulating network request
    setTimeout(() => {
      setLoadingMore(false);
    }, 2000);
  };
  
  const renderRouteItem = ({ item }) => (
    <TouchableOpacity onPress={() => onItemPress(item)}>
    <View style={styles.frameShadowBox}>
      <View style={styles.groupParent}>        
        <Text style={[styles.coachJasaPelangiContainer, styles.fromToSeatTypo]}>
          <Text style={styles.coach}>{LocalizedString.region}</Text>
          <Text style={styles.jasaPelangi}>{`${item.region}\n`}</Text>
          <Text style={styles.coach}>{LocalizedString.station}</Text>
            <Text style={styles.jasaPelangi}>{`${item.stationName}\n`}</Text>
            <Text style={styles.coach}>{LocalizedString.stationCode}</Text>
            <Text style={styles.jasaPelangi}>{item.stationCode}</Text>
        </Text>
      </View>     
    </View>
    </TouchableOpacity>
  );
  
  return (
    <ScrollView contentContainerStyle={[styles.ChooseRoute, styles.topBarBg]}>
      <View style={styles.flexbox}>
      <View style={styles.textbox}>
          <TextInput
              style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
              value={keyword}
              placeholderTextColor={Color.lightslategray}
              onChangeText={onChangeText}
              placeholder={LocalizedString.enterKeyword}
          />
      </View>
    <FlatList
  data={filteredRoutes || routes}
  renderItem={renderRouteItem}
  keyExtractor={(item, index) => index.toString()}
  contentContainerStyle={styles.bookingsList}
  refreshControl={
    <RefreshControl
      refreshing={refreshing}
      onRefresh={handleRefresh}
    />
  }
  onEndReached={handleLoadMore}
  onEndReachedThreshold={0.1} // This will trigger handleLoadMore when 10% of the bottom is reached.
  ListFooterComponent={() => 
    Platform.OS === 'web' ? 
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    : null
  }
    />

    {/* { Platform.OS === 'web' ? 
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    : undefined} */}
    </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  flexbox: {
    marginTop: 20,
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  textbox: {
    paddingBottom: Padding.p_5xl,
    paddingLeft: 10,
    paddingTop: Padding.p_base,    
    shadowOpacity: 1,
    elevation: 10,
    width: 330,
    shadowRadius: 10,
    shadowOffset: {
        width: 0,
        height: 5,
    },
    shadowColor: "rgba(89, 27, 27, 0.05)",
    backgroundColor: Color.white,
    borderRadius: Border.br_base,
    alignItems: "left",
    marginBottom: 10
  },
  topBarBg: {
    backgroundColor: Color.gray_100,
    alignItems: "center",
    overflow: "hidden",
  },
  fromToSeatTypo: {
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  coach: {
    color: Color.black,
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  groupParent: {
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
  },
  frameShadowBox: {
    marginTop: 11,
    paddingBottom: Padding.p_5xl,
    paddingTop: Padding.p_base,    
    width: 330,
    shadowOpacity: 1,
    elevation: 10,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowColor: "rgba(89, 27, 27, 0.05)",
    backgroundColor: Color.white,
    borderRadius: Border.br_base,
    alignItems: "left",
  },
  bookingsList: {
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  ChooseRoute: {
    flex: 1,
    flexDirection: 'column',
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
  },
});

export default ChooseRoute;
