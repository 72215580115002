import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Padding, FontSize, FontFamily, Color, Border } from "@common";
import { View, Text, Image, StyleSheet, Platform, TouchableOpacity,Dimensions } from "react-native";
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import { createAction, useDeviceType } from "@utils";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Splash from '../screens/Splash';
import DesktopHome from '../screens/DesktopHome';
import Signup from '../screens/Signup';
import EmailLogin from '../screens/EmailLogin';
import HTMLScreen from '../screens/HTMLScreen';
import Settings from '../screens/Settings';
import MakePayment from '../screens/MakePayment';
import MyBookings from '../screens/MyBookings';
import BookingDetail from '../screens/BookingDetail';
import PaymentSuccess from '../screens/PaymentSuccess';
import PaymentFailed from '../screens/PaymentFailed';
import PaymentPending from '../screens/PaymentPending';
import ResetPassword from '../screens/ResetPassword';
import ChooseRoute from '../screens/ChooseRoute';
import ForgotPassword from '../screens/ForgotPassword';
import Home from '../screens/Home';
import VerifyOTP from '../screens/VerifyOTP';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { LocalizedString } from '@common';
import MyProfile from '../screens/MyProfile';
import DateSection from '../screens/DateSection';
import SearchResult from '../screens/SearchResult';
import FlightDetails from '../screens/FlightDetails';
import Seats from '../screens/Seats';
import PersonalInfo from '../screens/PersonalInfo';
import FlightSummary from '../screens/FlightSummary';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import Term from '../screens/Term';
import Policy from '../screens/Policy';
import Faq from '../screens/Faq';
import ContactUs from '../screens/ContactUs';
import Icon from 'react-native-vector-icons/FontAwesome';
import Insurance from '../screens/Insurance';
import TermListing from '../screens/TermListing';
import Verify from '../screens/Verify';
import { createDrawerNavigator, DrawerItem, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { DUMMY } from "@constants";
import BtnLanguage from "components/BtnLanguage";

// ... other imports

const MainStack = createStackNavigator();
const RootStack = createStackNavigator();
const HomeTab = createBottomTabNavigator();
const SettingStack = createStackNavigator();
const HomeStack = createStackNavigator();
const DrawerStack = createDrawerNavigator();
const MyBookingStack = createStackNavigator();
const PaymentStack = createStackNavigator();
const FaqStack = createStackNavigator();
const InsuranceStack = createStackNavigator();
const ContactUsStack = createStackNavigator();

const screenWidth = Dimensions.get('window').width;

// Calculate 80% of it
const calculatedWidth = screenWidth * 0.9;
const DesktopHomeStack = createStackNavigator();

function WebDrawerTitle({ title, navigation, logo }) {
  const openDrawer = () => {
    navigation.openDrawer();
  };

  return (
    Platform.OS === 'web' ? (
      <View style={styles.headerContainer}>
        <TouchableOpacity onPress={openDrawer}>
          <Icon
            name="navicon"
            style={[styles.menuIcon, styles.iconLayout]}
          />      
        </TouchableOpacity>
        <View style={styles.spacer} />

        { logo ?
         <View style={styles.logoContainer}>
         <Image
           style={styles.headerLogo}
           source={require('../assets/logo-1.png')}
         />
       </View> : 
        <Text style={styles.headerTitle}>
        {title}
      </Text>
        }
       
        <View style={styles.spacer} />
      </View>
    ) : (
      <Text style={styles.headerTitle}>
        {title}
      </Text>
    )
  );
}


function WebLogoTitle({title}) {

  if ( Platform.OS == 'web' ){
    return (
      <View style={styles.headerContainer}>
      <Image
        style={styles.headerLogo}
        source={require('../assets/logo-1.png')}
      />
      {/* <Text style={styles.headerTitle}>
        {title}
    </Text> */}
      </View>
    );
  }
  return (
    <Text style={styles.headerTitle}>
      {title}
    </Text>
  );

}




function HomeIcon({ focused }) {
  let focusStyle = focused ? styles.focused : styles.unfocused;
  return (
    <View style={focused ? [styles.menuhome, styles.iconLayout] : styles.menubooking}>
       <Icon
        name="home"
        style={[styles.menuIcon, styles.iconLayout,focusStyle]}
      />          
    </View>
  );
}

function BookingIcon({ focused }) {
  let focusStyle = focused ? styles.focused : styles.unfocused;
  return (
    <View style={focused ? [styles.menubooking, styles.iconLayout] : styles.menubooking}>
     <Icon
        name="list"
        style={[styles.menuIcon, styles.iconLayout,focusStyle]}
      />        
    </View>
  );
}

function MoreIcon({ focused }) {
  let focusStyle = focused ? styles.focused : styles.unfocused;
  return (
    <View style={focused ? [styles.menubooking, styles.iconLayout] : styles.menubooking}>
      <Icon
        name="gear"
        style={[styles.menuIcon, styles.iconLayout,focusStyle]}
      />           
    </View>
  );
}


function HomeStackScreen() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name="Home" component={Home} 
         options={{
          headerTitle: props => <WebLogoTitle {...props} title={LocalizedString.home} />,
          // other options
        }}
      />      
      <HomeStack.Screen name="ChooseRoute" component={ChooseRoute} 
        options={{ title: LocalizedString.chooseRoute  }} 
      />
      <HomeStack.Screen name="DateSection" component={DateSection} 
        options={{ title: LocalizedString.chooseDate  }}
      />
      <HomeStack.Screen name="SearchResult" component={SearchResult} 
        options={{ title: LocalizedString.searchResults  }} 
      />
      <HomeStack.Screen name="FlightDetails" component={FlightDetails} 
        options={{ title: LocalizedString.tripDetails , headerBackTitle: 'Back'  }}
      />
       <HomeStack.Screen name="Seats" component={Seats} 
        options={{ title: LocalizedString.chooseSeats , headerBackTitle: 'Back'  }}
      />
      <HomeStack.Screen name="PersonalInfo" component={PersonalInfo} 
        options={{ title: LocalizedString.contactInfo , headerBackTitle: 'Back'  }}
      />
      <HomeStack.Screen name="FlightSummary" component={FlightSummary}
        options={{ title: LocalizedString.tripSummary , headerBackTitle: 'Back'  }}
      />
      <HomeStack.Screen name="MakePayment" component={MakePayment}         
      />

      <HomeStack.Screen name="Verify" component={Verify}         
      />
      <HomeStack.Screen name="PaymentSuccess" component={PaymentSuccess}         
      />

      <HomeStack.Screen name="PaymentFailed" component={PaymentFailed}         
      />
      <HomeStack.Screen name="PaymentPending" component={PaymentPending}         
      />
        <HomeStack.Screen name="ContactUs" component={ContactUs}         
      />
      <HomeStack.Screen name="Term" component={Term} 
                options={{ title: LocalizedString.onlineTermsAndConditions  }}
            />
            <HomeStack.Screen name="Policy" component={Policy} 
                options={{ title: LocalizedString.generalTicketingPolicy  }}
            />
            <HomeStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} 
                options={{ title: LocalizedString.privacyPolicy  }}
            />
  
                <HomeStack.Screen name="Faq" component={Faq} 
                options={{ title: LocalizedString.faq  }}
            />
    </HomeStack.Navigator>
  )
}

function MyBookingStackScreen(showMenu) {
  return (
    <MyBookingStack.Navigator>      
      <MyBookingStack.Screen name="MyBookings" component={MyBookings}       
        options={({ navigation }) => (
          showMenu ? {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.myBookings} navigation={navigation} logo={false} />,
            // other options
          } : {
            title: LocalizedString.more
          }
        )} 
      />
      <MyBookingStack.Screen name="BookingDetail" component={BookingDetail}
        options={{ title: LocalizedString.bookingDetail }} />
    </MyBookingStack.Navigator>
  )
}

function SettingStackScreen(showMenu) {
  
  return (
    <SettingStack.Navigator>      
      <SettingStack.Screen name="Settings" component={Settings} 
          options={({ navigation }) => (
            showMenu ? {
              headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.more} navigation={navigation} logo={false} />,
              // other options
            } : {
              title: LocalizedString.more
            }
          )}          
      />
      <SettingStack.Screen name="ResetPassword" 
                component={ResetPassword}     
                options={{ title: LocalizedString.changePassword }}            
      />
      <SettingStack.Screen name="MyProfile" 
                component={MyProfile}     
                options={{ title: LocalizedString.myProfile }}            
      />  
          <SettingStack.Screen name="Important Links" component={TermListing} 
            options={{ title: LocalizedString.important_links  }}  
      />

          <SettingStack.Screen name="Term" component={Term} 
                options={{ title: LocalizedString.onlineTermsAndConditions  }}
        />
        <SettingStack.Screen name="Policy" component={Policy} 
            options={{ title: LocalizedString.generalTicketingPolicy  }}
        />
        <SettingStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} 
            options={{ title: LocalizedString.privacyPolicy  }}
        />

            <SettingStack.Screen name="Faq" component={Faq} 
            options={{ title: LocalizedString.faq  }}
        />
      <SettingStack.Screen name="Insurance" component={Insurance} 
                  options={{ title: LocalizedString.insurance  }}
      />    
    </SettingStack.Navigator>
  )
}

function TermListingScreen(showMenu) {
  
  return (
    <SettingStack.Navigator>      
      <SettingStack.Screen name="Important Links" component={TermListing} 
         options={({ navigation }) => (
          showMenu ? {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.important_links} navigation={navigation} logo={false} />,
            // other options
          } : {
            title: LocalizedString.important_links
          }      
         )}
      />

          <SettingStack.Screen name="Term" component={Term} 
                options={{ title: LocalizedString.onlineTermsAndConditions  }}
        />
        <SettingStack.Screen name="Policy" component={Policy} 
            options={{ title: LocalizedString.generalTicketingPolicy  }}
        />
        <SettingStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} 
            options={{ title: LocalizedString.privacyPolicy  }}
        />

            <SettingStack.Screen name="Faq" component={Faq} 
            options={{ title: LocalizedString.faq  }}
        />
      <SettingStack.Screen name="Insurance" component={Insurance} 
                  options={{ title: LocalizedString.insurance  }}
      />  
    </SettingStack.Navigator>
  )
}


function LoginStackScreen() {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
        headerTitleAlign: 'center',
      }}>
      <MainStack.Screen component={EmailLogin} name="EmailLogin" />
      <MainStack.Screen component={Signup} name="Signup" />
      <MainStack.Screen component={Term} name="Term" />
      <MainStack.Screen component={Home} name="Home" />
      <MainStack.Screen component={Splash} name="Splash" />
      <MainStack.Screen component={VerifyOTP} name="VerifyOTP" />
      <MainStack.Screen component={ForgotPassword} name="ForgotPassword" />      
    </MainStack.Navigator>
  );
}

function PaymentStackScreen(showMenu) {
  return (
    <PaymentStack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
        headerTitleAlign: 'center',
      }}>      
      <PaymentStack.Screen name="Verify" component={Verify}    
        options={({ navigation }) => (
          showMenu ? {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.verify} navigation={navigation} logo={false} />,
            // other options
          } : {
            title: LocalizedString.important_links
          }      
         )}
      />
      <PaymentStack.Screen name="PaymentSuccess" component={PaymentSuccess}    
        options={{ title: LocalizedString.paymentSuccess  }} 
      />
      <PaymentStack.Screen name="PaymentFailed" component={PaymentFailed}    
        options={{ title: LocalizedString.paymentFailedTitle  }} 
      />
      <PaymentStack.Screen name="PaymentPending" component={PaymentPending}    
        options={{ title: LocalizedString.paymentPendingTitle  }} 
      />
    </PaymentStack.Navigator>
  )
}

function FaqStackScreen() {
  return (
    <FaqStack.Navigator
      screenOptions={{
        animationEnabled: false,
        headerTitleAlign: 'center',
      }}>      
      <FaqStack.Screen name="Faq" component={Faq}    
        options={({ navigation }) => (
          {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.faq} navigation={navigation} logo={false} />,
          }      
         )}
      />
    </FaqStack.Navigator>
  )
}

function InsuranceStackScreen() {
  return (
    <InsuranceStack.Navigator
      screenOptions={{
        animationEnabled: false,
        headerTitleAlign: 'center',
      }}>      
      <InsuranceStack.Screen name="Insurance" component={Insurance}    
        options={({ navigation }) => (
          {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.insurance} navigation={navigation} logo={false} />,
          }      
         )}
      />
    </InsuranceStack.Navigator>
  )
}

function ContactUsStackScreen() {
  return (
    <ContactUsStack.Navigator
      screenOptions={{
        animationEnabled: false,
        headerTitleAlign: 'center',
      }}>      
      <ContactUsStack.Screen name="ContactUs" component={ContactUs}    
        options={({ navigation }) => (
          {
            headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.contactUs} navigation={navigation} logo={false} />,
          }      
         )}
      />
    </ContactUsStack.Navigator>
  )
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props}>
      <DrawerItemList {...props} />

    {DUMMY || (props.authToken.authToken != null && props.authToken.authToken != undefined) ? 
        undefined
    :
      <DrawerItem
      label={LocalizedString.login}
      onPress={() => {
        // Custom action when pressed
        props.navigation.navigate('MainModal');
      }}
      />
    }
    <DrawerItem
      label={() => <BtnLanguage onSelectLanguage={props.authToken.languageSelected} language={props.authToken.language} />}
     />
    </DrawerContentScrollView>
  );
}

function DrawerNavigator(authToken) {
  return (
    <DrawerStack.Navigator
     screenOptions={{
      headerShown: true,    
  }}
    drawerContent={(props) => <CustomDrawerContent {...props} authToken={authToken} />}
>
      <DrawerStack.Screen 
        name="Home" 
        component={HomeStackWebScreen}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.home} navigation={navigation} />, headerShown: false,
          title: LocalizedString.home
          // other options
        })}/>
      
      { authToken.authToken !== null ? 
        <DrawerStack.Screen
        name={LocalizedString.myBookings}
        component={MyBookingStackScreen}
        initialParams={{ showMenu: true }}        
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.myBookings} navigation={navigation} logo={true} />, headerShown: false,
          title: LocalizedString.myBookings
          // other options
        })}     
      />
      : undefined }

      <DrawerStack.Screen name="Faq" component={FaqStackScreen}  initialParams={{ showMenu: true }}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.faq} navigation={navigation} logo={true} />, headerShown: false,
          title: LocalizedString.faq
          // other options
        })}
      />

      <DrawerStack.Screen name="Insurance" component={InsuranceStackScreen}  initialParams={{ showMenu: true }}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.insurance} navigation={navigation} logo={true} />, headerShown: false,
          title: LocalizedString.insurance
          // other options
        })}
      />

      <DrawerStack.Screen name="ContactUs" component={ContactUsStackScreen}  initialParams={{ showMenu: true }}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.contactUs} navigation={navigation} logo={true} />, headerShown: false,
          title: LocalizedString.contactUs
          // other options
        })}
      />
     
     <DrawerStack.Screen name="Settings" component={SettingStackScreen}  initialParams={{ showMenu: true }}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={authToken.memberId || LocalizedString.settings} navigation={navigation} logo={true} />, headerShown: false,
          title: authToken.memberId || LocalizedString.settings
          // other options
        })}
      />
      
        {/* <DrawerStack.Screen name="Important Links" component={TermListingScreen}  initialParams={{ showMenu: true }}
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.termsAndConditions} navigation={navigation} logo={false} />, headerShown: false,
          title: LocalizedString.important_links
          // other options
        })}
      /> */}

      <DrawerStack.Screen name="Payment" component={PaymentStackScreen}
        options={{
          drawerItemStyle: { height: 0 }
      }} />
   
   
    </DrawerStack.Navigator>
  );
}


function HomeStackWebScreen() {
  return (
    <HomeStack.Navigator>
   
      <HomeStack.Screen name="MobileHome" component={Home} 
        options={({ navigation }) => ({
          headerTitle: props => <WebDrawerTitle {...props} title={LocalizedString.home} navigation={navigation} logo={true} />,
          // other options
        })}
      />      
      <HomeStack.Screen name="ChooseRoute" component={ChooseRoute} 
        options={{ title: LocalizedString.chooseRoute  }} 
      />
      <HomeStack.Screen name="DateSection" component={DateSection} 
        options={{ title: LocalizedString.chooseDate  }}
      />
      <HomeStack.Screen name="SearchResult" component={SearchResult} 
        options={{ title: LocalizedString.searchResults  }} 
      />
      <HomeStack.Screen name="PaymentSuccess" component={PaymentSuccess}    
        options={{ title: LocalizedString.paymentSuccess  }}      
      />
      <HomeStack.Screen name="PaymentFailed" component={PaymentFailed}    
        options={{ title: LocalizedString.PaymentFailed  }}      
      />
      <HomeStack.Screen name="PaymentPending" component={PaymentPending}    
        options={{ title: LocalizedString.PaymentPending  }}      
      />
      <HomeStack.Screen name="FlightDetails" component={FlightDetails} 
        options={{ title: LocalizedString.tripDetails , headerBackTitle: 'Back'  }}
      />
       <HomeStack.Screen name="Seats" component={Seats} 
        options={{ title: LocalizedString.chooseSeats , headerBackTitle: 'Back'  }}
      />
      <HomeStack.Screen name="PersonalInfo" component={PersonalInfo} 
        options={{ title: LocalizedString.contactInfo , headerBackTitle: 'Back'  }}
      />
      <HomeStack.Screen name="MakePayment" component={MakePayment}
        options={{ title: LocalizedString.enterPaymentDetails , headerBackTitle: 'Back'  }}         
      />

      <HomeStack.Screen name="Verify" component={Verify} />
    
        <HomeStack.Screen name="ContactUs" component={ContactUs}         
      />
      <HomeStack.Screen name="Term" component={Term} 
                options={{ title: LocalizedString.onlineTermsAndConditions  }}
            />
            <HomeStack.Screen name="Policy" component={Policy} 
                options={{ title: LocalizedString.generalTicketingPolicy  }}
            />
            <HomeStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} 
                options={{ title: LocalizedString.privacyPolicy  }}
            />
  
                <HomeStack.Screen name="Faq" component={Faq} 
                options={{ title: LocalizedString.faq  }}
            />
        <HomeStack.Screen
            name="MainModal"
            component={LoginStackScreen}
            options={{ headerShown: false }}
          />
    </HomeStack.Navigator>
  )
}



function HomeTabScreen() {
   

  return (
    <HomeTab.Navigator 
    screenOptions={({ route }) => ({
      headerShown: false,
      tabBarStyle: {          
        backgroundColor: Color.steelblue_200,                  
    },
    tabBarLabelStyle: {
      color: Color.white,
    },       
  })}
    >
      <HomeTab.Screen
        name="HomeStackScreen"
        component={HomeStackScreen}
        options={({ route }) => {
          const focusedRouteName = getFocusedRouteNameFromRoute(route) ?? 'Home';
        
          if (focusedRouteName === 'Home') {
            return {                            
              tabBarIcon: ({ focused }) => <HomeIcon focused={focused} />,   
              title: LocalizedString.home 
            };
          }

          return {
            tabBarStyle: { display: 'none' },
            tabBarIcon: ({ focused }) => <HomeIcon focused={focused} />,   
             title: LocalizedString.home 
          };                 
        }
      }
      />
      <HomeTab.Screen
        name="Booking"
        component={MyBookingStackScreen}
        options={({ route }) => {
          const focusedRouteName = getFocusedRouteNameFromRoute(route) ?? 'MyBookings';
        
          if (focusedRouteName === 'MyBookings') {
            return {              
              tabBarIcon: ({ focused }) => <BookingIcon focused={focused} />,   
            };
          }

          return {
            tabBarStyle: { display: 'none' },
            tabBarIcon: ({ focused }) => <BookingIcon focused={focused} />,   
          };                 
        }
      
      }
      />
      <HomeTab.Screen
        name="More"
        component={SettingStackScreen}   
        initialParams={{ showMenu: false }}
        options={({ route }) => {
          const focusedRouteName = getFocusedRouteNameFromRoute(route) ?? 'Settings';

          //console.log('focusedRouteName', focusedRouteName);
          if (focusedRouteName === 'Settings') {
            return {              
              tabBarIcon: ({ focused }) => <MoreIcon focused={focused} />,  
              title: LocalizedString.more  
            };
          }

          return {
            tabBarStyle: { display: 'none' },
            tabBarIcon: ({ focused }) => <MoreIcon focused={focused} />,   
            title: LocalizedString.more 
          };                 
        }
      }
      />      
    </HomeTab.Navigator>
  );
}

function returnMobileWebview(authToken, isSkipLogin,isLoading,isSkipLoading,memberId,language,languageSelected){
  const linking = {
    config: {
      screens:{
        MyBookings: {
          path: 'my_bookings',
          screens: {
            MyBookings: "my_bookings",
            BookingDetail: "booking_detail",
          }
        },
        Payment: {
          path: 'payment',
          screens: {
            Verify: 'verify',
          }
        },
        DesktopHome: "",
        SearchResult: "search_result",
        FlightDetails: "trip_details",
        Seats: "choose_seats",
        PersonalInfo: "contact_info",
        MakePayment: "make_payment",   
        PaymentSuccess: "payment_success",
        PaymentFailed: "payment_failed",
        PaymentPending: "payment_pending",
        MyProfile: "my_profile",
        ResetPassword: "change_password",    
        Term: "terms_and_conditions",
        Policy: "ticketing_policy",
        PrivacyPolicy: "privacy_policy",
        ContactUs: "contact_us",
        Faq: "faq",  
        Insurance: "insurance",
        Home:"mobile",
        MobileHome:"home",        
        "Important Links":"important_links",
      }
    },
    };

  if (isLoading || isSkipLoading) {
    return <Splash />;
  }
  // if ((authToken === null || authToken === undefined )&& isSkipLogin === false) {
  //   return (
  //     <NavigationContainer linking={linking}>
  //       <RootStack.Navigator 
  //         screenOptions={{presentation:"modal"}}
  //       >
  //         <RootStack.Screen
  //           name="Main"
  //           component={LoginStackScreen}
  //           options={{ headerShown: false }}
  //         />
  //         <RootStack.Screen name="HTMLScreen" component={HTMLScreen} />          
  //         {/* Other modals here */}
  //       </RootStack.Navigator>
  //     </NavigationContainer>
  //   );
  // }else{
    return (
      <NavigationContainer linking={linking}>
        <DrawerNavigator authToken={authToken} memberId={memberId} language={language} languageSelected={languageSelected} />    
      </NavigationContainer>
    );
  // }
}

function returnMobileView(authToken, isSkipLogin,isLoading,isSkipLoading){
 

  const linking = {
    config: {
      screens:{
        MyBookings: "my_bookings",
        BookingDetail: "booking_detail",
        DesktopHome: "",
        SearchResult: "search_result",
        FlightDetails: "trip_details",
        Seats: "choose_seats",
        PersonalInfo: "contact_info",
        MakePayment: "make_payment",
        PaymentSuccess: "payment_success" , 
        PaymentFailed: "payment_failed" ,
        PaymentPending: "payment_pending",    
        MyProfile: "my_profile",
        ResetPassword: "change_password",    
        Term: "terms_and_conditions",
        Policy: "ticketing_policy",
        PrivacyPolicy: "privacy_policy",    
        ContactUs: "contact_us",
        Faq: "faq",  
        Insurance: "insurance",
        Verify:"payment/verify",
      }
    },
    };

  if (isLoading || isSkipLoading) {
    return <Splash />;
  }
  if ((authToken === null || authToken === undefined )&& isSkipLogin === false) {
    return (
      <NavigationContainer linking={linking}>
        <RootStack.Navigator 
          screenOptions={{presentation:"modal"}}
        >
          <RootStack.Screen
            name="Main"
            component={LoginStackScreen}
            options={{ headerShown: false }}
          />
          <RootStack.Screen name="HTMLScreen" component={HTMLScreen} />          
          {/* Other modals here */}
        </RootStack.Navigator>
      </NavigationContainer>
    );
  }else{
    return (
      <NavigationContainer linking={linking}>
        <RootStack.Navigator 
         screenOptions={{
                   headerShown: false,    
        }}
        >
      
          <HomeTab.Screen name="HomeNavigator" component={HomeTabScreen} />
          <HomeTab.Screen name="ResetPassword" component={ResetPassword} />
          <HomeTab.Screen
            name="MainModal"
            component={LoginStackScreen}
            options={{ headerShown: false }}
          />
          {/* Other modals here */}
        </RootStack.Navigator>
      </NavigationContainer>
    );
  }
}



function returnDesktopView(){


  const linking = {
    config: {
      screens:{
        MyBookings: "my_bookings",
        BookingDetail: "booking_detail",
        DesktopHome: "",
        SearchResult: "search_result",
        FlightDetails: "trip_details",
        Seats: "choose_seats",
        PersonalInfo: "contact_info",
        MakePayment: "make_payment",
        PaymentGateway: "payment_gateway",    
        PaymentSuccess: "payment_success",    
        PaymentFailed: "payment_failed",
        PaymentPending: "payment_pending", 
        MyProfile: "my_profile",
        ResetPassword: "change_password",    
        Term: "terms_and_conditions",
        Policy: "ticketing_policy",
        PrivacyPolicy: "privacy_policy",    
        ContactUs: "contact_us",
        Faq: "faq",  
        Insurance: "insurance",
        Verify:"payment/verify",
      }
    },
    };

    return (
      <NavigationContainer linking={linking}>
          <DesktopHomeStack.Navigator mode="modal"
            screenOptions={{
              headerShown: false,
            }}>

              <DesktopHomeStack.Screen name="DesktopHome" component={DesktopHome} 
                options={{ title: LocalizedString.home  }}
              />     
              {/* <DesktopHomeStack.Group screenOptions={{ presentation: 'card' }}> */}
              {/* <DesktopHomeStack.Screen name="Main" component={LoginStackScreen} /> */}
              <DesktopHomeStack.Screen name="SearchResult" component={SearchResult} 
                  options={{ title: LocalizedString.searchResults  }}
              />
               <DesktopHomeStack.Screen name="MyBookings" component={MyBookings}                   
                  options={{ title: LocalizedString.myBookings  }} />
                <DesktopHomeStack.Screen name="BookingDetail" component={BookingDetail}                   
                  options={{ title: LocalizedString.bookingDetail  }} />
                 <DesktopHomeStack.Screen name="FlightDetails" component={FlightDetails} 
                   options={{ title: LocalizedString.tripDetails }}
                 />   
                 <DesktopHomeStack.Screen name="Seats" component={Seats} 
                    options={{ title: LocalizedString.chooseSeats  }}
                 />  
                 <DesktopHomeStack.Screen name="PersonalInfo" component={PersonalInfo} 
                    options={{ title: LocalizedString.contactInfo  }}
                 />  
                 <DesktopHomeStack.Screen name="MakePayment" component={MakePayment} 
                    options={{ title: LocalizedString.enterPaymentDetails  }}
                 />
                 <DesktopHomeStack.Screen name="Verify" component={Verify} 
                    options={{ title: LocalizedString.enterPaymentDetails  }}
                 />
                    <DesktopHomeStack.Screen name="PaymentSuccess" component={PaymentSuccess} 
                    options={{ title: LocalizedString.paymentSuccess  }}
                 />
                 <DesktopHomeStack.Screen name="PaymentFailed" component={PaymentFailed} 
                    options={{ title: LocalizedString.paymentFailedTitle  }}
                 />
                 <DesktopHomeStack.Screen name="PaymentPending" component={PaymentPending} 
                    options={{ title: LocalizedString.paymentPendingTitle  }}
                 />
                 <DesktopHomeStack.Screen name="MyProfile" component={MyProfile} 
                    options={{ title: LocalizedString.myProfile  }}
                 />
                 <DesktopHomeStack.Screen name="ResetPassword" component={ResetPassword} 
                    options={{ title: LocalizedString.changePassword  }}
                 />

                  <DesktopHomeStack.Screen name="Term" component={Term} 
                      options={{ title: LocalizedString.onlineTermsAndConditions  }}
                  />
                  <DesktopHomeStack.Screen name="Policy" component={Policy} 
                      options={{ title: LocalizedString.generalTicketingPolicy  }}
                  />
                  <DesktopHomeStack.Screen name="PrivacyPolicy" component={PrivacyPolicy} 
                      options={{ title: LocalizedString.privacyPolicy  }}
                  />
                     <DesktopHomeStack.Screen name="ContactUs" component={ContactUs} 
                      options={{ title: LocalizedString.contactUs  }}
                  />
                     <DesktopHomeStack.Screen name="Faq" component={Faq} 
                      options={{ title: LocalizedString.faq  }}
                  />
                   <DesktopHomeStack.Screen name="Insurance" component={Insurance} 
                      options={{ title: LocalizedString.insurance  }}
                  />
              {/* </DesktopHomeStack.Group>      */}
            </DesktopHomeStack.Navigator>
      </NavigationContainer>
    );
}

export default function MainNavigator() {
  // Other logic here

  const dispatch = useDispatch(); // Declare dispatch
  const isDesktopWeb = useSelector((state) => state.member.isDesktopWeb);
  const authToken = useSelector((state) => state.member.authToken);
  const isLoading = useSelector((state) => state.member.isLoading);
  const isSkipLogin = useSelector((state) => state.member.skipLogin);
  const isSkipLoading = useSelector((state) => state.member.isSkipLoading);
  const [_, forceUpdate] = useState();
  const memberId = useSelector((state) => state.member.memberId);
  const language = useSelector((state) => state.config.language);
 
  const {isDesktop} = useDeviceType();

  const languageSelected = (language) => {
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render  
  };
  
  useEffect(() => {
    dispatch(createAction('config/loadLanguageFromCache')({}));      
    dispatch(createAction('member/loadUserFromCache')({}));    
    //dispatch(createAction('member/loadSkipLoginFromCache')({})); 
    dispatch(createAction('member/setIsDesktopWeb')(isDesktop));
    dispatch(createAction('member/setSkipLoadingCompleted')({}));
    
    //console.log('isDesktop', isDesktop);
  }, [dispatch]);
  
  if (isLoading ) {
    return <Splash />;
  }

  if (Platform.OS === 'web') {
    if (isDesktopWeb === null) {
      return <Splash />;
    }
    if (isDesktopWeb=== true) {
      return returnDesktopView();    
    }else{
      return returnMobileWebview(authToken, isSkipLogin,isLoading,isSkipLoading,memberId,language,languageSelected);  
    }
  }else{
    return returnMobileView(authToken, isSkipLogin,isLoading,isSkipLoading);
  }
}



const styles = StyleSheet.create({
  iconLayout: {
    width: 24,
    height: 24,
  },
  focused:{
    color: Color.white,
  },
  unfocused:{
    color: "#FAD3CA",
  },
  menuIcon: {
    alignItems: "center",
    alignSelf: "center",
    fontSize: 24,
  },
  menuIconButton: {
    width: 24,
    height: 24,
  },
  menuhome: {
    alignItems: "center",
  },
  menubooking: {
    opacity: 0.65,
    alignItems: "center",
  }, 
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between', // space the items on both ends
    width: calculatedWidth, // occupy full width    
  },
  logoContainer: {    
    alignItems: 'center', // aligns children at the center
    justifyContent: 'center', // centers children  
  },
  headerLogo:{
    width: 200, 
    height: 50,
    alignSelf: 'center',
  },
  headerTitle:{
    alignSelf: 'center',
    flex:1,
  },
  spacer: {
    flex: 1,
  },
});
