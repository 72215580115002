import React, { useState } from 'react';
import { Text, StyleSheet, View, Image, TouchableOpacity, TextInput, KeyboardAvoidingView ,ScrollView, Platform} from "react-native";
import { Border, FontSize, FontFamily, Color, Padding, LocalizedString, CommonStyle } from "@common";


const AccountForm = ({
  onSignUpPressed,
  onTermsAndConditionPressed,
  name,
  setName,
  email,
  setEmail,
  password,
  setPassword,
  phoneNo,
  setPhoneNo, // Assuming you want to use this in the future
  passwordConfirmation,
  setPasswordConfirmation, // Assuming you want to use this in the future
  signInButtonPressed
}) => { 
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordConfirmation, setHidePasswordConfirmation] = useState(true);

  return (
    
    <View style={[styles.formCreateAccount, styles.btnSignUpFlexBox]}>
      <View style={styles.title}>
        <Text style={[styles.login, styles.loginFlexBox]}>
          {LocalizedString.createAccount}
        </Text>
        <Text style={[styles.subtitle, styles.loginFlexBox]}>
          Enter your new password twice below to reset a new password
        </Text>
      </View>
      <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.name}
      </Text>
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={name}
        placeholderTextColor={Color.lightslategray}
        onChangeText={(text) => setName(text)}
        placeholder="Enter your name"
      />
      </View>
      <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.emailAddress}
      </Text>   
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        value={email}
        onChangeText={(text) => setEmail(text)}
        placeholder="hello@example.com"
        placeholderTextColor={Color.lightslategray}
        keyboardType="email-address"
        autoCapitalize="none"
      />
    </View>
    <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.phoneNo}
      </Text>   
      <View style={[styles.inputName, styles.inputNameLayout]}>
      <TextInput
        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
        placeholderTextColor={Color.lightslategray}
        value={phoneNo}
        onChangeText={(text) => setPhoneNo(text)}        
        keyboardType="phone-pad"
        autoCapitalize="none"
      />
    </View>
      <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.password}
      </Text>
         <View style={[styles.inputName, styles.inputNameLayout]}>
        <TextInput
          style={[styles.text,styles.textLayout1, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
          placeholderTextColor={Color.lightslategray}
          value={password}
          onChangeText={(text) => setPassword(text)}
          placeholder="Enter your password"
          secureTextEntry={hidePassword}
        />
        <TouchableOpacity onPress={() => setHidePassword(!hidePassword)}>
          <Image
            style={[styles.iconeye, styles.iconeyeLayout]}
            resizeMode="cover"
            source={hidePassword ? require("../assets/iconeye1.png") : require("../assets/iconeyeoff1.png")}
          />
        </TouchableOpacity>
    </View>
    <Text
        style={[styles.textName, styles.textNameSpaceBlock]}
        numberOfLines={1}
      >
        {LocalizedString.passwordConfirmation}
      </Text>
         <View style={[styles.inputName, styles.inputNameLayout]}>
        <TextInput
          style={[styles.text,styles.textLayout1, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
          placeholderTextColor={Color.lightslategray}
          value={passwordConfirmation}
          onChangeText={(text) => setPasswordConfirmation(text)}
          placeholder="Enter your password"
          secureTextEntry={hidePasswordConfirmation}
        />
        <TouchableOpacity onPress={() => setHidePasswordConfirmation(!hidePasswordConfirmation)}>
          <Image
            style={[styles.iconeye, styles.iconeyeLayout]}
            resizeMode="cover"
            source={hidePasswordConfirmation ? require("../assets/iconeye1.png") : require("../assets/iconeyeoff1.png")}
          />
        </TouchableOpacity>
    </View>
      <Text
        style={[styles.byContinuingYouContainer, styles.textNameSpaceBlock]}
      >
        <Text
          style={styles.byContinuingYou}
        >{ LocalizedString.byAgreeing }</Text>
        <TouchableOpacity onPress={onTermsAndConditionPressed}>
          <Text style={styles.termsOfService}>{LocalizedString.termsOfService}</Text>
        </TouchableOpacity>
      </Text>
 
      <TouchableOpacity style={[styles.btnSignUp, styles.inputNameLayout]} onPress={onSignUpPressed}>
        <Text style={[styles.label, styles.labelTypo]}>{LocalizedString.signUp}</Text>
      </TouchableOpacity>

      { signInButtonPressed !== undefined ?
          <TouchableOpacity onPress={signInButtonPressed}>
           <Text style={[styles.btnSignIn]} numberOfLines={1}>
             {LocalizedString.login}
           </Text>
         </TouchableOpacity>
        : undefined
      }
    </View>

  );
};

const styles = StyleSheet.create({
  btnSignUpFlexBox: {
    alignItems: "center",
    width: 312,
  },
  loginFlexBox: {
    alignSelf: "stretch",
    textAlign: "left",
  },
  textNameSpaceBlock: {
    marginTop: 16,
    textAlign: "left",
    width: 312,
  },
  inputNameLayout: {
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
    marginTop: 16,
  },
  btnSignIn: {
    marginTop: 24,
    color: Color.steelblue_200,
    textAlign: "center",
    height: 24,
    fontSize: FontSize.size_base,
    width: 312,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    lineHeight: 24,
  },
  textLayout: {
    width: 280,
    lineHeight: 22,
    height: 24,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
    textLayout1: {
    width: 280-48,
    lineHeight: 22,
    height: 24,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
  iconeyeLayout: {
    overflow: "hidden",
    height: 24,
  },
  iconeye: {
    width: 24,
    marginLeft: 24,
    height: 24,
  },
  labelTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  login: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
    color: Color.black,
    textAlign: "left",
  },
  subtitle: {
    fontSize: FontSize.size_lg,
    lineHeight: 26,
    color: Color.gray555,
    display: "none",
    marginTop: 12,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
  title: {
    width: 312,
  },
  textName: {
    color: Color.black1,
    height: 24,
    overflow: "hidden",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
    lineHeight: 24,
  },
  text: {
    color: Color.black1,
  },
  text1: {
    color: Color.lightslategray,
  },
  inputName: {
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
  }, 
  text2: {
    flex: 1, // Takes up all available space except for the eye icon
    lineHeight: 22,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    color: Color.gray999, // Ensure that the text color is visible
  },
 
  byContinuingYou: {
    fontWeight: "300",
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
    color: Color.gray02,
  },
  termsOfService: {
    color: Color.steelblue_200,
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base,
  },
  byContinuingYouContainer: {
    fontSize: FontSize.size_smi,
    lineHeight: 20,
  },
  label: {
    lineHeight: 16,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  btnSignUp: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    justifyContent: "center",
    alignItems: "center",
    width: 312,
  },
  formCreateAccount: {
    marginTop: 20,
  },
});

export default AccountForm;