
import React, { useState } from "react";
import { Image, StyleSheet, View, Pressable, Text } from "react-native";
import TicketDetailsSection from "../components/TicketDetailsSection";
import { Color, Padding, FontSize, FontFamily, Border, LocalizedString } from "@common";
import { useSelector,useDispatch } from "react-redux";
import { ScrollView } from "react-native-gesture-handler";
import { createAction } from "@utils";
import { Dialog } from "@rneui/themed";
import TicketSummary from "../components/TicketSummary";

const FlightSummary = ({navigation}) => {

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const selectedTrip = useSelector((state) => state.trip.selectedTrip);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const seats = useSelector((state) => state.trip.selectedSeats);
  const passengerId = useSelector((state) => state.trip.passengerId);
  const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
  const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
  const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
  const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);
  const seatResult = useSelector((state) => state.trip.seatResult);
  const contactNo = useSelector((state) => state.trip.contactNo);
  const contactId = useSelector((state) => state.trip.contactId);
  const includeInsurance = useSelector((state) => state.trip.includeInsurance);
  const memberId = useSelector((state) => state.member.memberId);
  
  
  let {fare} =  seatResult
  let adultTotal = adultTicketQuantity*fare.adult
  let childTotal = childTicketQuantity*fare.child
  let seniorTotal = seniorTicketQuantity*fare.senior
  let okuTotal = okuTicketQuantity*fare.disabled
  let subTotal = adultTotal+childTotal+seniorTotal+okuTotal
  let totalTickets = adultTicketQuantity+childTicketQuantity+seniorTicketQuantity+okuTicketQuantity
  let fareFacility = fare.facility * totalTickets
  let fareOther = fare.other * totalTickets
  let fareInsurance = includeInsurance ? fare.insurance * totalTickets : 0
  let grandTotal = subTotal+fareFacility+fareOther+fareInsurance
  let seatString = seats.map((seat) => seat).join(",");

  const ticketTypes = [
    { type: 'Adult', quantity: adultTicketQuantity, fare: fare.adult, total: adultTotal },
    { type: 'Child', quantity: childTicketQuantity, fare: fare.child, total: childTotal },
    { type: 'Senior', quantity: seniorTicketQuantity, fare: fare.senior, total: seniorTotal },
    { type: 'Oku', quantity: okuTicketQuantity, fare: fare.disabled, total: okuTotal },
  ];

  const confirmSeatPressed = () => {

    
    let callback = (eventObject) => {
      setLoading(false);

      if(eventObject.success){
        // navigation.navigate("Payment")
        navigation.navigate("PaymentSuccess")
      }else{
        alert(eventObject.message)
      }
    }

    dispatch(createAction('trip/loadConfirmSeat')({memberId,date:selectedDate,passengerId,totalPayableAmount:grandTotal,
      insurance:includeInsurance,adult:adultTicketQuantity,
      child:childTicketQuantity,senior:seniorTicketQuantity,
      seat:seats,trip:selectedTrip.code,
      oku:okuTicketQuantity,contactId,contactNo,isBooking:'N',callback}))

      setLoading(true);
    // memberId,date,trip,seat
    // ,passengerId,totalPayableAmount,
    // insurance,adult,child,senior,oku
    // ,contactId,contactNo,isBooking, callback
  }

  const toggleCheckbox = () => {
    //console.log('toggleCheckbox')
    dispatch(createAction('trip/setIncludeInsurance')(!includeInsurance))
  };
  
  return (
    
    <View style={[styles.paymentSummary, styles.topBarBg]}>    
      <ScrollView style={styles.topBarBg1}>
      <View style={styles.detail}>
        <TicketDetailsSection selectedTrip={selectedTrip}
          toSelectedStation={toSelectedStation}
          fromSelectedStation={fromSelectedStation}
          seatString={seatString}
          selectedDate={selectedDate}
          showContactInfo={true}
          contactNo={contactNo}
          contactId={contactId}
          includeInsurance={includeInsurance}
          toggleCheckbox={toggleCheckbox}
          insuranceFee={fare.insurance}
        />
       
        <View style={[styles.yourTicketsParent, styles.buttonSpaceBlock]}>
          <Text style={[styles.yourTickets, styles.priceRmLayout]}>
            Your Ticket(s):
          </Text>
          <Text style={[styles.priceRm, styles.priceRmLayout]}>Price ({fare.currency})</Text>
        </View>
        <View style={styles.searchResult}>
          <View style={styles.result01}>
          {ticketTypes.map((ticket, index) => (
          <TicketSummary key={index} {...ticket} fare={fare} />
        ))}
          
            <View style={[styles.result01Item, styles.result01Layout]} />
              <View style={styles.subtotal}>
                <Text style={styles.text2Typo}>{`${LocalizedString.subtotal}
  ${LocalizedString.facilityFee}
  ${LocalizedString.otherFee}
  ${LocalizedString.insuranceFee}`}</Text>
                <Text style={[styles.text2, styles.text2Typo]}>{`${subTotal.toFixed(2)}
  ${fareFacility.toFixed(2)}
  ${fareOther.toFixed(2)}
  ${fareInsurance.toFixed(2)}`}</Text>
              </View>
              <View style={[styles.result01Item, styles.result01Layout]} />
              <View style={styles.subtotal}>
                <Text style={[styles.grandTotal, styles.textTypo]}>
                  {LocalizedString.grandTotal}
                </Text>
                <Text style={[styles.text3, styles.textTypo]}>{grandTotal.toFixed(2)}</Text>
              </View>
          </View>
        </View>
      
      </View>
      </ScrollView>
      <View style={styles.buttonSpaceBlock}>
          <Pressable
            style={[styles.buttonSmall, styles.buttonFlexBox]}
            onPress={() => navigation.navigate("SearchResult")}
          >
            <Text style={[styles.confirm, styles.confirmTypo]}>{LocalizedString.cancel}</Text>
          </Pressable>
          <Pressable
            style={[styles.buttonSmall1, styles.buttonFlexBox]}
            onPress={confirmSeatPressed}
          >
            <Text style={[styles.confirm1, styles.confirmTypo]}>{LocalizedString.pay}</Text>
          </Pressable>
        </View>
        <Dialog isVisible={isLoading} >
          <Dialog.Loading />
        </Dialog>
    </View>
    
  );
};

const styles = StyleSheet.create({
  topBarBg: {
    backgroundColor: Color.gray_100,
    alignItems: "center",
    overflow: "hidden",
  },
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  buttonSpaceBlock: {
    marginTop: 9,
    flexDirection: "row",
  },
  priceRmLayout: {
    width: 126,
    lineHeight: 24,
    fontSize: FontSize.size_mini,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    height: 25,
    color: Color.black,
  }, 
  textTypo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  result01Layout: {
    opacity: 0.3,
    height: 1,
    width: 329,
    borderTopWidth: 1,
    marginTop: 16,
    borderStyle: "solid",
  },
  text2Typo: {
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_sm,
    textAlign: "right",
    color: Color.black,
  },
  buttonFlexBox: {
    paddingHorizontal: Padding.p_85xl,
    width: 156,
    borderRadius: Border.br_5xs,
    paddingVertical: Padding.p_base,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  confirmTypo: {
    width: 88,
    fontSize: FontSize.size_lg,
    lineHeight: 24,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  icon: {
    width: 31,
    height: 9,
  },
  wifiIcon: {
    overflow: "hidden",
  },
  frameChild: {
    marginLeft: 3.07,
  },
  batteryIcon: {
    width: 19,
    height: 19,
    marginLeft: 3.07,
    overflow: "hidden",
  },
  androidStatusBars1: {
    flexDirection: "row",
  },
  androidStatusBars2: {
    width: 360,
    justifyContent: "space-between",
    paddingHorizontal: Padding.p_base,
    height: 24,
    paddingVertical: 0,
  },
  androidStatusBars: {
    paddingTop: Padding.p_5xs,
  },
  bookFlight: {
    lineHeight: 32,
    width: 192,
    textAlign: "center",
    color: Color.black,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_xl,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    flexDirection: "row",
    alignItems: "center",
  },
  topBar: {
    alignItems: "center",
    overflow: "hidden",
  },
  yourTickets: {
    textAlign: "left",
  },
  priceRm: {
    marginLeft: 64,
    textAlign: "right",
  },
  yourTicketsParent: {
    width: 316,
    height: 25,
    marginTop: 9,
    alignItems: "center",
  },
  x: {
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    fontSize: FontSize.size_sm,
  },
  quantity: {
    paddingHorizontal: 12,
  },
  adult: {
    textAlign: "left",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_sm,
  },
  text: {
    marginLeft: 14,
    fontSize: FontSize.size_sm,
    color: Color.black,
    textAlign: "right",
  },
  quantity1: {
    paddingHorizontal: 10,
  },
  quantityGroup: {
    marginTop: 16,
  },
  result01Item: {
    borderColor: "#555",
    marginTop: 16,
  },
  text2: {
    marginLeft: 16,
  },
  subtotal: {
    width: 303,
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  grandTotal: {
    textAlign: "right",
    color: Color.black,
    fontSize: FontSize.size_xl,
    fontWeight: "600",
  },
  text3: {
    marginLeft: 16,
    textAlign: "right",
    color: Color.black,
    fontSize: FontSize.size_xl,
    fontWeight: "600",
  },
  result01: {
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingHorizontal: 0,    
    paddingBottom: Padding.p_5xl,    
    alignItems: "center",
  },
  searchResult: {
    
    marginTop: 9,
  },
  confirm: {
    color: Color.secondary,
  },
  buttonSmall: {
    borderColor: "#ec441e",
    borderWidth: 1,
    borderStyle: "solid",
    paddingHorizontal: Padding.p_85xl,
    width: 156,
    borderRadius: Border.br_5xs,
  },
  confirm1: {
    color: Color.white,
  },
  buttonSmall1: {
    backgroundColor: Color.steelblue_200,
    marginLeft: 16,
  },
  detail: {
    marginTop: -4,
    justifyContent: "center",
    alignItems: "center",
  },
  paymentSummary: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    overflow: "hidden",
  },
});

export default FlightSummary;
