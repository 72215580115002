import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View, Text, TouchableOpacity, ImageBackground, ScrollView, Platform } from "react-native";
import WebView from 'react-native-webview';
import { FontFamily, Padding, Color, FontSize, Border,LocalizedString, CommonStyle } from "@common";
import { useDispatch, useSelector } from "react-redux";
import {Desktop, createAction} from '@utils';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopFooter from '../components/DesktopFooter';
import HtmlComponent from '../components/HtmlComponent';
import DesktopWrapper from '../components/DesktopWrapper';
import { Accordion } from 'native-base';


const Faq = ({navigation}) => {
  
const [_, forceUpdate] = useState();
const [firstTabSelected, setFirstTabSelected] = useState(true);
 const memberId = useSelector((state) => state.member.memberId);
  const language = useSelector((state) => state.config.language);
  const authToken = useSelector((state) => state.member.authToken);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
};

  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(createAction('trip/loadGetRoute')({}));
  }, [dispatch]);

  const buildEnglishFaq = () => {
    return (
      <View style={styles.faqContainer}>
      <View style={isDesktop ? styles.buttonContainer : styles.buttonContainerMobile}>
        <TouchableOpacity style={firstTabSelected ? styles.selectedButton : styles.button} onPress={() => setFirstTabSelected(true) }>
          <Text style={firstTabSelected ? styles.selectedButtonText : styles.buttonText}>Before Purchasing Bus Ticket</Text>
        </TouchableOpacity>
        <TouchableOpacity style={ !firstTabSelected ? styles.selectedButton : styles.button} onPress={() =>  setFirstTabSelected(false) }>
          <Text style={!firstTabSelected ? styles.selectedButtonText : styles.buttonText}>During Purchasing Bus Ticket</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.accordionView}>
        { firstTabSelected
        ? <Accordion tyle={styles.accordion} index={[0]}>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#1 Where can I purchase a bus ticket?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>You can purchase either through online via the Terminal Kampar Putra Sentral Website or alternatively you may proceed to our CTS counter at the Terminal Kampar Putra Sentral, Kampar. 
           
           We have 8 staffs counter and 1 self service counter available for ease of passenger ticket purchase. The mode of payment can be either cash or cashless via credit/debit card, e-wallet or even with 'Touch & Go' card.</Text></Accordion.Details>          
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#2 What are the benefits if the ticket purchased thru the Terminal Website compared to other channel of sales?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Ticket purchased thru terminal website shall have the benefits of express boarding without hesitation to queue at the terminal to acquire boarding pass. Just scan the e-ticket QR code that has been send to your mobile at the pre boarding gate.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#3 How many bus tickets can I purchase?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Purchased through website shall be limited to a maximum of 4 tickets for every single transaction. However, there shall be no limitation for ticket purchased at the Terminal CTS counter.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#4 Who can purchase a bus ticket?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Anyone can purchase bus tickets with valid travelling information and subject to availability of trips.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#5 When can I purchase a bus ticket?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Tickets can be purchased 24 hour, 365 days at the Terminal self service counter or thru the website. However, the CTS counter opens daily from 7am - 12 midnight.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#6 What official documents do I should bring along to purchase a bus ticket?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Passengers are required to show their Malaysian MyKad or passport to purchase bus tickets. Passengers are required to provide their IDs during ticket purchase to ease the process of providing proof for insurance claims and personal claims.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#7 Where I can view available trips and destination offered by CTS?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>All active destinations under Centralized Ticketing System (CTS) are displayed in our homepage. Please click on the destination icons for active destinations to South, North, East Malaysia, South Thailand, and Singapore. 
           
           Trips and routes to all destinations offered by bus operators vary from day to day. Please view all available routes for today's date at our website under Bus Schedules. Our website is updated on the day-to-day basis. 
           
           For better access to daily trips, do not hesitate to proceed to any of our CTS Counters 1- 8 at the terminal.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#8 Where can I check for seat availability?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Please proceed to our CTS Counters at the Terminal for seat availability. If you are making a credit card or bank account transaction, please proceed to our e-ticketing portal OR to the self service kiosk at the terminal to find, and book your seats. Available seat will update based on current time.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#9 Who do I contact for more information?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>You may contact our Customer Service +6012-4099102 (8:00AM - 1:00AM) or the terminal trip administrator at +6012-3765867 (24 hours) or leave your comments and questions to customercare@kamparputrasentral.my. If you are in the vicinity, please proceed to our Info Counter at Terminal Kampar Putra Sentral. 
           
           Click here for more info.</Text></Accordion.Details>
        </Accordion.Item>
      </Accordion>

      :
      <Accordion tyle={styles.accordion} index={[0]}>
      <Accordion.Item style={styles.accordionItem}>
        <Accordion.Summary><Text style={styles.accordionSummary}>#1 What discounts or special rates are available under CTS bus operators?</Text></Accordion.Summary>
         <Accordion.Details><Text style={styles.accordionDetails}>Only Malaysian nationals with Malaysian MyKad are allowed to redeem special fares like Senior Citizen and Disabled (OKU). Permanent Residents status and foreigner are not eligible for Senior Citizen and Disabled (OKU) rates. Please note that NOT ALL the bus operators offer Senior Citizen or Disabled (OKU) rates. 
         
         Children fares are only available selected bus operators. Please note that Children fares and age requirements vary from one bus operator to another. NOT ALL bus operators offer Children fare.</Text></Accordion.Details>
      </Accordion.Item>
      <Accordion.Item style={styles.accordionItem}>
        <Accordion.Summary><Text style={styles.accordionSummary}>#2 What information do I need to give during ticket purchase?</Text></Accordion.Summary>
         <Accordion.Details><Text style={styles.accordionDetails}>1) Full name of passenger 
         2) Contact number of passengers 
         
         Passengers are required to provide their MyKad or passport details to purchase bus tickets at the Terminal CTS Counters or during online purchase at our website. 
         
         Please note that passengers are required to provide their IDs to our ticketing personnel during ticket purchase at ticketing counter or to collect bus tickets after online purchase to ease the process of providing proof for insurance claims and personal claims.</Text></Accordion.Details>
      </Accordion.Item>
      <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#3 What is the maximum transaction time limit for ticket purchase at CTS Counter before departure time?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Passengers are allowed to purchase any available bus tickets at least 8 minutes before departure time through CTS Counters. Please proceed to the boarding gate immediately after purchasing your ticket Bus Terminal is not responsible for missed bus or loss of tickets. Through our -ticketing portal, passengers are allowed to purchase tickets minimum 3 HOURS before departure time. 
       
       Through our portal, passengers are allowed to check in at boarding gate, minimum 3 HOURS before departure time.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#3 What is the maximum transaction time limit for ticket purchase at CTS Counter before departure time?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}></Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#4 What is the earliest date for bus ticket purchase at the Terminal CTS Counter?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Passengers are allowed to purchase bus tickets at least 1-3 months before departure date for select bus operators and trips. Please check all available dates at CTS Counters 1-8, terminal self service kiosk or through our e-ticketing portal.

Please check your departure platform on the actual departure date. Change of gates does occur often.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#5 How much time should the passenger arrive at the Terminal before departure?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>All ticket holders should have reached the terminal at least 30 minutes before departure time and straight away check in at the pre boarding hall.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#6 Can the ticket to be purchased thru the ticket agent or other sales portal channel beside the terminal website and CTS counter?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Yes. However, the passenger will have to exchange the ticket/s to boarding pass before entering the pre-boarding hall.

The boarding pass exchange can be done at terminal CTS counter no.2 & or via terminal self-service kiosk. 
</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#7 Do all CTS Counters offer the same information, trips, and schedules?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>YES. AlI CTS Counters offer the same information, trips, and schedules. Passengers do not need to line up again at a different counter to ask for the same information.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#8 Do bus operator offer separate seats for Senior Citizens or Disabled (OKU) passengers?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>NO. Most bus operators under CTS do not offer separate seats for Senior Citizens or Disabled (OKU) passengers. However, select bus operators do provide a reduced-price rate for Malaysian Nationals with Malaysian ID for Senior Citizens or Disabled (OKU) passengers.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#9 Can I book my seat and pay for my tickets later?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>NO. Passengers are asked to pay for their bus tickets immediately after the seats are confirmed.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#10 Should the child required a seat?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Any child who is 2-year old and above has to buy his her own seat ticket.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#11 Are the ticket prices at the counters and online ticketing the same?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>YES. The ticket prices for all bus operators under CTS are the same on all platforms.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#12 Do bus operator offer additional unscheduled trips during sold-out peak season?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>On a normal occasion during peak season YES. But subject to availability as it depends on the bus operator's will.</Text></Accordion.Details>
    </Accordion.Item>
    </Accordion>
  
        }
         
     
        </View>
      </View>
    )
  }

  const buildMalayFaq = () => {
    return (
      <View style={styles.faqContainer}>
      <View style={isDesktop ? styles.buttonContainer : styles.buttonContainerMobile}>
        <TouchableOpacity style={firstTabSelected ? styles.selectedButton : styles.button} onPress={() => setFirstTabSelected(true) }>
          <Text style={firstTabSelected ? styles.selectedButtonText : styles.buttonText}>Sebelum Pembelian Tiket Bas</Text>
        </TouchableOpacity>
        <TouchableOpacity style={ !firstTabSelected ? styles.selectedButton : styles.button} onPress={() =>  setFirstTabSelected(false) }>
          <Text style={!firstTabSelected ? styles.selectedButtonText : styles.buttonText}>Ketika Pembelian Tiket Bas</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.accordionView}>
        { firstTabSelected
        ? <Accordion tyle={styles.accordion} index={[0]}>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#1 Di mana boleh membeli tiket bas?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Anda boleh membuat pembelian sama ada atas talian (online) laman sesawang Terminal Kampar Putra Sentral ataupun terus ke kaunter CTS kami di Kampar Putra Sentral, Kampar. 
           Kami terdiri daripada 8 petugas kaunter dan 1 kaunter layan diri disediakan untuk kemudahan pembelian tiket. Cara pembayaran boleh dilakukan antaranya secara tunai (cash) ataupun bebas tunai (cashless) melalui kredit/debit kad, e-dompet mahupun kad ‘Touch & Go’</Text></Accordion.Details>          
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#2 Apakah kelebihan jika pembelian tiket dibuat melalui laman sesawang terminal berbanding saluran pembelian yang lain?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Pembelian tiket melalui laman sesawang terminal akan mendapat kelebihan perlepasan segera tanpa kerisauan untuk beratur di terminal untuk mendapatkan pas perlepasan. Cuma imbas QR code di e-tiket yang telah dihantar ke telefon mudah alih anda di pintu perlepasan.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#3 Berapakah tiket bas yang boleh dibeli?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Pembelian melalui laman sesawang dihadkan maksimum sebanyak 4 tiket setiap transaksi. Namun, tiada had dikenakan untuk pembelian tiket di kaunter tiket terminal CTS.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#4 Siapakah yang boleh membeli tiket bas?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Sesiapa sahaja boleh membeli tiket bas dengan maklumat perjalanan yang sah dan tertakluk pada laluan yang tersedia.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#5 Bilakah tiket bas boleh dibeli?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Tiket boleh dibeli 24 jam, 356 hari di kaunter layan diri Terminal ataupun melalui laman sesawang. Namun, CTS kaunter harian beroperasi dari 7 pagi - 12 tengah malam.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#6 Dokumen rasmi apakah yang harus dibawa ketika pembelian tiket bas?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Penumpang harus menyediakan MyKad ataupun pasport untuk pembelian tiket bas. Penumpang perlu menyediakan IC ketika pembelian tiket bagi memudahkan proses penyediaan bukti untuk tuntutan insuran dan peribadi.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#7 Di manakah laluan dan destinasi yang tersedia oleh CTS dapat dilihat?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Semua destinasi aktif dibawah Sistem Tiket Berpusat (CTS) dipaparkan di laman utama kami. Sila tekan pada ikon destinasi untuk destinasi aktif ke Selatan, Utara, Timur Malaysia, Selatan Thailand dan Singapura. 
           
           Perjalanan dan laluan ke pelbagai destinasi yang ditawarkan oleh pengendali bas berbeza dari hari ke hari. Sila lihat kesemua perjalanan yang tersedia unthuk hari dan tarikh di laman sesawang kami dibawah Jadual Bas. Laman Sesawang kami dikemas kini hari ke hari. 
           
           Untuk akses yang lebih baik kepada perjalanan harian, jangan khuatir untuk terus ke mana-mana kaunter CTS kami.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#8 Di manakah ketersediaan tempat duduk dapat disemak?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Sila terus ke kaunter CTS kami di terminal untuk ketersediaan tempat duduk. Jika anda melakukan transaksi menggunakan kad kredit atau akaun bank, sila terus ke kiosk layan diri di terminal untuk mencari, dan tempah tempat duduk anda. Ketersediaan tempat duduk akan dikemas kini mengikut masa semasa.</Text></Accordion.Details>
        </Accordion.Item>
        <Accordion.Item style={styles.accordionItem}>
          <Accordion.Summary><Text style={styles.accordionSummary}>#9 Siapa harus dihubungi untuk maklumat lanjut?</Text></Accordion.Summary>
           <Accordion.Details><Text style={styles.accordionDetails}>Anda boleh hubungi Khidmat Pelanggan kami di +6012-4293530 (8:00pagi-1.00pagi) ataupun pentadbiran perjalanan terminal di +6012-3765867 (24jam) atau tinggalkan komen dan pertanyaan anda di customercare@kamparputrasentral.my. Jika anda berdekatan, sila terus ke kaunter informasi di Terminal Kampar Putra Sentral.
           
           Tekan disini untuk maklumat lanjut.</Text></Accordion.Details>
        </Accordion.Item>
      </Accordion>

      :
      <Accordion tyle={styles.accordion} index={[0]}>
      <Accordion.Item style={styles.accordionItem}>
        <Accordion.Summary><Text style={styles.accordionSummary}>#1 Diskaun atau kadar istimewa apakah yang tersedia dibawah pengendali bas CTS?</Text></Accordion.Summary>
         <Accordion.Details><Text style={styles.accordionDetails}>Hanya warganegara Malaysia dengan MyKad sahaja dibenarkan untuk menebus tambang istimewa Warga Emas dan Kurang Upaya (OKU). Pemastautin Tetap atau Warga Asing tidak layak untuk kadar Warga Emas dan Kurang Upaya. Dimaklumkan bahawa tidak semua pengendali bas menawarkan kadar Warga Emas dan Kurang Upaya. 
         
         Tambang Kanak-kanak hanya ditawarkan bagi pengendali bas terpilih. Dimaklumkan juga bahawa kadar ini mungkin akan berbeza mengikut pengendali bas. TIDAK semua pengendali bas menawarkan kadar tambang kanak-kanak.</Text></Accordion.Details>
      </Accordion.Item>
      <Accordion.Item style={styles.accordionItem}>
        <Accordion.Summary><Text style={styles.accordionSummary}>#2 Maklumat apakah yang perlu diberi semasa pembelian tiket?</Text></Accordion.Summary>
         <Accordion.Details><Text style={styles.accordionDetails}>1) Nama penuh penumpang 
         2) Nombor Telefon Penumpang

Penumpang diminta untuk menunjukkan maklumat MyKad ataupun pasport untuk membeli tiket bas di kaunter CTS terminal atau semasa pembelian atas talian di laman sesawang kami.

Ambil maklum bahawa penumpang diminta untuk menunjukkan IC kepada petugas kaunter tiket semasa pembelian atau mengambil tiket bas selepas pembelian atas talian bagi memudahkan proses penyediaan bukti untuk tuntutan insuran dan peribadi.
</Text></Accordion.Details>
      </Accordion.Item>
      <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#3 Berapa lamakah had jangka masa transaksi maksimum untuk pembelian tiket di kaunter CTS sebelum waktu perlepasan?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Penumpang dibenarkan untuk membeli apa jua tiket bas yang tersedia sekurang-kurangnya 8 minit sebelum masa perlepasan menerusi kaunter CTS. Sila terus ke pintu perlepasan dengan segera selepas pembelian tiket bas. Pihak terminal tidak bertanggungjawab atas ketinggalan bas atau kehilangan tiket bas.
       
       Mengikut portal tiket kami, penumpang dibenarkan mendaftar masuk ke pintu perlepasan 3 jam minimum sebelum waktu perlepasan.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#3 What is the maximum transaction time limit for ticket purchase at CTS Counter before departure time?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}></Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#4 Bilakah tarikh paling awal tiket bas boleh dibeli di kaunter CTS terminal?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Penumpang boleh membuat pembelian tiket bas sekurang-kurangnya 1-3 bulan sebelum tarikh perlepasan mengikut pilihan pengendali bas dan perjalanan. Sila semak semua tarikh yang tersedia di kaunter CTS 1-8, kiosk layan diri terminal ataupun portal e-tiket kami. 
       
       Sila semak platform perlepasan pada tarikh perlepasan. Perubahan pintu perlepasan kerap kali berlaku.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#5 Berapa lamakah masa untuk tiba di terminal sebelum masa perlepasan?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Semua pemegang tiket perlu berada di terminal sekurang-kurangnya 30 minit sebelum masa perlepasan dan terus membuat daftar masuk di balai pra-perlepasan.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#6 Bolehkah pembelian tiket dibuat melalui agen tiket atau saluran portal penjualan tiket selain dari laman sesawang terminal dan kaunter CTS?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Ya. Namun, penumpang perlu menukar tiket tersebut kepada pas perlepasan sebelum masuk ke balai pra-perlepasan. Penukaran pas perlepasan boleh dibuat di kaunter CTS terminal no 2 & 3 ataupun melalui kiosk layan diri terminal.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#7 Adakah semua kaunter CTS menawarkan maklumat perjalanan dan jadual yang sama?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>YA. Semua kaunter CTS menawarkan maklumat perjalanan dan jadual yang sama. Penumpang tidak perlu untuk beratur lagi di kaunter berlainan untuk pertanyaan maklumat yang sama.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#8 Adakah pengendali bas menawarkan tempat duduk berasingan untuk penumpang Warga Emas ataupun Kurang Upaya?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>TIDAK. Kebanyakan Pengendali bas dibawah CTS tidak menawarkan tempat duduk berasingan untuk penumpang Warga Emas mahupun Kurang Upaya. Namun, pengendali bas terpilih menyediakan potongan kadar harga tiket untuk penumpang warganegara Malaysia dengan IC Malaysia untuk Warga Emas atau Kurang Upaya.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#9 Bolehkan tempat duduk ditempah dan bayar tiket kemudian?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>TIDAK. Penumpang diminta untuk membayar tiket bas serta merta selepas tempat duduk telah disahkan.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#10 Perlukah tempat duduk untuk kanak-kanak? </Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Kanak-kanak berumur 2 tahun dan ke atas perlu membeli tiket tempat duduk mereka.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#11 Adakah harga tiket di kaunter sama seperti tiket atas talian?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>YA. Harga tiket untuk semua pengendali bas dibawah CTS adalah sama di semua platform.</Text></Accordion.Details>
    </Accordion.Item>
    <Accordion.Item style={styles.accordionItem}>
      <Accordion.Summary><Text style={styles.accordionSummary}>#12 Adakah pengendali bas menawarkan perjalan tidak berjadual tambahan semasa tiket habis terjual pada musim kemuncak?</Text></Accordion.Summary>
       <Accordion.Details><Text style={styles.accordionDetails}>Kebiasaannya YA, ketika musim kemuncak. Akan tetapi, hal ini bergantung kepada kehendak pengendali bas.</Text></Accordion.Details>
    </Accordion.Item>
    </Accordion>
  
        }
         
     
        </View>
      </View>
    )
  }


 
  return (
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={<DesktopFooter navigation={navigation} isDesktop={isDesktop}/>} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="Faq"/>}>

    <ScrollView style={styles.container}>
      <Desktop>
        <View style={styles.backgroundImage}>
          <Image source={require('../assets/landing_page.jpg')} style={styles.backgroundImage} />      
          <View style={styles.titleContainer}>
            <Text style={[CommonStyle.title,styles.banner]}>{LocalizedString.frequentlyAskedQuestions}</Text>
          </View>
        </View>
      </Desktop>
      {language === 'en' && buildEnglishFaq()}
      {language === 'ms' && buildMalayFaq()}
      </ScrollView>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Color.white,
    height: 100,
  },
  web:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },  
  backgroundImage: {
    width: "100%",
    height: 300,
    resizeMode: 'cover', // or 'stretch'
    backgroundColor: Color.black,
  },
  titleContainer: {
    backgroundColor: Color.black,
    position: "absolute",
    bottom: 0,
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
    flex: 1,
    height: 90,
    alignContent: "center",
    width: "100%",
    zIndex: 1,
  },
  banner: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    textAlign: "left",    
    color: Color.white,
  },

  home: {
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: 'Color.gray_100',
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
    minHeight: 1000,
  },
  buttonContainerMobile: {
    marginBottom: 20,
  },
  buttonContainer:{
    flexDirection: 'row',
    marginBottom: 20,
  },
  button:{
    margin: 2.5,
    padding: 10,
    borderColor:Color.lightslategray,
    borderWidth:1,
  },
  selectedButton:{
    backgroundColor:Color.steelblue_200,
    margin: 2.5,
    padding: 10,
    borderColor:Color.lightslategray,
    borderWidth:1,
  },
  buttonText:{
    fontSize: FontSize.size_lg,
    fontFamily: FontFamily.interBold,
  },
  selectedButtonText:{
    color:Color.white,
    fontSize: FontSize.size_lg,
    fontFamily: FontFamily.interBold,
  },
  accordionItem:{
    marginBottom:10,
    backgroundColor:Color.white,
  },
  accordionSummary:{
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_base
  },
  accordionDetails:{
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_base
  },
  accordionView:{
    backgroundColor:Color.lightslategray,
    marginBottom: 30,
  },
  faqContainer:{
    width: '100%',
    maxWidth: 1000,
    alignSelf: "center",
  },
  accordion:{
    borderRadius: 10,
    
  }

});

export default Faq;
