// LoginForm.js
import React from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Platform } from "react-native";
import { Button, CheckBox } from "@rneui/themed";
import { Padding, FontFamily, FontSize, Color, Border, LocalizedString, CommonStyle } from "@common";
const LoginForm = ({ email, setEmail, password, rememberMe, setPassword, setRememberMe, loginButtonPressed, forgotPasswordButtonPressed,showModalLogin,skipButtonPressed,createAccountButtonPressed,showSkipButton }) => {

  const onRememberMePressed = () => {
    setRememberMe && setRememberMe(!rememberMe);
  }

  return (
    
    <View style={styles.containerForm}>
    <View>
        <View style={styles.textLoginTitle}>
          <Text style={styles.login}>{LocalizedString.login}</Text>
          <Text style={styles.subtitle}>{LocalizedString.welcome}</Text>
        </View>
        <View style={styles.email}>
          <Text
            style={[styles.textinputtitle, styles.textinputtitleTypo]}
            numberOfLines={1}
          >
            {LocalizedString.emailAddress}
          </Text>
          <TextInput
            style={[styles.inputEmail, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
            placeholder="hello@example.com"
            placeholderTextColor={Color.lightslategray}
            minLength={10}
            required={true}
            autoFocus={true}
            autoCapitalize="none"
            onChangeText={text => setEmail(text)}
            value={email}
            inputStyle={{ color: "#928fa6" }}
            containerStyle={styles.inputEmailTextInputInput}
          />
        </View>
        <View style={styles.password}>
          <View style={styles.passwords}>
            <Text
              style={[styles.textInputTitme, styles.textinputtitleTypo]}
              numberOfLines={1}
            >
              {LocalizedString.password}
            </Text>
            <TouchableOpacity onPress={forgotPasswordButtonPressed}>
            <Text style={[styles.btnForgotPassword]}>
            {LocalizedString.forget_password}
            </Text>
            </TouchableOpacity>
          </View>
          <TextInput
            style={[styles.inputEmail, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
            required={true}
            value={password}
            onChangeText={text => setPassword(text)}
            secureTextEntry={true}
            inputStyle={{}}
            containerStyle={styles.inputPasswordTextInputInput}
          />
        </View>
        <View style={styles.email}>
          <CheckBox 
            size={24} 
            title={LocalizedString.rememeberMe}
            checked={rememberMe}
            onPress={onRememberMePressed}          
          />
        </View>
        <Button
          style={styles.password}
          title="Login"
          radius={5}
          iconPosition="left"
          type="solid"
          titleStyle={styles.btnLoginBtn}
          onPress={loginButtonPressed}
        />
        { !showModalLogin || showSkipButton ?
                  <TouchableOpacity onPress={skipButtonPressed}>
                  <Text style={[styles.btnSkip, styles.btnTypo]} numberOfLines={1}>
                    {LocalizedString.skip}
                  </Text>
                </TouchableOpacity> : undefined
        }

        
      </View>
      <TouchableOpacity onPress={createAccountButtonPressed}>
        <Text
          style={[styles.btnCreateAccount, styles.btnTypo]}
          numberOfLines={1}
        >
          {LocalizedString.createAccount}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

// Your styles
const styles = StyleSheet.create({
    inputEmailTextInputInput: {
      height: 48,
    },
    inputPasswordTextInputInput: {
      height: 48,
    },
    btnLoginBtn: {
      color: "#fff",
      fontSize: 16,
      fontWeight: "500",
      fontFamily: FontFamily.interMedium,
    },
    textinputtitleTypo: {
      height: 24,
      color: Color.black1,
      fontSize: FontSize.size_base,
      textAlign: "left",
      fontFamily: FontFamily.interMedium,
      fontWeight: "500",
      lineHeight: 24,
    },
    btnTypo: {
      textAlign: "center",
      height: 24,
      fontSize: FontSize.size_base,
      width: 312,
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
      lineHeight: 24,
    },
    login: {
      fontSize: FontSize.size_9xl,
      lineHeight: 40,
      width: 312,
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
      color: Color.black,
      textAlign: "left",
    },
    subtitle: {
      fontSize: FontSize.size_lg,
      lineHeight: 26,
      fontFamily: FontFamily.interRegular,
      marginTop: 12,
      color: Color.gray555,
      width: 312,
      textAlign: "left",
    },
    textinputtitle: {
      width: 312,
    },
    inputEmail: {
      borderRadius: Border.br_5xs,
      borderStyle: "solid",
      borderColor: "#d0d5dd",
      borderWidth: 1,
      paddingHorizontal: Padding.p_base,
      paddingVertical: Padding.p_xs,
      marginTop: 8,
      alignSelf: "stretch",
    },
    email: {
      marginTop: 24,
    },
    btnForgotPassword: {
      textAlign: "right",
      fontFamily: FontFamily.interRegular,
      fontSize: FontSize.size_base,
      width: 120,
      marginLeft: 4,
      color: Color.steelblue_200,
      alignSelf: "flex-end",
    },
    passwords: {
      flexDirection: "row", 
      justifyContent: "space-between",   
    },
    password: {
      marginTop: 24,      
    },
    btnSkip: {
      marginTop: 24,
      color: Color.gray555,
    },
    btnCreateAccount: {
      marginTop: 54,
      color: Color.steelblue_200,
    },
    containerForm: {
      paddingBottom: 99,
      marginTop: 30,
      alignSelf: "stretch",
      alignItems: "center",
    },
    emailLogin: {
      flex: 1,
      width: "100%",
      paddingHorizontal: Padding.p_5xl,
      paddingVertical: Padding.p_21xl,
    },
    scrollContainer: {
      flex: 1,
      backgroundColor: Color.white,
    }
  });
  

export default LoginForm;
