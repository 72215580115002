import React, { useState , useEffect } from 'react';
import { Image, StyleSheet, Text, View, ScrollView, Pressable, Platform, Linking } from "react-native";
import { FontFamily, FontSize, Color, Padding, Border,CommonStyle, LocalizedString, windowWidth } from "@common";
import DesktopWrapper from "../components/DesktopWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import {DateTime} from 'luxon';
import DesktopNavbar from "../components/DesktopNavbar";
import DesktopFooter from '../components/DesktopFooter';
import BookingInfoWidget from  '../components/BookingInfoWidget';
import { PDF_URL, GUEST_ID } from "@constants";

const PaymentSuccess = ({route, navigation}) => {
    const {refNo, date, totalAmount} = route.params

    const formattedDate = date || DateTime.now().toFormat('dd-MM-yyyy')

  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState('');
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const [booking, setBooking] = useState('')

  useEffect(() => {
    loadPrintTicket()
  }, []);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };

  const onPrintPdfPressed = () => {
    let url = `${PDF_URL}?memberId=${memberId || GUEST_ID}&refNo=${refNo}&date=${formattedDate}`
    Linking.canOpenURL(url).then(supported => {
      if (supported) {
        Linking.openURL(url, '_self');
      } else {
        //console.log("Don't know how to open URI: " + url);
      }
    });
  }

  const onBackToHomePressed = () => {
    if (isDesktop) {
      navigation.navigate("DesktopHome")
    }
    else {
      if (Platform.OS === 'web') {
        navigation.navigate('Home', {screen: 'MobileHome'})
      }
      else {
        navigation.navigate('MobileHome')
      }
    }
  }

const loadPrintTicket = () => {
    let callback = (eventObject) => {
      setLoading(false);
      if(eventObject.success){
        setBooking(eventObject.result)
      }else{
        alert(eventObject.message)
      }
    }
    setLoading(true);
    dispatch(createAction('member/loadPrintTicket')({callback,memberId:memberId || GUEST_ID,authToken,refNo,date:formattedDate}))

  }

let containerStyle = isDesktop ? styles.containerDesktop : styles.container;

  return (
    <DesktopWrapper isLoading={isLoading} navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="PaymentSuccess"/>}>      
 
    <View style={styles.bookingDetails}>
      <View style={styles.centralize}>
        <Text style={CommonStyle.title}>{LocalizedString.paymentSuccess}</Text>
      </View>
      <BookingInfoWidget booking={booking} date={date} totalAmount={totalAmount} />
      <View style={[isDesktop ? styles.successButtonsDesktop : styles.successButtons]}>
        <Pressable style={styles.buttonBig} onPress={onBackToHomePressed}>
          <Text style={styles.confirm}>{LocalizedString.backToHome}</Text>
        </Pressable>
        <Pressable style={styles.buttonBig} onPress={onPrintPdfPressed}>
          <Text style={styles.confirm}>{LocalizedString.printAsPdf}</Text>
        </Pressable>
      </View>
    </View>
    <Desktop>
      <View style={CommonStyle.withDesktopFooter}></View>
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    </Desktop>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  fromToSeatTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  kprKamparLarTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.black,
    textAlign: "left",
  },
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout1: {
    height: 18,
    width: 18,
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  frameChild: {
    width: 38,
    height: 38,
  },
  coach: {
    color: Color.black,
    width: 135
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
  },
  groupParent: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  fromToSeat: {
    color: Color.black,
  },
  kprKamparLar: {
    marginLeft: 21,
    lineHeight: 24,
    fontSize: FontSize.size_sm,
    fontFamily: FontFamily.interRegular,
  },
  fromToSeatContactNoIcParent: {
    
    marginTop: 11,
    flexDirection: "row",
  },
  frameGroup: {
    height: 279,
    paddingBottom: Padding.p_5xl,
    alignItems: "center",    
  },
  boardingPassesText: {
    marginTop: 15,
    textAlign:"center"
  },

  text: {
    width: 58,
  },

  frameView: {
    marginTop: 16,
    flexDirection: "row",
  },
  frameParent: {    
    alignSelf: "center",     
  },
  icon: {
    width: 31,
    height: 9,
  },
  bookFlight: {
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    textAlign: "center",
    width: 192,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    paddingHorizontal: Padding.p_base,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  frame: {
    flex: 1,
  },
  bookingDetails: {
    flex: 1,
    width: "100%",    
    backgroundColor: Color.gray_100,
    alignItems:"center",
    justifyContent:"center",
  },
  centralize:{
    width:"100%",
    alignItems:"center",
    justifyContent:"center",
  },
  containerDesktop: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",        
    marginTop: 9,
    width: 800,
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingTop: 40,
  },
  container:{
    width: windowWidth,
    paddingTop: 40,
  },
  coachInfoDiv: {
    width: '100%'
  },
  coachInfo: {
    flexDirection: 'row'
  },
  buttonBig: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 296,
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_5xs,
    marginTop: 16,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 10,
  },
  confirm: {
    fontSize: FontSize.size_lg,
    color: Color.white,
    textAlign: "center",
    width: 120,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
  },
  successButtons: {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  successButtonsDesktop: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16
  }
});

export default PaymentSuccess;
