import React, { useState } from 'react';
import { Image, StyleSheet, View, Text, TextInput, Platform } from "react-native";
import { FontFamily, FontSize, Border, Color, Padding, CommonStyle, LocalizedString } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";
import { isValidEmail } from "@utils";
import { Dialog } from "@rneui/themed";
import { createAction } from "@utils";
import {useDispatch, useSelector} from 'react-redux';
import DismissableToast from '../components/DismissableToast';
import { useToast } from 'native-base';

const ForgotPassword = ({navigation}) => {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState('');
  const isDesktopWeb = useSelector((state) => state.member.isDesktopWeb);
  const toast = useToast();

  const handleResetPassword = () => {
    if (!isValidEmail(email)) {
      // Alert or display a message that the email is not valid
      alert('Please enter a valid email address.');
      return;
    }
    // Proceed with the password reset action
    // This could involve calling an API to send a reset link to the email
    let callback = (eventObject) => {
      //console.log('eventObject',eventObject);
      if (eventObject.success) {
        setTimeout(() => {
          setLoading(false)
          let placement = isDesktopWeb ? "top" : "bottom"
          toast.show({
            placement: placement,
            duration: null,
            render: ({
              id
            }) => {
              return <DismissableToast id={id} toast={toast} description={LocalizedString.forgetPasswordSuccessful} />;
            }
          })
          navigation.popToTop();
          navigation.goBack();
        }, 500);      
      } else {
        alert(eventObject.message);
      }
      setLoading(false);
    }
  
    setLoading(true);
    dispatch(createAction('member/loadResetPassword')({"memberId": email,callback}));
  
  };  

  return (
    <View style={[styles.forgotPassword, styles.frameFlexBox]}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.backArrow} onPress={() => navigation.goBack()}>
          <Image
            style={[styles.iconleftarrow, styles.textEmailLayout]}
            resizeMode="cover"
            source={require("../assets/iconleftarrow.png")}
          />
        </TouchableOpacity>
        <View style={[styles.frame, styles.frameFlexBox]}>
          <View>
            <Text style={[styles.title, styles.titleFlexBox]}>
              Forgot Password?
            </Text>
            <Text style={styles.subtitle}>
              Enter your email address to get the password reset link.
            </Text>
          </View>
          <View style={[styles.frame1, styles.frameFlexBox]}>
            <View style={styles.containerEmail}>
              <Text
                style={[styles.textEmail, styles.labelTypo]}
                numberOfLines={1}
              >
                Email Address
              </Text>
              <View style={[styles.inputEmail, styles.inputEmailLayout]}>
                <TextInput
                  style={[styles.text, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                  value={email}
                  onChangeText={setEmail}
                  placeholder="hello@example.com"
                  keyboardType="email-address"
                  autoCapitalize="none"
                />
              </View>
            </View>
            <TouchableOpacity style={[styles.btnResetPassword, styles.inputEmailLayout]}
               onPress={handleResetPassword}
            >
              <Text style={[styles.label, styles.labelTypo]}>
                Password Reset
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
    </View>
  );
};

const styles = StyleSheet.create({
  frameFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  textEmailLayout: {
    height: 24,
    overflow: "hidden",
  },
  titleFlexBox: {
    textAlign: "left",
    width: 312,
  },
  labelTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  inputEmailLayout: {
    height: 48,
    flexDirection: "row",
    borderRadius: Border.br_5xs,
  },
  inputEmail: {
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    marginTop: 8,
    width: '100%', // Set to 100% of the parent container
  },
  text: {
    flex: 1, // Allow the text input to take the full width
    lineHeight: 22,
    color: Color.lightslategray,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    height: 48, // Match the height of the inputEmailLayout
  },
  iconleftarrow: {
    width: 24,
  },
  backArrow: {    
    padding: Padding.p_5xs,
    flexDirection: "row",
    borderRadius: Border.br_5xs,
  },
  title: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
    color: Color.black,
  },
  subtitle: {
    fontSize: FontSize.size_lg,
    lineHeight: 26,
    color: Color.gray555,
    marginTop: 12,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    width: 312,
  },
  textEmail: {
    lineHeight: 24,
    color: Color.black1,
    textAlign: "left",
    width: 312,
    height: 24,
    overflow: "hidden",
  },
  text: {
    lineHeight: 22,
    color: Color.lightslategray,
    width: "100%",
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    height: 24,
  },
  containerEmail: {
    justifyContent: "center",
  },
  label: {
    lineHeight: 16,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  btnResetPassword: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    marginTop: 32,
    justifyContent: "center",
    width: 312,
    alignItems: "center",
  },
  frame1: {
    height: 160,
    marginTop: 64,
    width: 312,
    alignItems: "center",
  },
  frame: {
    height: 328,
    marginTop: 67,
    width: 312,
    alignItems: "center",
  },
  container: {
    height: 435,
    width: 312,
  },
  forgotPassword: {
    backgroundColor: Color.white,
    flex: 1,
    width: "100%",
    height: 800,
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: 63,
    alignItems: "center",
  },
});

export default ForgotPassword;
