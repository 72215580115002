import * as React from "react";
import { Text, StyleSheet, View } from "react-native";
import { FontSize, Color, FontFamily, Border, LocalizedString } from "@common";

const SeatLegend = ({}) => {

  return (
    <View style={styles.selectionType}>
        <View style={styles.rectangleParentFlexBox}>
        <View style={[styles.frameChild, styles.frameLayout]} />
        <Text style={[styles.selected, styles.bookedTypo]}>{LocalizedString.selected}</Text>
        </View>
        <View style={[styles.rectangleGroup, styles.rectangleFlexBox]}>
        <View style={[styles.bookedItem, styles.frameLayout]} />
        <Text style={[styles.booked, styles.bookedTypo]}>{LocalizedString.booked}</Text>
        </View>
        <View style={styles.rectangleFlexBox}>
        <View style={[styles.frameInner, styles.frameLayout]} />
        <Text style={[styles.available, styles.bookedTypo]}>{LocalizedString.available}</Text>
        </View>        
    </View>    
  );
};

const styles = StyleSheet.create({
    frameLayout: {
      height: 12,
      width: 12,
      borderRadius: Border.br_base,
    },
    bookedTypo: {
      marginLeft: 10,
      textAlign: "left",
      fontFamily: FontFamily.interRegular,
      lineHeight: 22,
      fontSize: FontSize.size_sm,
      color: Color.gray555,
    },
    rectangleFlexBox: {
      marginLeft: 16,
      alignItems: "center",
      flexDirection: "row",
    },
    rectangleParentFlexBox: {
      alignItems: "center",
      flexDirection: "row",
    },
    frameChild: {
      backgroundColor: Color.deeppink,
    },
    bookedItem: {
      backgroundColor: Color.dimgray_100,
    },
    frameInner: {
      backgroundColor: Color.grayD9D9D9,
    },
    selectionType: {
      marginTop: 10,
      flexDirection: "row",
      justifyContent: "center",
    },
  });
  

export default SeatLegend;
