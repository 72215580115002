import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Color, FontSize, FontFamily, Border, Padding, LocalizedString } from '@common';
const RouteItem = ({ item, onItemPress }) => (
  <TouchableOpacity onPress={() => onItemPress(item)}>
    <View style={styles.frameShadowBox}>
      <View style={styles.groupParent}>
        <Text style={[styles.coachJasaPelangiContainer, styles.fromToSeatTypo]}>
          <Text style={styles.coach}>{LocalizedString.region}</Text>
          <Text style={styles.jasaPelangi}>{`${item.region}\n`}</Text>
          <Text style={styles.coach}>{LocalizedString.station}</Text>
          <Text style={styles.jasaPelangi}>{`${item.stationName}\n`}</Text>
          <Text style={styles.coach}>{LocalizedString.stationCode}</Text>
          <Text style={styles.jasaPelangi}>{item.stationCode}</Text>
        </Text>
      </View>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({    
    fromToSeatTypo: {
      textAlign: "left",
      lineHeight: 24,
      fontSize: FontSize.size_sm,
      fontFamily: FontFamily.interRegular
    },
    coach: {
      color: Color.black,
      fontFamily: FontFamily.interRegular
    },
    jasaPelangi: {
      color: Color.deeppink,
    },
    coachJasaPelangiContainer: {
      marginLeft: 10,
      fontFamily: FontFamily.interBold,
      fontWeight: "700",
      textAlign: "left",
      lineHeight: 24,
      fontSize: FontSize.size_sm,
    },
    groupParent: {
      // flexDirection: "row",
      // justifyContent: "center",
      // alignItems: "center",
    },
    frameShadowBox: {
      marginTop: 11,
      paddingBottom: Padding.p_5xl,
      paddingTop: Padding.p_base,    
      width: 330,
      shadowOpacity: 1,
      elevation: 10,
      shadowRadius: 10,
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowColor: "rgba(89, 27, 27, 0.05)",
      backgroundColor: Color.white,
      borderRadius: Border.br_base,
      alignItems: "left",
    },   
  });
  

export default RouteItem;
