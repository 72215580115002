import {windowWidth, windowHeight, alpha} from './size';
import {StyleSheet, Platform} from 'react-native';
import { Color, FooterHeight,FontFamily } from './GlobalStyles';

export const CommonStyle = StyleSheet.create({
  title:{
    fontSize: 24,
    fontFamily:FontFamily.interBold,  
    marginTop: 20,
  },
  subTitle:{
    fontSize: 16,
    fontFamily:FontFamily.interBold,  
    marginTop: 20,
  },
  desktopSearchView: {
    height: 200,
    backgroundColor: Color.white,
    width: "100%",
    justifyContent: 'center',  // Aligns children vertically in the center
    alignItems: 'center',  // Aligns children horizontally in the center
  },
  container: {        
    backgroundColor: Color.gray_100,
    flex: 1,
    width: "100%",    
    // alignItems: "center",
  },
  textWrapper:{
    width: "100%",
    justifyContent: 'center',  // Aligns children vertically in the center
    alignItems: 'center',  // Aligns children horizontally in the center
  },
  withDesktopFooter:{
    marginBottom: 0,
  },
  withDesktopPaymentFooter:{
    marginBottom: FooterHeight.payment,
  },
  withDesktopButtonFooter:{
    marginBottom: FooterHeight.button,
  },
  webTextField:{
    outlineStyle: 'none' 
  },
  breadcrumb:{
    paddingTop: 20,
    width:"100%",
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor:"white",
  }
});

// export const HEADER_NO_BACK =
//   Platform.OS === 'android'
//     ? {marginLeft: alpha * 10, flex: 1, fontFamily: TITLE_FONT}
//     : {textAlign: 'center', flex: 1, fontFamily: TITLE_FONT};
