import React, { useState } from 'react';
import { Image, StyleSheet, View, Text, TextInput , Platform } from "react-native";
import { FontFamily, FontSize, Border, Color, Padding, LocalizedString, CommonStyle } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";

const ForgotPasswordForm = ({email,setEmail,handleResetPassword}) => {

  return (
    <View style={[styles.forgotPassword, styles.frameFlexBox]}>
      <View style={styles.container}>      
        <View style={[styles.frame, styles.frameFlexBox]}>
          <View>
            <Text style={[styles.title, styles.titleFlexBox]}>
              Forgot Password?
            </Text>
            <Text style={styles.subtitle}>
              Enter your email address to get the password reset link.
            </Text>
          </View>
          <View style={[styles.frame1, styles.frameFlexBox]}>
            <View style={styles.containerEmail}>
              <Text
                style={[styles.textEmail, styles.labelTypo]}
                numberOfLines={1}
              >
                Email Address
              </Text>
              <View style={[styles.inputEmail, styles.inputEmailLayout]}>
                <TextInput
                  style={[styles.text, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                  value={email}
                  onChangeText={setEmail}
                  placeholder="hello@example.com"
                  keyboardType="email-address"
                  autoCapitalize="none"
                />
              </View>
            </View>
            <TouchableOpacity style={[styles.btnResetPassword, styles.inputEmailLayout]}
               onPress={handleResetPassword}
            >
              <Text style={[styles.label, styles.labelTypo]}>
                Password Reset
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>    
      </View>
  );
};

const styles = StyleSheet.create({
  frameFlexBox: {
    overflow: "hidden",
    alignItems: "center",
  },
  textEmailLayout: {
    height: 24,
    overflow: "hidden",
  },
  titleFlexBox: {
    textAlign: "left",
    width: 312,
  },
  labelTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  inputEmailLayout: {
    height: 48,
    flexDirection: "row",
    borderRadius: Border.br_5xs,
  },
  inputEmail: {
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
    marginTop: 8,
    width: '100%', // Set to 100% of the parent container
  },
  text: {
    flex: 1, // Allow the text input to take the full width
    lineHeight: 22,
    color: Color.lightslategray,
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    height: 48, // Match the height of the inputEmailLayout
  },
  iconleftarrow: {
    width: 24,
  },
  backArrow: {    
    padding: Padding.p_5xs,
    flexDirection: "row",
    borderRadius: Border.br_5xs,
  },
  title: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
    color: Color.black,
  },
  subtitle: {
    fontSize: FontSize.size_lg,
    lineHeight: 26,
    color: Color.gray555,
    marginTop: 12,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    width: 312,
  },
  textEmail: {
    lineHeight: 24,
    color: Color.black1,
    textAlign: "left",
    width: 312,
    height: 24,
    overflow: "hidden",
  },
  text: {
    lineHeight: 22,
    color: Color.lightslategray,
    width: "100%",
    fontSize: FontSize.size_base,
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
    height: 24,
  },
  containerEmail: {
    justifyContent: "center",
  },
  label: {
    lineHeight: 16,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  btnResetPassword: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    marginTop: 32,
    justifyContent: "center",
    width: 312,
    alignItems: "center",
  },
  frame1: {
    height: 160,
    marginTop: 64,
    width: 312,
    alignItems: "center",
  },
  frame: {
    height: 328,
    marginTop: 67,
    width: 312,
    alignItems: "center",
  },
  container: {
    height: 435,
    width: 312,
  },
  forgotPassword: {
    backgroundColor: Color.white,
    flex: 1,
    width: "100%",
    height: 800,
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: 63,
    alignItems: "center",
  },
});

export default ForgotPasswordForm;
