import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View, Text, TouchableOpacity, ImageBackground, ScrollView, Dimensions } from "react-native";
import WebView from 'react-native-webview';
import { FontFamily, Padding, Color, FontSize, Border,LocalizedString, CommonStyle } from "@common";
import { useDispatch, useSelector } from "react-redux";
import {createAction, Desktop, callNumber, sendEmail} from '@utils';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopFooter from '../components/DesktopFooter';
import DesktopWrapper from '../components/DesktopWrapper';
import Icon from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';


const ContactUs = ({navigation}) => {
  

const isDesktop = useSelector(state => state.member.isDesktopWeb);

const [_, forceUpdate] = useState();
 const memberId = useSelector((state) => state.member.memberId);
  const language = useSelector((state) => state.config.language);
  const authToken = useSelector((state) => state.member.authToken);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
};

const emailButtonPressed = (email) => {
  //console.log('Email button pressed');
  sendEmail(email,'','');
}

const phoneButtonPressed = (phone) => {
  //console.log('Phone button pressed');
  callNumber(phone);

}

  const phoneNo = "05-468 4999";
  const email = "customercare@kamparputrasentral.my"

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createAction('trip/loadGetRoute')({}));
  }, [dispatch]);

 
  return (
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={<DesktopFooter isDesktop={isDesktop} navigation={navigation}/>} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="Term"/>}>

    <ScrollView style={styles.container}>
      <Desktop>
      <View style={styles.backgroundImage}>
      <Image source={require('../assets/landing_page.jpg') } style={styles.backgroundImage} />      
      <View style={styles.titleContainer}>
        <Text style={[CommonStyle.title,styles.banner]}>{LocalizedString.contactUs}</Text>
      </View>
    </View>
    </Desktop>
    <View style={[styles.boxWrapper, isDesktop ? styles.row :  styles.column]}>
        <View style={[styles.box, isDesktop ? styles.box_row :  styles.box_column]}>
        <MaterialCommunityIcon name="phone-message" style={styles.icon} />
          <Text style={styles.title}>{LocalizedString.terminalSupport}</Text>
          <Text style={styles.description}>{LocalizedString.reachUsOut}</Text>
          <TouchableOpacity onPress={phoneButtonPressed.bind(this,phoneNo)}>
            <Text style={styles.link}>{phoneNo}</Text>
          </TouchableOpacity>        
          <Text style={styles.title}>{LocalizedString.orEmailTo}</Text>
          <TouchableOpacity onPress={emailButtonPressed.bind(this,email)}>
            <Text style={styles.link}>{email}</Text>
          </TouchableOpacity>
          <Text style={styles.title}>{LocalizedString.terminalSupportHours}</Text>
          <Text style={styles.description}>{LocalizedString.terminalSupportActualHours}</Text>
        </View>

        <View style={[styles.box, isDesktop ? styles.box_row :  styles.box_column]}>
            <Icon name="headphones" style={styles.icon} />
          <Text style={styles.title}>{LocalizedString.onlineSupport}</Text>
          <Text style={styles.description}>{LocalizedString.needHelp}</Text>
          <TouchableOpacity onPress={phoneButtonPressed.bind(this,phoneNo)}>
            <Text style={styles.link}>{phoneNo}</Text>
          </TouchableOpacity>
          <Text style={styles.title}>{LocalizedString.orEmailTo}</Text>
          <TouchableOpacity onPress={emailButtonPressed.bind(this,email)}>
            <Text style={styles.link}>{email}</Text>
          </TouchableOpacity>
          <Text style={styles.title}> {LocalizedString.operatingHours}</Text>
          <Text style={styles.description}>{LocalizedString.operatingHoursTime1}</Text>
          <Text style={styles.description}>{LocalizedString.operatingHoursTime2}</Text>
        </View>
      </View>
      </ScrollView>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: Color.white,
  },
  web:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },  
  backgroundImage: {
    width: "100%",
    height: 300,
    resizeMode: 'cover', // or 'stretch'
    backgroundColor: Color.black,
  },
  titleContainer: {
    backgroundColor: Color.black,
    position: "absolute",
    bottom: 0,
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
    flex: 1,
    height: 90,
    alignContent: "center",
    width: "100%",
    zIndex: 1,
  },
  banner: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    textAlign: "left",    
    color: Color.white,
  },

  home: {
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: 'Color.gray_100',
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
    minHeight: 1000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
  boxWrapper: {
    justifyContent: 'center',
    // alignSelf: 'center',
    backgroundColor: Color.white,    
  },
  column: {
    flexDirection: 'column',
  },
  row:{
    flexDirection: 'row',
  },
  box: {    
    padding: 20,
    borderColor: 'grey',
    borderWidth: 1,
    shadowColor: Color.gray01,
    shadowOpacity: 0.5,
    shadowOffset: { width: 1, height: 1 },
    margin: 10,
    alignItems: 'center',
  },
  box_column:{
    
  },
  box_row:{
    width: '45%',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
    fontFamily:FontFamily.interBold
  },
  description: {    
    marginBottom: 10,
    fontSize: 16,
    fontFamily:FontFamily.interRegular
  },
  link: {
    color: 'blue',
    fontSize: 18,
    fontFamily:FontFamily.interRegular
  },
  icon: {
    fontSize: 48,
    color:Color.black,
    alignSelf: 'center',
    fontFamily:FontFamily.interBold
  }

});

export default ContactUs;
