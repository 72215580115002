import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet ,Image} from 'react-native';
import { Color, LocalizedString, FontFamily} from '@common';
import Icon from 'react-native-vector-icons/FontAwesome';

import { DateTime } from "luxon";
import { TERMS_AND_CONDITIONS_URL, PRIVACY_POLICY_URL, POLICY_URL } from '../constants/constant';

const DesktopFooter = ({navigation,isDesktop}) => {
  const now = DateTime.now();
  const currentYear = now.year;


  function privacyPolicyPressed() {
    navigation.navigate('PrivacyPolicy')
  }

  function termsPressed(){
    navigation.navigate('Term')
  }

  function generalTicketingPolicyPressed(){
    navigation.navigate('Policy')
  }


  return (
  <View style={styles.footerWrapper}>
      <View style={styles.containerFooter}>
        
        {/* Connect with Us section */}
        <View style={styles.footerSocial}>
          <Text style={styles.header}>{LocalizedString.connectWithUs}</Text>
          <TouchableOpacity onPress={() => window.open('https://www.facebook.com/KamparPutraSentral')} style={styles.iconContainer}>
          {/* <Image source={require('../assets/fb.jg')} style={styles.icon} /> */}
            <Icon name="facebook-f" style={styles.icon} />
          </TouchableOpacity>
        </View>
        
        {/* Useful Links section */}
        <View style={isDesktop ? styles.usefulLinkDesktop : styles.usefulLink}>
          <TouchableOpacity onPress={privacyPolicyPressed} style={isDesktop ? styles.linkDesktop : styles.link}>
            <Text style={styles.linkText}>{LocalizedString.privacyPolicy}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={generalTicketingPolicyPressed} style={isDesktop ? styles.linkDesktop : styles.link}>
            <Text style={styles.linkText}>{LocalizedString.generalTicketingPolicy}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={termsPressed} style={isDesktop ? styles.linkDesktop : styles.link}>
            <Text style={styles.linkText}>{LocalizedString.onlineTermsAndConditions}</Text>
          </TouchableOpacity>
        </View>
        
        {/* Copyright section */}
        <View style={styles.copyright}>
          <Text style={styles.copyrightText}>© 2023 Terminal Kampar Putra Sentral. All Rights Reserved.</Text>
          {/* You can add another link here */}
        </View>
      </View>
    </View>
  );
};

// const styles = StyleSheet.create({
//   navBar:{    
//     flexDirection: "row",
//     width: '100%',
//     height: 70,
//     backgroundColor: Color.steelblue_100,
//     paddingHorizontal: 20,
//     position: 'absolute',
//     bottom: 0,
//   },
//   copyRight: {
//     color: Color.white,
//     position: "absolute",  // Set the position to absolute
//     bottom: 5,  // Position it at the bottom of the parent container
//     left: 0,  // Stretch the text box from this point
//     right: 0,  // To this point
//     textAlign: "center"  // Center the text within the stretched box
//   },
  
  
// });

const styles = StyleSheet.create({
  footerWrapper: {
    width: '100%',
    height: 280,
    backgroundColor: Color.darkgray_200,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    // add your styles here
  },
  link:{
    marginVertical: 3,
  }, 
  linkDesktop:{
    marginHorizontal: 10,
  },
  containerFooter: {
    // add your styles here
  },
  footerSocial: {
    // add your styles here
  },
  usefulLink: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  usefulLinkDesktop:{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  linkText:{
    color: Color.white,
    fontFamily: FontFamily.interRegular,
    fontSize:16,
  },
  copyright: {
    marginBottom: 10,
    // add your styles here
  },
  copyrightText:{
    color: Color.gray01,
    textAlign: 'center',
    fontFamily: FontFamily.interRegular,
    fontSize:16,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    color: Color.white,
    marginBottom: 20,
    fontStyle: 'italic',
    fontFamily: FontFamily.interBold
  },
  iconContainer:{
    width: 50,
    height: 50,
    backgroundColor: "#0A7AEA",
    borderRadius: 25,
    alignSelf: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    fontSize: 42,
    color:Color.white,
    alignSelf: 'center',
  }
});

export default DesktopFooter;
