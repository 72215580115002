import LocalizedStrings from 'react-localization';

export const LocalizedString = new LocalizedStrings({
 en:{
   login:"Login",  
   createAccount:"Create Account",
   emailAddress:"Email Address",
   welcome:"Welcome back, please login to your account.",
   password:"Password",
   skip:"Skip",
   name:"Name",
   signInHere:"Sign in here",
   signUp:"Sign up",
   byAgreeing:"By continuing, you agree to our ",
   termsOfService:"Terms of Service",
   alreadyHaveAccount:"Already have an account?",
   forget_password:"Forget Password",
   passwordReset:"Password changed successfully.",
   termsAndConditions:"Terms and Conditions",   
   passwordConfirmation:"Confirm Password",
   invalidEmail:"Please enter a valid email address.",
   invalidName:"Please enter a valid name.",
   invalidPhoneNo:"Please enter a valid phone number.",
   invalidPassword:"Password must meet the required criteria.",
   passwordsNotMatch:"Password and password confirmation do not match.",
   enterPasswordTwice:"",
   enterNewPassword:"Enter New Password",
   changePassword:"Change Password",
   language:"Language",
   more:"More",
   logout:"Logout",
   home:"Home",
   myProfile:"My Profile",
   phoneNo:"Phone Number",
   idNo:"ID Number",
   address:"Address",
   updateProfile:"Update Profile",
   updateProfileSuccess:"Profile updated successfully.",
   reenterPassword:"Re-enter New Password",
   passwordLength:"Password must be at least 8 characters.",
   passwordConfirmationLength:"Password confirmation must be at least 8 characters.",
   oldPasswordLength:"Old password must be at least 8 characters.",
   passwordsDoNotMatch:"Passwords do not match.",
   enterOldPassword:"Enter Old Password",
   rememeberMe:"Remember Me",
   passwordChanged:"Password changed successfully.",
   activate:"Activate",
   codeVerification:"Code Verification",
   enterVerificationCode:"Enter the verification code we just sent on your email",
   resentOtpIn:"Resend OTP in",
   invalidOtp:"OTP need to be at least 4 characters.",
   myBookings:"My Bookings",
   bookingDetail:"Booking Detail",
   chooseRoute:"Choose Route",
   region:"Region: ",
   station:"Station: ",
   stationCode:"Code: ",
   searchResults:"Search Results",
   select:"Select",
   chooseDate:"Choose Date",
   tripDetails:"Trip Details",
   chooseSeats:"Choose Seats",
   seatsAvailable:"Seats Available",
   totalVacantSeats:"Total Vacant Seats: ",
   adult:"Adult",
   child:"Child",
   senior:"Senior",
   oku:"OKU",
   pleaseSelectSeat:"Please make sure no. of selected seats is the same as no. of selected passengers",
   lowerDeck:"Lower Deck",
   upperDeck:"Upper Deck",
   confirm:"Confirm",
   enterContactInfo:"Enter a contact person who can be contact during emergency",
   contactNumber:"Contact No *",
   fromC:"FROM",
   toC:"TO",
   time:"Time",
   contactInfo:"Contact Info",
   minimum1Ticket:"Please select at least one ticket",
   tripSummary:"Trip Summary",
   price:"Price",
   contactNoAlert:"Please ensure Contact No. has at least 5 digits.",
   idNoAlert:"Please ensure Contact IC/Passport No. has at least 7 characters.",
   contactEmailAlert:"Please ensure Contact Email has at least 7 characters.",
   idNoPassengerWarning:"Please ensure the IC/Passport Number for the following passengers has more than 7 characters:",
   includeInsurance:"Premium Package (RM{price} / passenger): Inclusive of one water bottle and free RM20,000 PA insurance coverage / passenger\n*Please ensure to fill full name and correct IC No",
   noTripsAvailable:"No trips available",
   available:"Available",
    booked:"Booked",
    selected:"Selected",
    grandTotal:"Grand Total",
  cancel:"Cancel",
  pay:"Pay",
  subtotal:"Subtotal",
  facilityFee:"Facility Fee",
  insuranceFee:"Premium Package",
  otherFee:"Other Fee",
  yourTickets:"Your Tickets",
  confirmLogout:"Confirm Logout",
  enterPaymentDetails:"Enter Payment Details",
  faq:"F.A.Q",
  frequentlyAskedQuestions:"Frequently Asked Questions",
  contactUs:"Contact Us",
  privacyPolicy:"Privacy Policy",
  generalTicketingPolicy:"General Ticketing Policy",
  onlineTermsAndConditions:"Terms and Conditions",
  insurance:"Insurance",
  insuranceLong:"Kampar Putra Sentral PA Insurance",
  pleaseSelectFromToAndDate:"Please select from, to and date",
  settings:"Settings",
  important_links:"Important Links",
  onlineTicketPurchasing:"Online Ticket Purchasing\nComing Soon",
  verifyingPayment:"Verifying Payment, please wait...",
  paymentSuccess:"Payment Success",
  paymentFailedTitle:"Payment Failed",
  paymentFailed:"Payment failed. Please contact customer support.",
  paymentPendingTitle:"Verifying Payment",
  paymentPending:"Verifying payment. Please check payment status later at \"My Bookings\" page",
  paymentRefunded:"Your payment has been refunded.",
  payment:"Payment",
  departingBus:"Bus Selection",
  registrationSuccessful:"Register Successful. Please check your email to activate the account",
  forgetPasswordSuccessful:"Your password reset request has been processed. Please check your email for further instructions.",
  noMoreResult:"No more bookings to show.",
  coachName:"Coach Name: ",
  coachNo:"Coach No:",
  travelTime:"Departure: ",
  seat:"Seat:",
  contactNo:"Contact No:",
  noOfTicket:"No Of Tickets:",
  noOfSeats:"No. of seat(s) to select: ",
  backToHome:"Back to Home",
  confirmPurchase:"Confirm Purchase",
  icPassportNumber:"Contact IC/Passport Number *",
  contactEmail:"Contact Email *",
  finalIcPassportNumber:"Contact IC / Passport number:",
  finalContactEmail:"Contact Email:",
  passengerIcPassportNumber:"Passenger #{index} IC/Passport No. ({type})",
  passengerName:"Passenger #{index} Name. ({type})",
  passengerDetails:"Passenger Details",
  insuranceCoverage:"Premium Package",
  totalRm:"Total (RM)",
  proceedToPayment:"Proceed to Payment",
  connectWithUs:"Connect with Us",
  placeForEveryone:"A Place For Everyone",
  from:"From",
  to:"To",
  chooseFrom:"Choose From",
  chooseTo:"Choose To",
  departure:"Departure Date",
  chooseDate:"Choose Date",
  date:"Date",
  search:"Search",
  topDestination:"TOP DESTINATION",
  destination:"Destination",
  popularBusRoutes:"Our popular bus routes",
  terminalSupport:"Terminal Support",
  onlineSupport:"Online Support",
  reachUsOut:"Reach us out. We'd love to hear from you.",
  orEmailTo:"Or e-mail to",
  terminalSupportHours:"Terminal Support Operation Hours",
  terminalSupportActualHours:"Mon-Sun (24 hours)",
  needHelp:"Need help? We're here to assist you.",
  operatingHours:"Operation Hours",
  operatingHoursTime1:"Mon - Fri (8:00AM - 9:00PM)",
  operatingHoursTime2:"Sat - Sun (9:00AM - 5:00PM)",
  refNo:"Ref No: ",
  transactionTime:"Transaction Time:",
  transactionStatus:"Transaction Status:",
  paymentStatus:"Payment Status:",
  status:"Status:",
  amount:"Amount:",
  loadMore:"Load more...",
  enterKeyword:"Enter keyword...",
  coachNameHeader:"Coach Name",
  seatAvailability:"Seats\nAvailability",
  departTime:"Depart Time",
  priceHeader:"Price",
  printAsPdf:"Print as PDF",
 },
 ms:{
  login: "Log Masuk",
  createAccount: "Buat Akaun",
  emailAddress: "Alamat E-mel",
  welcome: "Selamat kembali, sila log masuk ke akaun anda.",
  password: "Kata Laluan",
  skip: "Langkau",
  byAgreeing: "Dengan meneruskan, anda bersetuju dengan ",
  name: "Nama",
  signInHere: "Log Masuk di sini",
  signUp: "Daftar",
  termsOfService: "Syarat-syarat Perkhidmatan",
  alreadyHaveAccount: "Sudah ada akaun?",
  forget_password: "Lupa Kata Laluan",
  passwordReset:"Kata Laluan telah berjaya ditukar.",
  termsAndConditions: "Terma dan Syarat",
  passwordConfirmation: "Sahkan Kata Laluan",
  invalidEmail:"Sila masukkan alamat e-mel yang sah.",
  invalidName:"Sila masukkan nama yang sah.",
  invalidPhoneNo:"Sila masukkan nombor telefon yang sah.",
  invalidPassword:"Kata laluan mesti memenuhi kriteria yang diperlukan.",
  passwordsNotMatch:"Kata laluan dan pengesahan kata laluan tidak sepadan.",
  enterPasswordTwice:"",
  enterNewPassword:"Masukkan Kata Laluan Baru",
  changePassword:"Tukar Kata Laluan",
  language:"Bahasa",
  more:"Lagi",
  logout:"Log Keluar",
  home:"Laman Utama",
  myProfile:"Profil Saya",
  phoneNo:"Nombor Telefon",
  idNo:"Nombor ID",
  address:"Alamat",
  updateProfile:"Kemaskini Profil",
  updateProfileSuccess:"Profil berjaya dikemaskini.",
  reenterPassword:"Masukkan Semula Kata Laluan Baru",
  passwordLength:"Kata laluan mesti sekurang-kurangnya 8 aksara.",
  passwordConfirmationLength:"Pengesahan kata laluan mesti sekurang-kurangnya 8 aksara.",
  oldPasswordLength:"Kata laluan lama mesti sekurang-kurangnya 8 aksara.",
  passwordsDoNotMatch:"Kata laluan tidak sepadan.",
  enterOldPassword:"Masukkan Kata Laluan Lama",
  rememeberMe:"Ingatkan Saya",
  passwordChanged:"Kata laluan berjaya ditukar.",
  activate:"Aktifkan",
  codeVerification:"Pengesahan Kod",
  enterVerificationCode:"Masukkan kod pengesahan yang kami hantar ke e-mel anda",
  resentOtpIn:"Hantar semula OTP dalam",
  invalidOtp:"OTP mesti sekurang-kurangnya 4 aksara.",
  myBookings:"Tempahan",
  bookingDetail:"Info Tempahan",
  chooseRoute:"Pilih Laluan",
  region:"Wilayah: ",
  station:"Stesen: ",
  stationCode:"Kod: ",
  searchResults:"Keputusan Carian",
  select:"Pilih",
  chooseDate:"Pilih Tarikh",
  date:"Tarikh",
  tripDetails:"Butiran Perjalanan",
  chooseSeats:"Pilih Tempat Duduk",
  seatsAvailable:"Tempat Duduk tersedia",
  totalVacantSeats:"Jumlah Tempat Duduk: ",
  adult:"Dewasa",
   child:"Kanak-Kanak",
   senior:"Warga Emas",
   oku:"OKU",
   pleaseSelectSeat:"Sila pastikan bilangan tempat duduk yang dipilih adalah sama dengan bilangan penumpang",
  lowerDeck:"Tgkt Bawah",
  upperDeck:"Tgkt Atas",
  confirm:"Sahkan",
  enterContactInfo:"Sila masukkan nombor telefon individu yang boleh dihubungi sekiranya berlaku kecemasan",
  fromC:"DARI",
  toC:"KE",
  time:"Masa",
  contactInfo:"Maklumat Perhubungan",
  minimum1Ticket:"Sila pilih sekurang-kurangnya satu tiket",
  tripSummary:"Ringkasan Perjalanan",
  price:"Jumlah",
  contactNoAlert:"Sila pastikan nombor telefon penghubung mempunyai lebih daripada 5 aksara.",
  idNoAlert:"Sila pastikan nombor IC/Passport penghubung mempunyai lebih daripada 7 aksara.",
  contactEmailAlert:"Sila pastikan alamat emel penghubung mempunyai lebih daripada 7 aksara.",
  idNoPassengerWarning:"Sila pastikan Nombor IC/Passport untuk penumpang berikut mempunyai lebih daripada 7 aksara:",
  includeInsurance:"Pakej Premium (RM{price} / penumpang): Termasuk satu botol air dan perlindungan insurans PA percuma bernilai RM20,000 / penumpang\n*Pastikan nama penuh diisi dan No IC yang betul",
  noTripsAvailable:"Tiada perjalanan yang tersedia",
  available:"Tersedia",
  booked:"Ditempah",
  selected:"Dipilih",
  grandTotal:"Jumlah Keseluruhan",
  cancel:"Batal",
  pay:"Bayar",
  subtotal:"Sub-Jumlah",
  facilityFee:"Yuran Fasiliti",
  insuranceFee:"Pakej Premium",
  otherFee:"Yuran Lain",
  yourTickets:"Tiket Anda",
  confirmLogout:"Sahkan Log Keluar",
  contactNumber:"No Telefon *",
  enterPaymentDetails:"Masukkan Butiran Pembayaran",
  faq:"Soalan Lazim",
  contactUs:"Hubungi Kami",
  privacyPolicy:"Dasar Privasi",
  generalTicketingPolicy:"Dasar Tiket",
  onlineTermsAndConditions:"Terma dan Syarat",
  frequentlyAskedQuestions:"Soalan Lazim",
  insurance:"Insurans",
  insuranceLong:"Kampar Putra Sentral Insuran Kemalangan Peribadi Penumpang",
  pleaseSelectFromToAndDate:"Sila pilih dari, ke dan tarikh",
  settings:"Tetapan",
  important_links:"Pautan Penting",
  onlineTicketPurchasing:"Pembelian Tiket Dalam Talian\nAkan Datang",
  verifyingPayment:"Sedang mengesahkan pembayaran, sila tunggu...",
  paymentSuccess:"Kejayaan Pembayaran",
  paymentFailedTitle:"Pembayaran Gagal",
  paymentFailed:"Pembayaran gagal. Sila minta bantuan daripada admin.",
  paymentPendingTitle:"Pembayaran Sedang Disahkan",
  paymentPending:"Pembayaran sedang disahkan. Sila semak semula status pembayaran di skrin \"Tempahan\" sebentar lagi.",
  paymentRefunded:"Pembayaran anda telah dipulangkan.",
  payment:"Pembayaran",
  departingBus:"Pilihan Bas",
  registrationSuccessful:"Daftar Berjaya. Sila semak e-mel anda untuk mengaktifkan akaun",
  forgetPasswordSuccessful:"Permintaan set semula kata laluan anda telah diproses. Sila semak emel anda untuk arahan selanjutnya.",
  noMoreResult:"Tiada tempahan.",
  coachName:"Nama Koc: ",
  coachNo:"Nombor Koc:",
  travelTime:"Perlepasan: ",
  seat:"Tempat Duduk:",
  contactNo:"Nombor Telefon:",
  noOfTicket:"Bilangan Ticket:",
  noOfSeats:"Bilangan Tempat Duduk yang perlu dipilih: ",
  backToHome:"Kembali Ke Laman Utama",
  confirmPurchase:"Pengesahan Tempahan",
  icPassportNumber:"No IC/Passport Penghubung *",
  contactEmail:"Alamat Email Penghubung *",
  finalIcPassportNumber:"No IC / Passport Penghubung:",
  finalContactEmail:"Alamat Email Penghubung:",
  passengerIcPassportNumber:"No. IC/Passport Penumpang #{index} ({type})",
  passengerName:"Nama Penumpang #{index}({type})",
  passengerDetails:"Maklumat Penumpang",
  insuranceCoverage:"Pakej Premium",
  totalRm:"Jumlah (RM)",
  proceedToPayment:"Teruskan ke Pembayaran",
  connectWithUs:"Berhubung Dengan Kami",
  placeForEveryone:"Tempat Untuk Semua",
  from:"Dari",
  to:"Ke",
  chooseFrom:"Pilih Dari",
  chooseTo:"Pilih Ke",
  departure:"Tarikh Berlepas",
  chooseDate:"Pilih Tarikh",
  search:"Cari",
  topDestination:"DESTINASI TERATAS",
  destination:"Destinasi",
  popularBusRoutes:"Laluan bas popular kami",
  terminalSupport:"Sokongan Terminal",
  onlineSupport:"Sokongan Dalam Talian",
  reachUsOut:"Hubungi kami. Kami ingin mendengar daripada anda.",
  orEmailTo:"Atau e-mel ke",
  terminalSupportHours:"Waktu Operasi Sokongan Terminal",
  terminalSupportActualHours:"MIsn-Ahad (24 jam)",
  needHelp:"Perlukan bantuan? Kami di sini untuk membantu anda.",
  operatingHours:"Waktu Operasi",
  operatingHoursTime1:"Isn - Jum (8:00AM - 9:00PM)",
  operatingHoursTime2:"Sabtu - Ahad (9:00AM - 5:00PM)",
  refNo:"No Rujukan: ",
  transactionTime:"Masa Transaksi:",
  transactionStatus:"Status Transaksi:",
  paymentStatus:"Status Pembayaran:",
  status:"Status:",
  amount:"Jumlah:",
  loadMore:"Muat turun selanjutnya...",
  enterKeyword:"Masukkan kata kunci...",
  coachNameHeader:"Nama Koc",
  seatAvailability:"Tempat Duduk\ntersedia",
  departTime:"Masa Berlepas",
  priceHeader:"Harga",
  printAsPdf:"Cetak sebagai PDF",
 },
});