import {
  member,
  trip,
  config,
} from './models';
import {DvaInstance} from 'dva-core-ts';

export function registerModels(app: DvaInstance) {
  app.model(member);
  app.model(trip);
  app.model(config);
}
