import AsyncStorage from '@react-native-async-storage/async-storage';

import EventObject from '../models/event_object';
import {refreshToken} from '../../webservice/putrajaya_api';
import { createAction } from "@utils";
import { call, put, select } from 'redux-saga/effects';

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

// Generic function to handle API calls and token refresh
function* handleApiCall(apiFunction, params) {
    let { memberId, authToken } = params;
    let json = yield call(apiFunction, params);
    let eventObject = new EventObject(json);
  
    if (eventObject.isTokenExpired()) {
      const newAuthToken = yield call(refreshAuthToken, memberId, authToken);
  
      if (newAuthToken) {
        // Update authToken in params for retry
        params.authToken = newAuthToken;
        yield delay(1000);
        json = yield call(apiFunction, params);
        eventObject = new EventObject(json);
      }
    }
  
    return eventObject;
}

async function refreshAuthToken(memberId, authToken) {
    try {
      //console.log('refreshToken', authToken);
      const json = await refreshToken({memberId,authToken});
                
      //console.log('refreshToken result', JSON.stringify(json));
      const eventObject = new EventObject(json);
  
      if (eventObject.success === true) {
        await saveAuthTokenToStorage(eventObject.result);
        return eventObject.result.authToken
        await createAction('member/setAuthToken')(eventObject.result);
      }
    } catch (err) {
      //console.log('err', err);
    }
    return null; // Return null if refresh fails
}

function getUserFromStorage() {
    return AsyncStorage.getItem('@profile', (err, result) => {
      // log('@profile ', result);
      if (err) {
        log('@profile Error: ', err);
      }
  
      return result;
    });
  }

async function saveAuthTokenToStorage(data) {
    let userString = await getUserFromStorage()
    if (userString) {
      let user = JSON.parse(userString)
      if (user) {
        //console.log(user)
        user.authToken = data.authToken;
        AsyncStorage.setItem('@profile', JSON.stringify(user));
      }
    }
  }

export default handleApiCall