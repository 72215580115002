import { View, Text, StyleSheet } from "react-native";
import React from "react";
import { Color, FontFamily, FontSize, Padding, Border,LocalizedString } from "@common";
import TicketSummary from "./TicketSummary";

const TicketFeeSummary = ({ fare,subTotal, ticketTypes, fareFacility, fareOther, fareInsurance, grandTotal }) => {
    return  (
        <View>
        <View style={[styles.yourTicketsParent, styles.buttonSpaceBlock]}>
          <Text style={[styles.yourTickets, styles.priceRmLayout]}>
            {LocalizedString.yourTickets}
          </Text>
          <Text style={[styles.priceRm, styles.priceRmLayout]}>{LocalizedString.price} ({fare.currency})</Text>
        </View>
        <View style={styles.searchResult}>
          <View style={styles.result01}>
          {ticketTypes.map((ticket, index) => (
          <TicketSummary key={index} {...ticket} fare={fare} />
        ))}
          
            <View style={[styles.result01Item, styles.result01Layout]} />
              <View style={styles.subtotal}>
                <Text style={styles.text2Typo}>{`${LocalizedString.subtotal}
  ${LocalizedString.facilityFee}
  ${LocalizedString.otherFee}
  ${LocalizedString.insuranceFee}`}</Text>
                <Text style={[styles.text2, styles.text2Typo]}>{`${subTotal.toFixed(2)}
  ${fareFacility.toFixed(2)}
  ${fareOther.toFixed(2)}
  ${fareInsurance.toFixed(2)}`}</Text>
              </View>
              <View style={[styles.result01Item, styles.result01Layout]} />
              <View style={styles.subtotal}>
                <Text style={[styles.grandTotal, styles.textTypo]}>
                  {LocalizedString.grandTotal}
                </Text>
                <Text style={[styles.text3, styles.textTypo]}>{grandTotal.toFixed(2)}</Text>
              </View>
          </View>
        </View>
      </View>
    ) 
  };

  const styles = StyleSheet.create({
    buttonSpaceBlock: {
      marginTop: 9,
      flexDirection: "row",
    },
    priceRmLayout: {
      width: 126,
      lineHeight: 24,
      fontSize: FontSize.size_mini,
      fontFamily: FontFamily.interBold,
      fontWeight: "700",
      height: 25,
      color: Color.black,
    },
    priceRm: {
        marginLeft: 64,
        textAlign: "right",
      },
    textTypo: {
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
    },
    result01Layout: {
      opacity: 0.3,
      height: 1,
      width: 329,
      borderTopWidth: 1,
      marginTop: 16,
      borderStyle: "solid",
    },
    text2Typo: {
      fontFamily: FontFamily.interRegular,
      fontSize: FontSize.size_sm,
      textAlign: "right",
      color: Color.black,
    },
    text2: {
        marginLeft: 16,
    },
    text3: {
        marginLeft: 16,
        textAlign: "right",
        color: Color.black,
        fontSize: FontSize.size_xl,
        fontWeight: "600",
    },
    subtotal: {
      width: 303,
      marginTop: 16,
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    result01: {
      borderRadius: Border.br_base,
      backgroundColor: Color.white,
      shadowColor: "rgba(89, 27, 27, 0.05)",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 10,
      elevation: 10,
      shadowOpacity: 1,
      paddingHorizontal: 0,
      paddingBottom: Padding.p_5xl,
      alignItems: "center",
    },
    searchResult: {
      marginTop: 9,
    },
});


  export default TicketFeeSummary;