import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View, Text, TouchableOpacity, ImageBackground, FlatList, Dimensions} from "react-native";
import { FontFamily, Padding, Color, FontSize, Border,LocalizedString } from "@common";
import { useDispatch, useSelector } from "react-redux";
import {createAction} from '@utils';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopLoginModal from '../components/DesktopLoginModal';
import DesktopFooter from '../components/DesktopFooter';
import { Popover, Select, Button } from 'native-base';
import CalendarView from '../components/CalendarView';
import RouteItem from '../components/RouteItem';
import DesktopSearchBar from '../components/DesktopSearchBar';
import DesktopWrapper from '../components/DesktopWrapper';
import {DUMMY, DEFAULT_FROM_DESTINATION} from "@constants"
import {DateTime} from 'luxon';

const numColumns = 3
const windowWidth = Dimensions.get('window').width * 0.7;
const paddingSize = 20;
const itemSize = 250;

const DesktopHome = ({navigation}) => {
  
const [_, forceUpdate] = useState();
 const memberId = useSelector((state) => state.member.memberId);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const selectedDate = useSelector((state) => state.trip.selectedDate);
  const language = useSelector((state) => state.config.language);
  const routes = useSelector((state) => state.trip.routes);
  const authToken = useSelector((state) => state.member.authToken);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
};

const destinations = [
  { name: 'TBS', price: 'RM35.00', image: require('../assets/dest_tbs.jpg'),"stationCode":"TBS","stationName":"TERMINAL BERSEPADU SELATAN","region":"Central" },
  { name: 'Larkin', price: 'RM55.00', image: require('../assets/dest_jb.jpg'),"stationCode":"LAR","stationName":"JB - LARKIN","region":"Southern" },
  { name: 'Alor Setar', price: 'RM28.00', image: require('../assets/dest_alor_setar.jpg'),"stationCode":"TBC","stationName":"Alor Setar","region":"Southern" },
  { name: 'Melaka Sentral', price: 'RM16.00', image: require('../assets/dest_melaka.jpg'),"stationCode":"TBC","stationName":"Melaka Sentral","region":"Southern"  },
  { name: 'Teluk Intan', price: 'RM50.00', image: require('../assets/dest_teluk_intan.jpg'),"stationCode":"TBC","stationName":"Teluk Intan","region":"Southern"  },
  { name: 'Terminal One Seremban', price: 'RM50.00', image: require('../assets/dest_seremban.jpg'),"stationCode":"TBC","stationName":"Terminal One Seremban","region":"Southern"  },
];
 

  const dispatch = useDispatch();

  useEffect(() => {

    //console.log(`DesktopHome useEffect called ${authToken}}`);
    dispatch(createAction('trip/loadGetRoute')({}));
  }, [dispatch]);

 

  const searchButtonPressed = () => {

    if (fromSelectedStation === null || toSelectedStation === null || selectedDate === null) {

      alert(LocalizedString.pleaseSelectFromToAndDate);
      return;
    }
    //dispatch(createAction('trip/resetTripInfo')({}));
     navigation.navigate("SearchResult")
  }

  const itemChosen = (item) => {

    if (DUMMY){
      return
    }



    dispatch(createAction('trip/setFromSelectedStation')(DEFAULT_FROM_DESTINATION));
    dispatch(createAction('trip/setToSelectedStation')(item));
    const currentDate = DateTime.now().setZone('Asia/Singapore').toISODate();
    dispatch(createAction('trip/setSelectedDate')(currentDate));
    dispatch(createAction('trip/resetTripInfo')({}));
    navigation.navigate("SearchResult")
    // setTimeout(searchButtonPressed, 1000);

    
    //console.log(`item chosen ${JSON.stringify(item)}`);
  };
  
  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.itemContainer} onPress={() => itemChosen(item)}>
      <Image source={item.image} style={styles.image} />
      <Text style={styles.destinationName}>{item.name}</Text>
    </TouchableOpacity>
  );
 
  return (
    <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={<DesktopFooter isDesktop={isDesktop} navigation={navigation}/>} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="DesktopHome"/>}>

    <ImageBackground source={require('../assets/landing_page.jpg') } style={styles.backgroundImage}>
      <View style={styles.container}>

        <DesktopSearchBar dispatch={dispatch} destinationRoutes={routes} toSelectedStation={toSelectedStation} 
        fromSelectedStation={DEFAULT_FROM_DESTINATION} selectedDate={selectedDate} 
        searchButtonPressed={searchButtonPressed} showBanner={true} />          
      </View>    
      
      </ImageBackground>      
      <View style={styles.container1}>
        <Text style={styles.title}>{LocalizedString.topDestination}</Text>
        <Text style={styles.subtitle}>{LocalizedString.popularBusRoutes}</Text>
        <View>
        <FlatList
          data={destinations}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          numColumns={numColumns}
          scrollEnabled={false}
          columnWrapperStyle={styles.columnWrapper}
          style={{ marginBottom: 80 }}          
        />
        </View>
      </View>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  horizontal:{
    flexDirection: "row",
    backgroundColor: "white",
    padding: 10,
    alignItems: "center",
  },
  backgroundImage: {
    flex: 1,
    width: "100%",
    alignItems: "center",
    resizeMode: 'cover', // or 'stretch'
  },
  headerSpaceBlock: {
    paddingVertical: 0,
    flexDirection: "row",
  },
  disabled: {
    opacity: 0.65,
  },
  headerFlexBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
  },
  confirmTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  detailsSpaceBlock: {
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: 0,
  },
  fromTypo: {
    color: Color.gray555,
    lineHeight: 16,
    fontSize: FontSize.size_xs,
    textAlign: "left",
  },
  detailsWrapperBorder: {
    marginTop: -12,
    borderWidth: 1,
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    borderRadius: Border.br_5xs,
    paddingHorizontal: Padding.p_5xs,
    zIndex: 0,
  },
  wrapperSpaceBlock: {
    marginLeft: 12,
    flexDirection: "row",
  },
  home1Typo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  larWrapperSpaceBlock: {
    paddingRight: Padding.p_13xl,
    paddingLeft: Padding.p_21xl,
    borderRadius: Border.br_13xl,
    flexDirection: "row",
  },
  bookingTypo: {
    fontFamily: FontFamily.interLight,
    fontWeight: "300",
  },
  textLayout: {
    lineHeight: 24,
    textAlign: "left",
    color: Color.black,
  },
  buttonSearchBg: {
    backgroundColor: Color.steelblue_200,
    flexDirection: "row",
    height: 60,
  },
  bannerTypo: {
    fontSize: FontSize.size_lg,
    lineHeight: 24,
  },
  home1Layout: {
    marginTop: 4,
    height: 12,
    width: 48,
    lineHeight: 12,
    fontSize: FontSize.size_xs,
    textAlign: "center",
  },
  icon: {
    width: 31,
    height: 9,
  },
  frameChild: {
    marginLeft: 3.07,
  },
  batteryIcon: {
    width: 19,
    height: 19,
    marginLeft: 3.07,
    overflow: "hidden",
  },
  leftArrowIcon: {
    display: "none",
    overflow: "hidden",
  },
  bookFlight: {
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    width: 192,
    color: Color.black,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    paddingVertical: 0,
    flexDirection: "row",
  },
  menuIcon: {
    overflow: "hidden",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
    display: "none",
  },
  content: {
    paddingVertical: Padding.p_xs,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    flexDirection: "row",
  }, 
  from: {
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
  },
  fromWrapper: {
    paddingVertical: Padding.p_9xs,
    backgroundColor: Color.white,
    borderRadius: Border.br_5xs,
    paddingHorizontal: Padding.p_5xs,
    flexDirection: "row",
  },
  fromFrame: {
    zIndex: 1,
  },
  at21: {
    width: 44,
    height: 43,
  },
  kampar: {
    fontSize: FontSize.size_base,
    lineHeight: 24,
    textAlign: "left",
    color: Color.black,
  },
  kamparWrapper: {
    flex: 1,
  },
  details: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
  },
  kampar1: {
    fontSize: FontSize.size_3xs,
    color: Color.gray999,
    width: 208,
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
  },
  containerInputFrom: {
    paddingTop: Padding.p_3xs,
    paddingBottom: Padding.p_xs,
    backgroundColor: Color.white,
  },
  at221: {
    margin: 7,
    width: 28,
    height: 28,
  },
  at22: {
    width: 33,
    height: 38,
  },
  arrow: {
    borderRadius: 40,
    padding: Padding.p_5xs,
    borderWidth: 1,
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    marginLeft: 12,
    backgroundColor: Color.white,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  details1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  larWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  containerTo: {
    marginTop: 8,
    marginRight: 8,
  },
  departure1: {
    textAlign: "left",
    color: Color.gray555,
    lineHeight: 16,
    fontSize: FontSize.size_xs,
  },
  calenderIcon: {
    width: 20,
    height: 20,
    overflow: "hidden",
  },
  text: {
    fontSize: FontSize.size_sm,
    width: 200,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
  },
  wrapper: {
    width: 78,
  },
  details2: {
    width: 124,
    paddingLeft: Padding.p_5xs,
    paddingRight: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  detailsWrapper: {
    width: 297,
    paddingVertical: Padding.p_3xs,
  },
  departureReturn: {
    marginTop: 8,
    flexDirection: "row",
  },
  confirm: {
    width: 120,
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  buttonSearch: {
    width: 296,
    paddingHorizontal: Padding.p_85xl,
    marginTop: 8,
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    justifyContent: "center",
    paddingVertical: Padding.p_xs,
    alignItems: "center",
  },
  banner: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    textAlign: "left",
    color: Color.black,
  },
  containerBanner: {
    height: 178,
    marginTop: 118,
    width: 358,
    justifyContent: "flex-end",
  },
  container1: {
    flex: 1,
    width: "80%",
    alignSelf:'center',
    padding: 20,
    paddingBottom: 200,
    height: 400,
  },
  container: {
    zIndex: 1,
    width: 358,
    marginTop:80,
    alignItems: "center",
    flex: 1,
  },
  booking: {
    color: Color.mistyrose,
    fontFamily: FontFamily.interLight,
    fontWeight: "300",
  },
  menubooking: {
    opacity: 0.65,
    alignItems: "center",
  },
  home: {
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: 'Color.gray_100',   
    flex: 1,
    minHeight: 800,
  },
  title: {
    fontSize: 24,
    fontFamily:FontFamily.interBold,     
    fontWeight: "600", 
    textAlign:"center"
  },
  subtitle: {
    fontSize: 16,
    fontFamily:FontFamily.interBold,  
    marginBottom: 20,
    textAlign:"center"
  },
  columnWrapper: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: 0,
    padding: paddingSize,
  },
  itemContainer: {
    width: itemSize, // Adjust accordingly
    height: itemSize,
    padding: paddingSize/2,    
  },
  image: {
    width: itemSize-paddingSize,
    height: itemSize-paddingSize, // Adjust height if necessary
  },
  destinationName: {
    position:"absolute",
    bottom:0,
    fontSize: 14,
    fontWeight: 'bold',
    width:itemSize-paddingSize,
    color:"white",
    padding: 10,
    textAlign:"center",
    backgroundColor:"orange",
  },
  destinationPrice: {
    fontSize: 12,
    marginTop: 5,
  },
});

export default DesktopHome;
