import * as React from "react";
import { Text, StyleSheet, View, Image,TouchableOpacity } from "react-native";
import { FontSize, Color, FontFamily, Border, Padding, LocalizedString } from "@common";
import { createAction } from "@utils";

const SeatMap = ({row, col, seatDetails,selectedSeats,useSelector,dispatch,isLayoutDoubleRow}) => {

    const adultTicketQuantity = useSelector((state) => state.trip.adultTicketQuantity);
    const childTicketQuantity = useSelector((state) => state.trip.childTicketQuantity);
    const seniorTicketQuantity = useSelector((state) => state.trip.seniorTicketQuantity);
    const okuTicketQuantity = useSelector((state) => state.trip.okuTicketQuantity);

  // Convert seatDetails array to a row-based object.
  const seatHash = seatDetails.reduce((acc, [key, ...rest]) => {
    const [, row, column] = key.split('|');
    if (!acc[row]) acc[row] = {};
    acc[row][column] = rest;
    return acc;
  }, {});

  const getTotalQuantity = () => {
    return okuTicketQuantity+childTicketQuantity+adultTicketQuantity+seniorTicketQuantity;
  }

  const onSeatSelected = (seat) => {
    let seatNo = seat[0];

    if (selectedSeats.includes(seatNo)) {
      const updatedSeats = selectedSeats.filter((seat) => seat !== seatNo);
      dispatch(createAction('trip/setSelectedSeats')(updatedSeats));
    }else{

      let total = getTotalQuantity()
      if (selectedSeats.length >= total) {
        
      }else{
        dispatch(createAction('trip/setSelectedSeats')([...selectedSeats, seatNo]));
      } 
    }
  }


  return (
    <View style={styles.containerSeats}>
      {[...Array(col)].map((_, colIndex) => (
        <View key={colIndex} style={styles.row}>
          {[...Array(row)].map((_, rowIndex) => {
            const seatInfo = seatHash[rowIndex]?.[colIndex];

            // if not seat or seat not available
            if (!seatInfo || (seatInfo && seatInfo[1] === '0')) {
              return (              
                <View key={rowIndex} style={[styles.wrapperSpaceBlock, styles.bookedItem]}>
                  <Text style={styles.selectedText}>
                    {seatInfo ? seatInfo[0] : ' '}
                  </Text>
                </View>
              );
            }

            // if seat is selected
            if (selectedSeats.includes(seatInfo[0])) {
              return (              
                <TouchableOpacity key={rowIndex} style={[styles.wrapperSpaceBlock, styles.selectedFrame]} onPress={onSeatSelected.bind(this,seatInfo)}>
                  <Text style={styles.selectedText}>
                    {seatInfo ? seatInfo[0] : ''}
                  </Text>
                </TouchableOpacity>
              );
            }
            
            // if seat is available
            
            return (              
              <TouchableOpacity key={rowIndex} style={[styles.wrapperSpaceBlock, styles.availableFrame]} onPress={onSeatSelected.bind(this,seatInfo)}>
                <Text style={styles.a}>
                  {seatInfo ? seatInfo[0] : ''}
                </Text>
              </TouchableOpacity>
            );          
          }).reverse()}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
    row: {
      flexDirection: 'row',
    },
    wrapperSpaceBlock: {
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 5,
      marginRight: 5,
      padding: Padding.p_5xs,
      borderRadius: Border.br_9xs,
      width: 45,
    },
    bookedItem: {
      backgroundColor: Color.dimgray_100,
    },
    selectedText: {
      textAlign: "center",
      color: Color.white,
    },
    selectedFrame: {
      backgroundColor: Color.deeppink,
    },
    availableFrame: {
      backgroundColor: Color.grayD9D9D9,
    },
    a: {
      color: Color.gray555,
      textAlign: "center",
    },
    containerSeats: {
      marginTop: 50,
    },
    btnConfirm: {
      width: 296,
      marginTop: 74,
      backgroundColor: Color.steelblue_200,
      paddingVertical: Padding.p_base,
      paddingHorizontal: Padding.p_85xl,
      borderRadius: Border.br_5xs,
    }
});


export default SeatMap;
