import * as React from "react";
import { Text, StyleSheet, View, Image } from "react-native";
import { FontSize, Color, FontFamily, Border, Padding, LocalizedString } from "@common";
import { useSelector } from 'react-redux';
import { CheckBox, Icon } from '@rneui/themed';
import { DateTime } from 'luxon';


const TicketDetailsSection = ({selectedTrip,fromSelectedStation,toSelectedStation
  ,seatString,selectedDate,contactNo,contactId,contactEmail,showContactInfo,shouldShowInsuranceCheckBox, includeInsurance, toggleCheckbox, insuranceFee}) => {
    const language = useSelector((state) => state.config.language);

  return (
    <View style={styles.frameParent}>
     
      <View style={[styles.groupParent, styles.parentSpaceBlock]}>
        <Image
          style={styles.frameChild}
          resizeMode="cover"
          source={require("../assets/group-511.png")}
        />
        <Text style={[styles.coachJasaPelangiContainer, styles.fromToSeatTypo]}>
          <Text style={styles.coach}>{LocalizedString.coachName}</Text>
          <Text style={styles.jasaPelangi}>{selectedTrip && selectedTrip.brandName}{`\n`}</Text>
          <Text style={styles.coach}>{LocalizedString.travelTime}</Text>
          <Text style={styles.jasaPelangi}>{selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()} {selectedTrip && selectedTrip.time}</Text>
        </Text>
      </View>
      <View style={styles.detailBox}>
        <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{`${LocalizedString.fromC}:`}</Text>
          <Text
            style={[styles.kprKamparLar, styles.fromToSeatTypo]}
          >{fromSelectedStation.stationName}</Text>
        </View>
        <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{`${LocalizedString.toC}:`}</Text>
          <Text
            style={[styles.kprKamparLar, styles.fromToSeatTypo]}
          >{toSelectedStation.stationName}</Text>
        </View>
        <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{`${LocalizedString.seat}`}</Text>
          <Text
            style={[styles.kprKamparLar, styles.fromToSeatTypo]}
          >{seatString}</Text>
        </View>
        { showContactInfo && <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{LocalizedString.contactNo}</Text>
          <Text
            style={[styles.kprKamparLar, styles.fromToSeatTypo]}
          >{contactNo}</Text>
        </View>}
        { showContactInfo && <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{LocalizedString.finalIcPassportNumber}</Text>
          <View style={[styles.fromToSeatTypo, styles.fromToSeatTypoMargin, styles.textBottom]}>
            <Text
              style={styles.kprKamparLarCombined}
            >{contactId}</Text>
          </View>
        </View>}
        { showContactInfo && contactEmail && <View
          style={[styles.fromToSeatContactNoIcParent, styles.childSpaceBlock]}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{LocalizedString.finalContactEmail}</Text>
          <View style={[styles.fromToSeatTypo, styles.fromToSeatTypoMargin, styles.textBottom]}>
            <Text
              style={styles.kprKamparLarCombined}
            >{contactEmail}</Text>
          </View>
        </View> }
      </View>
      {shouldShowInsuranceCheckBox && <CheckBox size={24} 
          title={LocalizedString.formatString(LocalizedString.includeInsurance,{price:insuranceFee})}
          checked={includeInsurance}
          onPress={toggleCheckbox != undefined ? toggleCheckbox : undefined}          
        />}
    </View>
  );
};

const styles = StyleSheet.create({
  detailBox: {
    marginTop: 21,
    paddingLeft: 10,
    paddingRight: 10,
  },
  parentSpaceBlock: {
    marginTop: 21,
    flexDirection: "row",
  },
  childSpaceBlock: {
    flexDirection: "row",
  },
  fromToSeatTypo: {
    fontSize: FontSize.size_sm,
    textAlign: "left",
    lineHeight: 24,
    //width: '100%',
    flex: 1,
  },
  fromToSeatTypoMargin: {
    marginLeft: 5,
  },
  textBottom: {
    justifyContent: 'flex-end',
  },
  ticketDetails: {
    fontSize: FontSize.size_xl,
    color: Color.steelblue_100,
    width: 203,
    textAlign: "left",
    lineHeight: 24,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
  },
  ticketDetailsWrapper: {
    width: 290,
    flexDirection: "row",
    alignItems: "center",
  },
  frameChild: {
    width: 38,
    height: 38,
  },
  coach: {
    color: Color.black,
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    fontSize: FontSize.size_sm,
  },
  groupParent: {
    justifyContent: "center",
    alignItems: "center",
  },
  fromToSeat: {
    color: Color.black,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    fontSize: FontSize.size_sm,
  },
  kprKamparLar: {
    fontFamily: FontFamily.interRegular,
    marginLeft: 5,
    color: Color.black,
  },
  kprKamparLarCombined: {
    fontFamily: FontFamily.interRegular,
    color: Color.black,
    fontSize: FontSize.size_sm,
    textAlign: "left",
    lineHeight: 24,
  },
  fromToSeatContactNoIcParent: {
    minWidth: 295,
    //width: 295,
  },
  frameParent: {
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    //width: 330,
    paddingTop: Padding.p_base,
    paddingBottom: Padding.p_5xl,
    paddingLeft: 5,
    paddingRight: 5,
    alignItems: "center",
  },
});

export default TicketDetailsSection;
