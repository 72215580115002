import { Image, View, Text, StyleSheet } from "react-native";
import React from "react";
import { Color, FontFamily, FontSize, Padding, Border, LocalizedString } from "@common";

const PassengerSummary = ({ type, name, id, seat, fare }) => {
    const passengerIcons = {
        adult: require("../assets/standing-man-1.png"),
        child: require("../assets/elderly-person-1.png"),
        senior: require("../assets/elderly-person-11.png"),
        oku: require("../assets/wheelchair-1.png")
    }

    var iconAssetPath = "";
    var actualFare = "0";
    //console.log(type)
    switch (type) {
        case "Adult":
            iconAssetPath = passengerIcons.adult;
            actualFare = fare.adult;
            break;
        case "Child":
            iconAssetPath = passengerIcons.child;
            actualFare = fare.child;
            break;
        case "Senior":
            iconAssetPath = passengerIcons.senior;
            actualFare = fare.senior;
            break;
        case "OKU":
            iconAssetPath = passengerIcons.oku;
            actualFare = fare.disabled;
            break;
        default:
            iconAssetPath = passengerIcons.adult;
            actualFare = fare.adult;
    }

    return type !== '' && (
      <View key={id} style={styles.quantityFlexBox}>
        <View style={[styles.quantity, styles.quantityLayout]}>
        <Image
            style={styles.standingMan1Icon}
            resizeMode="cover"
            source={iconAssetPath}
        />
        </View>
        <View style={styles.adultParent}>
          <Text style={[styles.passengerInfo, styles.xTypo]}>{name}</Text>
          <Text style={[styles.passengerInfo, styles.xTypo]}>{id}</Text>
          <Text style={[styles.passengerInfo, styles.xTypo]}>{LocalizedString.seat} {seat}</Text>
          <Text style={styles.adult}>{type}</Text>
        </View>
        <Text style={[styles.text, styles.textTypo]}>{fare.currency} {actualFare.toFixed(2)}</Text>
      </View>
    );
  };

const styles = StyleSheet.create({
    quantityFlexBox: {
      width: '100%',
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    quantityLayout: {
      alignItems: "center",
      height: 31,
      width: 39,
      marginLeft: 10
    },
    xTypo: {
      fontSize: FontSize.size_sm,
      color: Color.black,
    },
    adultParent: {
      flexGrow: 4,
      marginLeft: 14,
      justifyContent: "center",
    },
    passengerInfo: {
        textAlign: "left",
        fontFamily: FontFamily.interMedium,
        fontWeight: "500",
        fontSize: FontSize.size_sm,
    },
    adult: {
      textAlign: "left",
      fontFamily: FontFamily.interMedium,
      color: Color.gray555,
      fontSize: FontSize.size_sm,
    },
    rm5500: {
      fontSize: FontSize.size_xs,
      color: Color.steelblue_200,
      width: 105,
      marginTop: 4,
      textAlign: "left",
    },
    textTypo: {
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
    },
    text: {
      marginLeft: 14,
      marginRight: 10,
      fontSize: FontSize.size_sm,
      color: Color.black,
      textAlign: "right",
    },
    result01Child: {
      borderColor: "#e6e8e7",
      marginTop: 16,
    },
    standingMan1Icon: {
        width: 31,
        height: 31,
        overflow: "hidden",
    },
  });
  

  export default PassengerSummary;