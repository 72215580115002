import React, { useState, useEffect } from 'react';
import { TextInput, View, StyleSheet, Platform, ScrollView } from "react-native";
import { Border, FontSize, FontFamily, Color, Padding, LocalizedString, CommonStyle } from "@common";
import { createAction } from '@utils';
import { FlatList } from "native-base";
import RouteItem from "./RouteItem";

const DestinationDropdown = ({
    dispatch,
    destinationRoutes,
    onToItemPress
}) => {
    const [keyword, setKeyword] = useState('');
    const [routes, setRoutes] = useState(destinationRoutes);

    const renderToRouteItem = ({ item }) => (
        <RouteItem item={item} onItemPress={onToItemPress} />
    )

    const onChangeText = (text) => {
        setKeyword(text)
        if (keyword === undefined || keyword === '') {
            setRoutes(destinationRoutes)
        }
        else {
            setRoutes(destinationRoutes.filter((route) => route.stationName.toLowerCase().includes(keyword.toLowerCase())))
        }
    }

    return (
        <ScrollView>
            <View>
                <View style={styles.textbox}>
                    <TextInput
                        style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                        value={keyword}
                        placeholderTextColor={Color.lightslategray}
                        onChangeText={onChangeText}
                        placeholder={LocalizedString.enterKeyword}
                    />
                </View>
                <FlatList
                    data={routes}
                    renderItem={renderToRouteItem}
                    keyExtractor={(item, index) => index.toString()}
                    contentContainerStyle={styles.bookingsList}                     
                    onEndReachedThreshold={0.1}
                />
            </View>
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    textbox: {
        paddingBottom: Padding.p_5xl,
        paddingLeft: 10,
        paddingTop: Padding.p_base,    
        shadowOpacity: 1,
        elevation: 10,
        shadowRadius: 10,
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowColor: "rgba(89, 27, 27, 0.05)",
        backgroundColor: Color.white,
        borderRadius: Border.br_base,
        alignItems: "left",
        marginBottom: 10
    }
})

export default DestinationDropdown