import React from 'react';
import { WebView } from 'react-native-webview';
import { useNavigation } from '@react-navigation/native';

const HTMLScreen = ({ route }) => {
  const navigation = useNavigation();
  const { uri, title } = route.params;
  
  navigation.setOptions({ title: title || 'Webview' });
  //console.log(`uri ${uri}`)
  if (uri != null) {

    return (
      <WebView
        originWhitelist={['*']}
        source={{ uri: uri }}
      />
    )
  }
  return (
    <WebView
      originWhitelist={['*']}
      source={{ html: htmlContent }}
    />
  );
};

export default HTMLScreen;
