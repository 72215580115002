import React from 'react';
import {create} from 'dva-core-ts';
import {Provider} from 'react-redux';
import {MainNavigator} from '@navigation';
import {registerModels} from '@redux';

import { applyMiddleware } from 'redux'; 
import thunk from 'redux-thunk';


// 创建dva实例，可传递配置参数。
// const app = create({onAction: applyMiddleware(thunk) });
const app = create({
  extraEnhancers: [applyMiddleware(thunk)]
});
registerModels(app);

app.start();

const store = app._store;

export default function Main() {
  return (
    <Provider store={store}>
      <MainNavigator />
      
    </Provider>
  );
}
