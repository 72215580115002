import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet ,Image} from 'react-native';
import { Color, LocalizedString,Border,Padding, CommonStyle , FooterHeight} from '@common';
import { DateTime } from "luxon";

const DesktopPaymentFooter = ({buttonName,buttonPressed, cancelName, cancelButtonPressed, grandTotal}) => {
  return (
    <View style={styles.navBar}>
      <View style={styles.container}>
        <Text style={[CommonStyle.title,styles.title]}>{grandTotal}</Text>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
                style={styles.cancelButton}
                onPress={cancelButtonPressed}
              >
            <Text style={styles.cancelButtonText}>{cancelName}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.buttonSmall1}
            onPress={buttonPressed}
          >
            <Text style={styles.buttonText}>{buttonName}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navBar:{
    flexDirection: "row",
    width: '100%',
    height: FooterHeight.payment,
    backgroundColor: Color.white,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container:{
    width: 1000,
    flexDirection: "row",
    backgroundColor: Color.white,
    alignItems: 'center',
    justifyContent: 'space-between', // Changes here
  },
  title:{
    width: '100%',
    textAlign: 'right',
    marginRight: 30,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  buttonSmall1: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,    
    height: 60,
    width: 150,
    flexDirection: "row",
    paddingHorizontal: Padding.p_21xl,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8, // Optional spacing between the buttons
  },
  cancelButton: {
    borderRadius: Border.br_5xs,
    borderColor: Color.red,
    borderWidth: 1,
    backgroundColor: Color.white,
    height: 60,
    width: 150,
    flexDirection: "row",
    paddingHorizontal: Padding.p_21xl,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 8, // Optional spacing between the buttons
  },
  buttonText:{
    color: Color.white,
  },
  cancelButtonText:{
    color: Color.red,
  }
});


export default DesktopPaymentFooter;
