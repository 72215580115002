import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View, Text, TouchableOpacity, ScrollView, Platform, ImageBackground} from "react-native";
import { FontFamily, Padding, Color, FontSize, Border, LocalizedString } from "@common";
import { useDispatch, useSelector } from "react-redux";
import {createAction} from '@utils';
import DesktopFooter from '../components/DesktopFooter';
import { DateTime } from 'luxon';
import {DUMMY} from "@constants"

const Home = ({navigation}) => {

  const [_, forceUpdate] = useState();
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const selectedDate = useSelector((state) => state.trip.selectedDate);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const language = useSelector((state) => state.config.language);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(createAction('trip/loadGetRoute')({}));
    LocalizedString.setLanguage(language);
    forceUpdate({});
  }, [dispatch]);

  const onFromStationPressed = () => {
    navigation.navigate("ChooseRoute",{direction:'from'});
  }

  const switchDirectionPressed = () => {
    dispatch(createAction('trip/setToSelectedStation')(fromSelectedStation));
    dispatch(createAction('trip/setFromSelectedStation')(toSelectedStation));    
  }

  const searchButtonPressed = () => {


    if (fromSelectedStation === null || toSelectedStation === null || selectedDate === null) {

      alert(LocalizedString.pleaseSelectFromToAndDate);
      return;
    }

    dispatch(createAction('trip/resetTripInfo')({}));
     navigation.navigate("SearchResult")
  }

  const onToStationPressed = () => {
    navigation.navigate("ChooseRoute",{direction:'to'});
  }

  const onCalendarPressed = () => {    

    navigation.navigate("DateSection");
  }

  let searchButtonStyle = toSelectedStation!=null && fromSelectedStation!=null && selectedDate!=null ? [styles.buttonSearch, styles.buttonSearchBg] : [styles.buttonSearch, styles.buttonSearchBg, styles.disabled];

  return (
    <ScrollView contentContainerStyle={styles.home}>
  {DUMMY ?
        <ImageBackground source={require('../assets/landing_page.jpg') } style={styles.container}>
        <View style={styles.horizontal1}>                                
        <Text style={styles.titleText}>{LocalizedString.onlineTicketPurchasing}</Text>                
      </View>     
      </ImageBackground>
    :
      <View style={styles.container}>
        <View>
          <View>
            <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
              <View style={styles.fromWrapper}>
                <Text style={[styles.from, styles.fromTypo]}>{LocalizedString.from}</Text>
              </View>
            </View>
            <TouchableOpacity
              style={[styles.containerInputFrom, styles.detailsWrapperBorder]}
            >
              <View style={[styles.details, styles.detailsSpaceBlock]}>
                <Image
                  style={styles.at21}
                  resizeMode="cover"
                  source={require("../assets/20230728-at-2-1.png")}
                />
                <View style={[styles.kamparWrapper, styles.wrapperSpaceBlock]}>
                  <Text style={[styles.kampar, styles.home1Typo]}>{fromSelectedStation != null ? fromSelectedStation.stationName : "" }</Text>
                </View>
              </View>
              <View style={styles.larWrapperSpaceBlock}>
                <Text style={styles.kampar1}>{fromSelectedStation != null ? fromSelectedStation.stationCode : "" }</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.containerTo}>
            <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
              <View style={styles.fromWrapper}>
                <Text style={[styles.from, styles.fromTypo]}>{LocalizedString.to}</Text>
              </View>
            </View>
            <TouchableOpacity onPress={onToStationPressed}
              style={[styles.containerInputFrom, styles.detailsWrapperBorder]}
            >
              <View style={[styles.details1, styles.detailsSpaceBlock]}>
                <Image
                  style={styles.at22}
                  resizeMode="cover"
                  source={require("../assets/20230728-at-2-2.png")}
                />
                <View style={[styles.kamparWrapper, styles.wrapperSpaceBlock]}>
                  <Text style={[styles.kampar, styles.home1Typo]}>
                    {toSelectedStation != null ? toSelectedStation.stationName : "" }
                  </Text>
                </View>
              </View>
              <View style={[styles.larWrapper, styles.larWrapperSpaceBlock]}>
                <Text style={styles.kampar1}>{toSelectedStation != null ? toSelectedStation.stationCode : "" }</Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.departureReturn}>
            <TouchableOpacity onPress={onCalendarPressed} >
              <View style={[styles.fromFrame, styles.detailsSpaceBlock]}>
                <View style={styles.fromWrapper}>
                  <Text style={[styles.departure1, styles.bookingTypo]}>
                    {LocalizedString.departure}
                  </Text>
                </View>
              </View>
              <View
                style={[styles.detailsWrapper, styles.detailsWrapperBorder]}
              >
                <View style={styles.details2}>
                  <Image
                    style={styles.calenderIcon}
                    resizeMode="cover"
                    source={require("../assets/calender1.png")}
                  />
                  <View style={[styles.wrapper, styles.wrapperSpaceBlock]}>
                    <Text style={[styles.text, styles.textLayout]}>
                      {selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()}
                    </Text>
                  </View>
                </View>
              </View>
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={searchButtonStyle}
            onPress={searchButtonPressed}            
          >
            {/* disabled={toSelectedStation!=null && fromSelectedStation!=null && selectedDate!=null ? false : true} */}
            <Text style={[styles.confirm, styles.bannerTypo]}>{LocalizedString.search}</Text>
          </TouchableOpacity>
        </View>  
      </View>    

      }
      { Platform.OS === 'web' ? <DesktopFooter isDesktop={isDesktop} navigation={navigation} /> :  undefined }
      
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  headerSpaceBlock: {
    paddingVertical: 0,
    flexDirection: "row",
  },
  titleText:{
    fontSize: 24,
    marginTop: 30,
    color: Color.white,
    backgroundColor:"black",
    textAlign:"center",
    padding:20,
},
  disabled: {
    opacity: 0.65,
  },
  wifiIconLayout: {
    height: 18,
    width: 18,
  },
  headerFlexBox: {
    justifyContent: "center",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
  },
  confirmTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  detailsSpaceBlock: {
    paddingHorizontal: Padding.p_5xs,
    paddingVertical: 0,
  },
  fromTypo: {
    color: Color.gray555,
    lineHeight: 16,
    fontSize: FontSize.size_xs,
    textAlign: "left",
  },
  detailsWrapperBorder: {
    marginTop: -12,
    borderWidth: 1,
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    borderRadius: Border.br_5xs,
    paddingHorizontal: Padding.p_5xs,
    zIndex: 0,
  },
  wrapperSpaceBlock: {
    marginLeft: 12,
    flexDirection: "row",
  },
  home1Typo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  larWrapperSpaceBlock: {
    paddingRight: Padding.p_13xl,
    paddingLeft: Padding.p_21xl,
    borderRadius: Border.br_13xl,
    flexDirection: "row",
  },
  bookingTypo: {
    fontFamily: FontFamily.interLight,
    fontWeight: "300",
  },
  textLayout: {
    lineHeight: 24,
    textAlign: "left",
    color: Color.black,
  },
  buttonSearchBg: {
    backgroundColor: Color.steelblue_200,
    flexDirection: "row",
  },
  bannerTypo: {
    fontSize: FontSize.size_lg,
    lineHeight: 24,
  },
  home1Layout: {
    marginTop: 4,
    height: 12,
    width: 48,
    lineHeight: 12,
    fontSize: FontSize.size_xs,
    textAlign: "center",
  },
  icon: {
    width: 31,
    height: 9,
  },
  wifiIcon: {
    overflow: "hidden",
  },
  frameChild: {
    marginLeft: 3.07,
  },
  batteryIcon: {
    width: 19,
    height: 19,
    marginLeft: 3.07,
    overflow: "hidden",
  },
  androidStatusBars1: {
    flexDirection: "row",
  },
  androidStatusBars2: {
    width: 360,
    paddingHorizontal: Padding.p_base,
    justifyContent: "space-between",
    height: 24,
    paddingVertical: 0,
    alignItems: "center",
  },
  androidStatusBars: {
    paddingTop: Padding.p_5xs,
  },
  leftArrowIcon: {
    display: "none",
    overflow: "hidden",
  },
  bookFlight: {
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    width: 192,
    color: Color.black,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    paddingVertical: 0,
    flexDirection: "row",
  },
  menuIcon: {
    overflow: "hidden",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
    display: "none",
  },
  content: {
    paddingVertical: Padding.p_xs,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    flexDirection: "row",
  },
  topNavBar: {
    position: "absolute",
    top: 0,
    right: 2,
    zIndex: 0,
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.gray_100,
  },
  from: {
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
  },
  fromWrapper: {
    paddingVertical: Padding.p_9xs,
    backgroundColor: Color.white,
    borderRadius: Border.br_5xs,
    paddingHorizontal: Padding.p_5xs,
    flexDirection: "row",
  },
  fromFrame: {
    zIndex: 1,
  },
  at21: {
    width: 44,
    height: 43,
  },
  kampar: {
    fontSize: FontSize.size_base,
    lineHeight: 24,
    textAlign: "left",
    color: Color.black,
  },
  kamparWrapper: {
    flex: 1,
  },
  details: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
  },
  kampar1: {
    fontSize: FontSize.size_3xs,
    color: Color.gray999,
    width: 208,
    textAlign: "left",
    fontFamily: FontFamily.interRegular,
  },
  containerInputFrom: {
    paddingTop: Padding.p_3xs,
    paddingBottom: Padding.p_xs,
  },
  at22: {
    width: 37,
    height: 41,
  },
  arrow: {
    borderRadius: 40,
    padding: Padding.p_5xs,
    borderWidth: 1,
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    marginLeft: 12,
    backgroundColor: Color.white,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  details1: {
    alignSelf: "stretch",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  larWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  containerTo: {
    marginTop: 8,
  },
  departure1: {
    textAlign: "left",
    color: Color.gray555,
    lineHeight: 16,
    fontSize: FontSize.size_xs,
  },
  calenderIcon: {
    width: 20,
    height: 20,
    overflow: "hidden",
  },
  text: {
    fontSize: FontSize.size_sm,
    width: 200,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
  },
  wrapper: {
    width: 200,
  },
  details2: {
    width: 124,
    paddingLeft: Padding.p_5xs,
    paddingRight: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  detailsWrapper: {
    width: 297,
    paddingVertical: Padding.p_3xs,
  },
  departureReturn: {
    marginTop: 8,
    flexDirection: "row",
  },
  confirm: {
    width: 120,
    color: Color.white,
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  buttonSearch: {
    width: 296,
    paddingHorizontal: Padding.p_85xl,
    marginTop: 8,
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    justifyContent: "center",
    paddingVertical: Padding.p_xs,
    alignItems: "center",
  },
  banner: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    textAlign: "left",
    color: Color.black,
  },
  imgBannerIcon: {
    height: 143,
    marginTop: 11,
    width: 358,
  },
  containerBanner: {
    height: 178,
    marginTop: 118,
    width: 358,
    justifyContent: "flex-end",
  },
  container: {
    zIndex: 1,
    width: 358,
    marginTop:20,
    
    alignItems: "center",
    flex: 1,
  },
  home1: {
    color: Color.white,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  menuhome: {
    alignItems: "center",
  },
  booking: {
    color: Color.mistyrose,
    fontFamily: FontFamily.interLight,
    fontWeight: "300",
  },
  menubooking: {
    opacity: 0.65,
    alignItems: "center",
  },

  home: {
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: Color.gray_100,
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
  },
});

export default Home;
