import React from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { Color, FontFamily, Padding, FontSize, Border, LocalizedString } from "@common";

const MobileTripItem = ({ item, handleItemPress }) => {
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.result01}>
          <View style={styles.groupParent}>
            <Image
              style={styles.frameItem}
              resizeMode="cover"
              source={require("../assets/group-44.png")}
            />
            <View style={styles.jasaPelangiParent}>
              <Text style={styles.jasaPelangi}>{item.brandName}</Text>
              <Text style={[styles.standard, styles.standardSpaceBlock]}>{item.coachClass}</Text>
              <Text style={[styles.seatsAvailable, styles.standardSpaceBlock]}>{item.vac} {LocalizedString.seatsAvailable}</Text>
            </View>
          </View>
          <View style={[styles.line, styles.childLayout]} />
          <View style={styles.frameParent}>
            <Text style={[styles.am, styles.amTypo]}>{item.time}</Text>
            <View style={styles.rm5500Wrapper}>
              <Text style={[styles.rm5500, styles.amTypo]}>{item.currency} {item.fare.toFixed(2)}</Text>
            </View>
          </View>
          <TouchableOpacity
            style={styles.buttonBig}
            onPress={() => handleItemPress(item)}
          >
            <Text style={[styles.confirm, styles.confirmTypo]}>Select</Text>
          </TouchableOpacity>
        </View>
        <View style={[styles.rowResultChild, styles.childLayout]} />
      </View>
    </View>
  );
};

// Your styles will go here, just copied them from your renderItem function
const styles = StyleSheet.create({  
    standardSpaceBlock: {
      marginTop: 4,
      textAlign: "left",
    },
    childLayout: {
      height: 1,
      width: 329,
      borderTopWidth: 1,
      borderColor: "#e6e8e7",
      borderStyle: "solid",
      marginTop: 16,
    },
    amTypo: {
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
      fontSize: FontSize.size_base,
      lineHeight: 24,
      color: Color.black,
    },
    frameItem: {
      width: 76,
      height: 76,
    },
    jasaPelangi: {
      fontWeight: "700",
      fontFamily: FontFamily.interBold,
      color: Color.steelblue_100,
      width: 203,
      textAlign: "left",
      lineHeight: 24,
      fontSize: FontSize.size_xl,
    },
    standard: {
      fontSize: FontSize.size_sm,
      color: Color.black,
      fontFamily: FontFamily.interMedium,
      fontWeight: "500",
      marginTop: 4,
    },
    seatsAvailable: {
      fontSize: FontSize.size_xs,
      fontWeight: "300",
      fontFamily: FontFamily.interLight,
      color: Color.dimgray_200,
      width: 105,
    },
    jasaPelangiParent: {
      marginLeft: 14,
      justifyContent: "center",
    },
    groupParent: {
      width: 289,
      flexDirection: "row",
      alignItems: "center",
    },
    line: {
      opacity: 0.3,
      marginTop: 16,
    },
    am: {
      width: 85,
      textAlign: "left",
    },
    rm5500: {
      textAlign: "right",
      width: 112,
    },
    rm5500Wrapper: {
      width: 119,
      marginLeft: 103,
      flexDirection: "row",
      alignItems: "center",
    },
    frameParent: {
      marginTop: 16,
      flexDirection: "row",
      alignItems: "center",
    },
    confirm: {
      fontSize: FontSize.size_lg,
      color: Color.white,
      width: 120,
      lineHeight: 24,
    },
    confirmTypo: {
      textAlign: "center",
      fontFamily: FontFamily.interMedium,
      fontWeight: "500",
    },
    buttonBig: {
      borderRadius: Border.br_5xs,
      backgroundColor: Color.steelblue_200,
      width: 296,
      paddingHorizontal: Padding.p_85xl,
      paddingVertical: Padding.p_5xs,
      marginTop: 16,
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
    },
    result01: {
      borderRadius: Border.br_base,
      backgroundColor: Color.white,
      shadowColor: "rgba(89, 27, 27, 0.05)",
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowRadius: 10,
      shadowOpacity: 1,
      paddingHorizontal: 0,
      paddingVertical: Padding.p_base,
      alignItems: "center",
    },
    rowResultChild: {
      marginTop: 16,
    },
    container: {        
      marginTop: 24,
      alignItems: "center",
    },
  });
  
export default MobileTripItem;
