import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Image, Platform } from 'react-native';
import { Color, CommonStyle, LocalizedString } from '@common';

import { Desktop,createAction } from '@utils';
import { Dialog } from "@rneui/themed";
import DesktopLoginModal from './DesktopLoginModal';
import { ScrollView } from 'react-native-gesture-handler';

const DesktopWrapper = ({ navigation,footer,navbar,memberId,dispatch, showSetting,setShowSetting, ...props }) => {  // Capture any props passed to DesktopWrapper
    const [logoutVisible, setLogoutVisible] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [settingModalVisible, setSettingModalVisible] = useState(false);


    useEffect(() => {
      if (showSetting) {
        // Call your method here if showSetting is true
        settingPressed();
      }
    }, [showSetting]);


    const logoutPressed = () => {

    if (memberId == null) {
      dispatch(createAction('member/destroyUser')());
    }else{
      setLogoutVisible(true);
    }    
  }

  const settingPressed = () => {
    //console.log(`Setting button pressed`);
    setSettingModalVisible(true);
  }

  const renderContainer = (newNavbar) => {

    return (
      <>
    <Desktop>
    {newNavbar}
    </Desktop>
    {props.children}
    <Desktop>
      <DesktopLoginModal modalVisible={settingModalVisible} setModalVisible={setSettingModalVisible} setShowSetting={setShowSetting} navigation={navigation} />
      <Dialog 
      overlayStyle={styles.dialog}
      isVisible={logoutVisible}
      onBackdropPress={confirmLogoutPressed}
    >
      <Dialog.Title title={LocalizedString.confirmLogout}/>
      
      <Dialog.Actions>
        <Dialog.Button
          title={LocalizedString.confirm}
          onPress={confirmLogoutPressed}
        />
        <Dialog.Button title={LocalizedString.cancel} onPress={()=> setLogoutVisible(false)} />
      </Dialog.Actions>
    </Dialog>
      <Dialog overlayStyle={styles.dialog} isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>

    </Desktop>
    </>
    )
  }

  const confirmLogoutPressed = () => {
    
    setLogoutVisible(false);
    setLoading(true);

    dispatch(createAction('member/loadLogout')({"memberId": memberId}));
    setTimeout(() => {
      setLoading(false);
     
    }, 1000);
    navigation.navigate("DesktopHome");
  }

  const newNavbar = React.cloneElement(navbar, { settingPressed: settingPressed, logoutPressed: logoutPressed, memberId: memberId });

  if (Platform.OS === 'web') {
    return (
      <ScrollView style={styles.scrollView}> 
             <View style={styles.home} {...props}> 
          {renderContainer(newNavbar)}          
      </View>
      {footer != null ? <View style={CommonStyle.withDesktopFooter}></View> : null}
      {footer != null ? footer : null}
      </ScrollView>
    );
  }else{
    return (
      <View style={styles.home} {...props}> 
        {renderContainer(newNavbar)}
      </View>
    );
  }
  
};

const styles = StyleSheet.create({
    dialog:{
        maxWidth: 300,
    },
    scrollView:{
      width: "100%",
      height: "100%",
      
    },
    home: {
        width: "100%",
        backgroundColor: Color.white,     
        minHeight: 1000, 
      },
      navBar:{
        flexDirection: "row",
        width: '100%',
        height: 70,
        backgroundColor: Color.steelblue_100,
        paddingHorizontal: 20,
        position: 'absolute',
        bottom: 0,
      },
  // your styles here
});

export default DesktopWrapper;
