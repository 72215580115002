import React, { useState, useEffect } from 'react';
import { Image, StyleSheet, View, Pressable, Text, ScrollView, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Padding, FontSize, FontFamily, Color, Border } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";
import BtnLanguage from "../components/BtnLanguage";
import {useDispatch, useSelector} from 'react-redux';
import { LocalizedString } from "@common";
import { Dialog } from "@rneui/themed";
import DesktopFooter from '../components/DesktopFooter';
import { createAction } from '@utils';
const Settings = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const navigation = useNavigation();
  const language = useSelector((state) => state.config.language);
  const [_, forceUpdate] = useState();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const authToken = useSelector((state) => state.member.authToken);
  
  const memberId = useSelector((state) => state.member.memberId);

  const contactUsPressed = () => {
    navigation.navigate('ContactUs');
  }

  const changePasswordPressed = () => {
    //console.log(`Skip button pressed`);
    navigation.navigate('ResetPassword');
  }

  const myProfilePressed = () => {
    //console.log(`Skip button pressed`);
    navigation.navigate('MyProfile');
  }
 
  const privacyPolicyPressed = () => {
    navigation.navigate('PrivacyPolicy');
  }

  const generalTicketingPolicyPressed = () => {
    navigation.navigate('Policy');
  }

  const policyPressed = () => {
    navigation.navigate('Policy');
  }

  const termsOfUsePressed = () => {
    navigation.navigate('Term');
  }

  const faqPressed = () => {
    navigation.navigate('Faq');
  }

  const logoutPressed = () => {

    if (memberId == null) {
      dispatch(createAction('member/destroyUser')());

      if (Platform.OS === 'web' && !isDesktop) {
        navigation.navigate('MobileHome')
      }
    }else{
      setLogoutVisible(true);
    }


  }

  const linksPressed = () => {
    navigation.navigate('Important Links');
  }


  const confirmLogoutPressed = () => {
    
    setLogoutVisible(false);
    setLoading(true);

    dispatch(createAction('member/loadLogout')({"memberId": memberId}));
    setTimeout(() => {
      setLoading(false);
      if (Platform.OS === 'web' && !isDesktop) {
        navigation.navigate('MobileHome')
      }
    }, 1000);
  }

  const languageSelected = (language) => {
    
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render  
};
  
  return (
    <ScrollView contentContainerStyle={styles.settings}>
      <View style={styles.container}>   
        <View style={styles.table}>
          {authToken && <TouchableOpacity style={styles.rowChangePassword} onPress={changePasswordPressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.changePassword}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>}
          {/* <View style={styles.rowLanguage}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.language}</Text>
                </View>
                <BtnLanguage onSelectLanguage={languageSelected} language={language} />
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </View> */}
          {authToken && <TouchableOpacity style={styles.rowChangePassword} onPress={myProfilePressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.myProfile}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>}    
          {authToken && <TouchableOpacity style={styles.rowChangePassword} onPress={logoutPressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.logout}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>}
          {/* <TouchableOpacity style={styles.rowChangePassword} onPress={contactUsPressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.contactUs}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>           
          <TouchableOpacity style={styles.rowChangePassword} onPress={faqPressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.frequentlyAskedQuestions}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>    */}

          { Platform.OS === 'web' ? undefined : 
            <TouchableOpacity style={styles.rowChangePassword} onPress={linksPressed}>
            <View style={styles.resultShadowBox}>
              <View style={styles.result01Inner}>
                <View style={styles.changePasswordWrapper}>
                  <Text style={styles.changePassword}>{LocalizedString.important_links}</Text>
                </View>
              </View>
              <View style={[styles.result01Child, styles.childLayout]} />
            </View>
            <View style={[styles.rowChangePasswordChild, styles.childLayout]} />
          </TouchableOpacity>      
          }
          
        </View>
      </View>    
      <Dialog
      isVisible={logoutVisible}
      onBackdropPress={confirmLogoutPressed}
    >
      <Dialog.Title title={LocalizedString.confirmLogout}/>
      
      <Dialog.Actions>
        <Dialog.Button
          title={LocalizedString.confirm}
          onPress={confirmLogoutPressed}
        />
        <Dialog.Button title={LocalizedString.cancel} onPress={()=> setLogoutVisible(false)} />
      </Dialog.Actions>
    </Dialog>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
          
      {Platform.OS === 'web' ?      
         <DesktopFooter navigation={navigation} isDesktop={isDesktop} />     
      : undefined }
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  wifiIconLayout: {
    height: 18,
    width: 18,
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  childLayout: {
    height: 1,
    width: 329,    
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    marginTop: 16,
  },
  bgBmFlexBox: {
    alignItems: "flex-end",
    paddingHorizontal: Padding.p_8xs,
    width: 30,
    height: 28,
    justifyContent: "center",
    paddingVertical: 0,
    overflow: "hidden",
  },
  enTypo: {
    fontSize: FontSize.size_xs,
    textAlign: "left",
    lineHeight: 24,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  icon: {
    width: 31,
    height: 9,
  },   
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    justifyContent: "center",
    paddingHorizontal: Padding.p_base,
    flexDirection: "row",
    alignItems: "center",
  },
  changePassword: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    width: 203,
    textAlign: "left",
    lineHeight: 24,
    color: Color.black,
    fontSize: FontSize.size_xl,
  },
  changePasswordWrapper: {
    justifyContent: "center",
  },
  result01Inner: {
    width: 289,
    flexDirection: "row",
    alignItems: "center",
  },
  result01Child: {
    opacity: 0.3,
    marginTop: 16,
  },
  resultShadowBox: {
    paddingVertical: Padding.p_base,
    paddingHorizontal: 0,
    shadowOpacity: 1,
    elevation: 10,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowColor: "rgba(89, 27, 27, 0.05)",
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    alignItems: "center",
  },
  rowChangePasswordChild: {
    marginTop: 16,
  },
  rowChangePassword: {
    justifyContent: "center",
    alignItems: "center",
  },
  en: {
    color: Color.white,
  },
  bgEng: {
    backgroundColor: Color.steelblue_200,
  },
  bm: {
    color: Color.black,
  },
  bgBm: {
    marginLeft: 8,
    backgroundColor: Color.white,
    paddingHorizontal: Padding.p_8xs,
    width: 30,
  },
  btnLanguage: {
    width: 80,
    marginLeft: 14,
    height: 28,
    flexDirection: "row",
    alignItems: "center",
  },
  rowLanguage: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  table: {    
    justifyContent: "center",
    alignItems: "center",
    marginTop: 32,
  },
  container: {    
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
  },
  settings: {
    width: "100%",    
    alignItems: "center",
    overflow: "hidden",
    flex: 1,
    backgroundColor: Color.gray_100,
  },
});

export default Settings;
