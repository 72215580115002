import * as React from "react";
import { Image, StyleSheet, Text, Touchable, View } from "react-native";

import {Calendar} from 'react-native-calendars';
import {DateTime} from 'luxon';
import {createAction} from '@utils';
import { Color, windowWidth } from "@common";

const CalendarView = ({dispatch,calendarPressed,selectedDate}) => {
    const currentDate = DateTime.now().setZone('Asia/Singapore').toISODate();
    const current = selectedDate && DateTime.fromFormat(selectedDate, 'yyyy-MM-dd').toISODate() || DateTime.now().setZone('Asia/Singapore').toISODate();
  
    return (
      <View style={[styles.dateSection]}>     
          <Calendar
           theme={{
            arrowWidth: 40,
            arrowHeight: 40,
          }}
            style={styles.calendar}
            minDate={currentDate}
            current={current}
            onDayPress={day => {
              dispatch(createAction('trip/setSelectedDate')(day.dateString));
              calendarPressed(day.dateString)
            //   navigation.goBack()
            }}
            
          />
      </View>
    );
};

const styles = StyleSheet.create({
 
    dateSection: {  
        flex: 1,
        width: "100%", 
        alignItems: "center",
        backgroundColor: Color.gray_100,
        overflow: "hidden",
      },
      calendar: {
        width: 300,
        paddingRight: 15,
        paddingLeft: 15,
        paddingHorizontal: 0,  // Remove any default padding if necessary
      }
});

export default CalendarView;
