import * as React from "react";
import { Image, StyleSheet, Text, Touchable, View } from "react-native";
import { Color, FontFamily, FontSize, Border, Padding, LocalizedString } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";

const ChooseTicketQuantityForm = ({
  currency,adultPrice,childPrice,okuPrice,seniorPrice, 
  adultQuantity,childQuantity, okuQuantity, seniorQuantity,
  adultButtonPressed,childButtonPressed,okuButtonPressed,seniorButtonPressed
}) => {
  return (
    <View style={styles.searchResult}>
      <View style={styles.result01}>
        <View style={styles.parentFlexBox}>
          <Image
            style={styles.standingMan1Icon}
            resizeMode="cover"
            source={require("../assets/standing-man-1.png")}
          />
          <View style={styles.adultParent}>
            <Text style={[styles.adult, styles.textFlexBox]}>{LocalizedString.adult}</Text>
            <Text style={[styles.rm5500, styles.rm5500SpaceBlock]}>
              {currency} {adultPrice.toFixed(2)}
            </Text>
          </View>
          <TouchableOpacity onPress={adultButtonPressed.bind(this,true)}>
          <Image
            style={styles.iconPlus}
            resizeMode="cover"
            source={require("../assets/icon-plus.png")}
          />
          </TouchableOpacity>
          <Text style={[styles.text, styles.textTypo]}>{adultQuantity}</Text>
          <TouchableOpacity onPress={adultButtonPressed.bind(this,false)}>
          <Image
            style={styles.iconPlus}
            resizeMode="cover"
            source={require("../assets/icon-minus.png")}
          />
          </TouchableOpacity>
        </View>
        <View style={[styles.result01Child, styles.childLayout]} />
        <View style={[styles.elderlyPerson1Parent, styles.parentFlexBox]}>
          <Image
            style={styles.standingMan1Icon}
            resizeMode="cover"
            source={require("../assets/elderly-person-1.png")}
          />
          <View style={styles.adultParent}>
            <Text style={[styles.adult, styles.textFlexBox]}>{LocalizedString.child}</Text>
            <Text style={[styles.to12Years, styles.rm5500SpaceBlock]}>
              3 to 12 years old
            </Text>
            <Text style={[styles.rm5500, styles.rm5500SpaceBlock]}>
            {currency} {childPrice.toFixed(2)}
            </Text>
          </View>
          <TouchableOpacity onPress={childButtonPressed.bind(this,true)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-plus.png")}
            />
          </TouchableOpacity>
          <Text style={[styles.text, styles.textTypo]}>{childQuantity}</Text>
          <TouchableOpacity onPress={childButtonPressed.bind(this,false)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-minus.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={[styles.result01Child, styles.childLayout]} />
        <View style={[styles.elderlyPerson1Parent, styles.parentFlexBox]}>
          <Image
            style={styles.standingMan1Icon}
            resizeMode="cover"
            source={require("../assets/elderly-person-11.png")}
          />
          <View style={styles.adultParent}>
            <Text style={[styles.adult, styles.textFlexBox]}>{LocalizedString.senior}</Text>
            <Text style={[styles.rm5500, styles.rm5500SpaceBlock]}>
              {currency} {seniorPrice.toFixed(2)}
            </Text>
          </View>
          <TouchableOpacity onPress={seniorButtonPressed.bind(this,true)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-plus.png")}
            />
          </TouchableOpacity>
          <Text style={[styles.text, styles.textTypo]}>{seniorQuantity}</Text>
          <TouchableOpacity onPress={seniorButtonPressed.bind(this,false)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-minus.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={[styles.elderlyPerson1Parent, styles.parentFlexBox]}>
          <Image
            style={styles.standingMan1Icon}
            resizeMode="cover"
            source={require("../assets/wheelchair-1.png")}
          />
          <View style={styles.adultParent}>
            <Text style={[styles.adult, styles.textFlexBox]}>{LocalizedString.oku}</Text>
            <Text style={[styles.rm5500, styles.rm5500SpaceBlock]}>
              {currency} {okuPrice.toFixed(2)}
            </Text>
          </View>
          <TouchableOpacity onPress={okuButtonPressed.bind(this,true)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-plus.png")}
            />
          </TouchableOpacity>
          <Text style={[styles.text, styles.textTypo]}>{okuQuantity}</Text>
          <TouchableOpacity onPress={okuButtonPressed.bind(this,false)}>
            <Image
              style={styles.iconPlus}
              resizeMode="cover"
              source={require("../assets/icon-minus.png")}
            />
          </TouchableOpacity>
        </View>
        <View style={[styles.result01Child, styles.childLayout]} />
      </View>
      <View style={[styles.searchResultChild, styles.childLayout]} />
    </View>
  );
};

const styles = StyleSheet.create({
  textFlexBox: {
    textAlign: "left",
    color: Color.black,
  },
  rm5500SpaceBlock: {
    marginTop: 4,
    textAlign: "left",
  },
  textTypo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_xs,
  },
  childLayout: {
    height: 1,
    width: 329,
    borderTopWidth: 1,
    borderColor: "#e6e8e7",
    borderStyle: "solid",
    marginTop: 16,
  },
  parentFlexBox: {
    flexDirection: "row",
    width: 289,
    alignItems: "center",
  },
  standingMan1Icon: {
    width: 50,
    height: 50,
    overflow: "hidden",
  },
  adult: {
    fontSize: FontSize.size_sm,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
  },
  rm5500: {
    color: Color.steelblue_200,
    width: 105,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_xs,
  },
  adultParent: {
    justifyContent: "center",
    marginLeft: 14,
  },
  iconPlus: {
    width: 29,
    height: 29,
    marginLeft: 14,
  },
  text: {
    textAlign: "left",
    color: Color.black,
    marginLeft: 14,
  },
  result01Child: {
    opacity: 0.3,
    marginTop: 16,
  },
  to12Years: {
    fontSize: FontSize.size_3xs,
    fontStyle: "italic",
    fontFamily: FontFamily.interLight,
    color: Color.gray555,
  },
  elderlyPerson1Parent: {
    marginTop: 16,
  },
  result01: {
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingHorizontal: 0,
    paddingVertical: Padding.p_base,
    alignItems: "center",
    height: 326,
  },
  searchResultChild: {
    marginTop: 16,
  },
  searchResult: {
    marginTop: 14,
    height: 326,
  },
});

export default ChooseTicketQuantityForm;
