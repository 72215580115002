import { Linking, Alert, Platform } from 'react-native';

export const callNumber = phone => {
  //console.log('callNumber ----> ', phone);

  if (Platform.OS === 'web') {
    let phoneNumber = `tel:${phone}`;

    window.open(phoneNumber)

    return
  }
  if (Platform.OS === 'android' || Platform.OS === 'ios' ) {
    let phoneNumber = phone;
    if (Platform.OS !== 'android') {
      phoneNumber = `telprompt:${phone}`;
    }
    else  {
      phoneNumber = `tel:${phone}`;
    }
    Linking.canOpenURL(phoneNumber)
    .then(supported => {
      if (!supported) {
        Alert.alert('Phone number is not available');
      } else {
        return Linking.openURL(phoneNumber);
      }
    })
    .catch(err => alert('Can\'t open'));

  }
 
}; 

export const sendEmail = (email,subject,body) => {
  
  const url = `mailto:${email}?subject=${subject}&body=${body}`;

  // if (Platform.OS === 'web') {
  

  //   window.open(url)

  //   return
  // }

  Linking.canOpenURL(url)
    .then((supported) => {
      if (supported) {
        return Linking.openURL(url);
      } else {
        //console.log(`Don't know how to open URL: ${url}`);
      }
    })
    .catch((err) => console.error('An error occurred', err));
};


