import React, { useState } from 'react';
import { Text, StyleSheet, View, Image, TouchableOpacity, TextInput, KeyboardAvoidingView ,ScrollView, Platform} from "react-native";
import { Border, FontSize, FontFamily, Color, Padding, LocalizedString, CommonStyle, windowWidth } from "@common";
import QRCode from 'react-native-qrcode-svg';
import {DateTime} from 'luxon';

const BoardingPass = ({
    isDesktop,companyName,brandName,tripNo,date,time,
    coachNo,coachClass,fromStation,toStation,fromStationCode,toStationCode,fromPlatform,
    name,contactNo,seatNo,ticketNo,insuranceNo,
    currency,fare,facility,other,insurance,type
}) => { 

  let scrollStyle = isDesktop ? styles.desktopScrollView : styles.mobileScrollView
  let dayOfWeek = DateTime.fromFormat(`${date} ${time}`, 'dd-MM-yyyy hh:mm a').toFormat('EEE');
  let floatFare = parseFloat(fare)
  let floatFacility = facility === "-" ? 0 : parseFloat(facility)
  let floatOther = other === "-" ? 0 : parseFloat(other)
  let floatInsurance = insurance === "-" ? 0 : parseFloat(insurance)
  let total = (floatFare + floatFacility + floatOther + floatInsurance).toFixed(2)


  var typeAbbr = ""
  switch (type) {
    case "Adult":
      typeAbbr = "A";
      break;
    case "Senior":
      typeAbbr = "S";
      break;
    case "Child":
      typeAbbr = "C";
      break;
    case "OKU":
      typeAbbr = "O";
      break;
    default:
      typeAbbr = "A";
  }
  
  return (    
    <ScrollView style={[styles.scrollView,scrollStyle]} contentContainerStyle={styles.container} horizontal={true}>
      <View style={styles.boardingPassContainer}>
        <View style={styles.stickyHeader}>
            <Text style={styles.title}>BOARDING PASS</Text>
        </View>
        <View style={styles.header}>
          <Image source={require('../assets/logo-1.png')} style={isDesktop ? styles.logoWeb : styles.logo} resizeMode="contain" />
          <View style={styles.ticketHeaderContainer}>
            <Text style={styles.ticketHeaderText}>{fromStation} ({fromStationCode}) - </Text>
            <Text style={styles.ticketHeaderText}>{toStation} ({toStationCode})</Text>
            <Text style={styles.ticketHeaderText}>{companyName}</Text>
            <Text style={styles.ticketHeaderText}>{date}, {time} ({dayOfWeek})</Text>
          </View>
        </View>

        <View style={styles.detailsContainer}>
          <View style={styles.detailsTextContainer}>
            <Text style={styles.text}>Coach No: {coachNo} ({coachClass})</Text>
            <Text style={styles.text}>Seat No: <strong>{seatNo}</strong></Text>
            <Text style={styles.text}>Ticket No: {ticketNo}</Text>
            <Text style={styles.text}>Name: {name}</Text>
            <Text style={styles.text}>Contact No: {contactNo}</Text>
            <Text style={styles.text}>Fare: <strong>{currency} {fare} ({typeAbbr})</strong></Text>
            <Text style={styles.text}>Premium Package: {currency} {insurance === "-" ? "0.00" : floatInsurance.toFixed(2)} {floatInsurance > 0 && `(${insuranceNo})`}</Text>
            <Text style={styles.text}>Facility Fee: {currency} {facility}</Text>
            <Text style={styles.text}>Other Fee: {currency} {other}</Text>
            <Text style={styles.text}>Total: {currency} {total}</Text>
          </View>
          <View style={isDesktop ? styles.qrcodeContainerWeb: styles.qrcodeContainer}>
            <QRCode value={ticketNo} size={isDesktop ? 100 : 45} />
              {/* <Image source={require('../assets/qrcode.png')} style={styles.qrcode} />      */}
              <Text style={styles.qrcodeText}>Platform {fromPlatform}</Text>   
          </View>
        </View>

      {/* <View style={styles.termsContainer}>
        <Text style={styles.termsTitle}>Terms & Conditions:</Text>
        <Text style={styles.termsText}> 1. Ticket sold cannot be refunded. Amendment will be charged some fee if applicable.{'\n'}
  2. Please be at the bus terminal 20 minutes before your departure time. The bus will not wait. NO refund if you miss the bus.{'\n'}
  3. Management has the right to change the departure schedule without giving prior notice.{'\n'}
  4. Total weight of all luggage cannot exceed 15kg.{'\n'}
  5. No flammable items, dangerous goods, or livestock are allowed.{'\n'}
  6. Please take care of your belongings. Management is not responsible for THEFT/loss and damage of your belongings.{'\n'}
  7. Keep this ticket during the trip to facilitate any checks that may arise.</Text>
      </View> */}

      </View>
    {/* <View style={styles.driverCopy}>
    <Text style={styles.title}>DRIVER COPY</Text>
      <View style={styles.header}>
        <Image source={require('../assets/logo-1.png')} style={styles.logo} />
        
      </View>
    <View style={styles.detailsContainer}>

      <View style={styles.detailsTextContainer}>
     
        <Text style={styles.text}>From: KAMPAR (KPR)</Text>
        <Text style={styles.text}>To: MERSING (MSG)</Text>
        <Text style={styles.text}>Date: 03 Oct 2023, 08:00 PM (Tue)</Text>
        <Text style={styles.text}>Seat No.: 018</Text>
        <Text style={styles.text}>Ticket No.: KPR231001408</Text>
        <Text style={styles.text}>Name: CHAN PENG LIAN</Text>
        <Text style={styles.text}>Contact No.: 0126550560</Text>
        <Text style={styles.text}>Fare: RM 80.00 (A)</Text>
        <Text style={styles.text}>Total: RM 82.00</Text>        
      </View>      
      </View>
      </View> */}
    </ScrollView>

  );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        borderColor: 'black',
        width: '90%',
        borderWidth: 1,
        margin: 20,
        flexDirection:'row',
      },
      boardingPassContainer: {
        flex: 1
      },
      scrollView:{
        flex:1,
      },
      desktopScrollView: {
        width: 700
      },
      mobileScrollView:{
        width: windowWidth
      },
      qrcode:{
        width: 100,
        height: 100,
        marginRight:5,
      },
      qrcodeContainer:{
        alignItems:"center",
        width: 50,
        justifyContent:"center",
      },
      qrcodeContainerWeb: {
        alignItems:"center",
        width: 105,
        justifyContent:"center",
      },
      driverCopy:{
        borderLeftColor:'black',        
        borderLeftWidth:1,
        paddingLeft: 15
      },
      text:{
        fontFamily:FontFamily.interRegular
      },
      qrcodeText:{
        marginTop:10,
        fontFamily:FontFamily.interRegular,
        textAlign:'center'
      },
      header: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      ticketHeaderContainer: {
        marginLeft: 10
      },
      ticketHeaderText: {
        fontFamily: FontFamily.interBold,
        fontSize: FontSize.size_sm,
        fontWeight: "700",
      },
      stickyHeader:{
        width:"100%",
        alignItems:"center",
        marginBottom: 10
      },
      logo: {
        width: 80,
        height: 21,
        marginRight: 5,
      },
      logoWeb: {
        width: 120,
        height: 50,
        marginRight: 10,
      },
      title: {
        fontSize: 16,
        fontFamily:FontFamily.interBold,
      },
      detailsContainer: {
        flexDirection:"row",
        textTransform:"uppercase",
      },
      detailsTextContainer: {
        marginVertical: 20,
        flex: 1,
      },
      termsContainer: {
        marginTop: 10,
        maxWidth:450,
      },
      termsTitle: {
        fontFamily:FontFamily.interRegular,
        fontSize:12,
      },
      termsText: {
        fontWeight: 'normal',
        fontSize:11,
        fontFamily:FontFamily.interRegular
      }
});

export default BoardingPass;