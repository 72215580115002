import React, { useState , useEffect } from 'react';
import { Image, StyleSheet, Text, View, ScrollView } from "react-native";
import { FontFamily, FontSize, Color, Padding, Border,CommonStyle, LocalizedString, windowWidth } from "@common";
import DesktopWrapper from "../components/DesktopWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import {DateTime} from 'luxon';
import DesktopNavbar from "../components/DesktopNavbar";
import DesktopFooter from '../components/DesktopFooter';
import BoardingPass from '../components/BoardingPass';

const BookingInfoWidget = ({booking, date, totalAmount}) => {

  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState('');
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const renderBoardingPass = (ticket) => (
    <BoardingPass key={ticket.ticketNo} isDesktop={isDesktop} {...booking} {...ticket} />
  )

let containerStyle = isDesktop ? styles.containerDesktop : styles.container;

  return (
    <View style={[styles.frame,containerStyle]}>
        <View style={styles.frameParent}>
          <View style={styles.frameGroup}>
            <View style={styles.groupParent}>
              <Image
                style={styles.frameChild}
                resizeMode="cover"
                source={require("../assets/group-511.png")}
              />
              <Text
                style={[
                  styles.coachJasaPelangiContainer,
                  styles.fromToSeatTypo,
                ]}
              >
                <View style={styles.coachInfoDiv}>
                    <View style={styles.coachInfo}>
                        <Text style={styles.coach}>{LocalizedString.coachName}</Text>
                        <Text style={styles.jasaPelangi}>{booking && booking.brandName}</Text>
                    </View>
                    <View style={styles.coachInfo}>
                        <Text style={styles.coach}>{LocalizedString.coachNo}</Text>
                        <Text style={styles.jasaPelangi}>{booking && booking.coachNo} ({booking && booking.coachClass})</Text>
                    </View>
                    <View style={styles.coachInfo}>
                        <Text style={styles.coach}>{LocalizedString.travelTime}</Text>
                        <Text style={styles.jasaPelangi}>{date}</Text>
                    </View>
                </View>
              </Text>
            </View>
            <View style={styles.fromToSeatContactNoIcParent}>
              <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{`${LocalizedString.fromC}:
${LocalizedString.toC}:
${LocalizedString.seat}
${LocalizedString.contactNo}
${LocalizedString.noOfTicket}
${totalAmount && LocalizedString.grandTotal || ''}`}</Text>
              <Text
                style={[styles.kprKamparLar, styles.kprKamparLarTypo]}
              >{booking && `${booking.fromStationCode} (${booking.fromStation})
${booking.toStationCode} (${booking.toStation})
${booking.ticket.map((ticket) => ticket.seatNo).join(',')}
${booking.ticket[0].contactNo}
${booking.ticket.length}
${totalAmount && `RM${totalAmount}` || ''}`}</Text>
            </View>
          </View>
        
          <View>
            {booking && booking.ticket.map((ticket) => renderBoardingPass(ticket))}              
          </View>
        </View>  
      </View>
  );
};

const styles = StyleSheet.create({
  fromToSeatTypo: {
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  kprKamparLarTypo: {
    fontFamily: FontFamily.interRegular,
    color: Color.black,
    textAlign: "left",
  },
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout1: {
    height: 18,
    width: 18,
  },
  leftArrowFlexBox: {
    padding: Padding.p_9xs,
    flexDirection: "row",
    alignItems: "center",
  },
  iconLayout: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  frameChild: {
    width: 38,
    height: 38,
  },
  coach: {
    color: Color.black,
    width: 135
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
  },
  groupParent: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  fromToSeat: {
    color: Color.black,
  },
  kprKamparLar: {
    marginLeft: 21,
    lineHeight: 24,
    fontSize: FontSize.size_sm,
    fontFamily: FontFamily.interRegular,
  },
  fromToSeatContactNoIcParent: {
    
    marginTop: 11,
    flexDirection: "row",
  },
  frameGroup: {
    height: 279,
    paddingBottom: Padding.p_5xl,
    alignItems: "center",    
  },
  boardingPassesText: {
    marginTop: 15,
    textAlign:"center"
  },

  text: {
    width: 58,
  },

  frameView: {
    marginTop: 16,
    flexDirection: "row",
  },
  frameParent: {    
    alignSelf: "center",     
  },
  icon: {
    width: 31,
    height: 9,
  },
  bookFlight: {
    fontSize: FontSize.size_xl,
    lineHeight: 32,
    textAlign: "center",
    width: 192,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  menuIcon: {
    display: "none",
  },
  hamburgerMenu: {
    justifyContent: "flex-end",
  },
  content: {
    paddingVertical: Padding.p_xs,
    paddingHorizontal: Padding.p_base,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  frame: {
    flex: 1,
  },
  bookingDetails: {
    flex: 1,
    width: "100%",    
    backgroundColor: Color.gray_100,
    alignItems:"center",
    justifyContent:"center",
  },
  centralize:{
    width:"100%",
    alignItems:"center",
    justifyContent:"center",
  },
  containerDesktop: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",        
    marginTop: 9,
    width: 800,
    borderRadius: Border.br_base,
    backgroundColor: Color.white,
    shadowColor: "rgba(89, 27, 27, 0.05)",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 10,
    elevation: 10,
    shadowOpacity: 1,
    paddingTop: 40,
  },
  container:{
    width: windowWidth,
    paddingTop: 40,
  },
  coachInfoDiv: {
    width: '100%'
  },
  coachInfo: {
    flexDirection: 'row'
  }
});

export default BookingInfoWidget;
