import React, { useState, useEffect } from 'react';
import { Text, StyleSheet, View, Image, TextInput, Platform } from "react-native";
import { Padding, Border, FontFamily, FontSize, Color } from "@common";
import { LocalizedString, CommonStyle } from "@common";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useDispatch, useSelector } from 'react-redux';
import { createAction, Desktop } from '@utils';
import { Dialog } from "@rneui/themed";
import { isValidPassword, passwordsMatch } from "@utils";
import DesktopFooter from '../components/DesktopFooter';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopWrapper from '../components/DesktopWrapper';

const ResetPassword = ({navigation}) => {

  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [isLoading, setLoading] = useState(false);
  const memberId = useSelector((state) => state.member.memberId);
  const language = useSelector((state) => state.config.language);
  const authToken = useSelector((state) => state.member.authToken);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  
  useEffect(() => {
    if (authToken === null) {
      if (isDesktop) {
      
        navigation.navigate("DesktopHome")
  
        return
      }
    }
  }, []);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };

  const resetButtonPressed = () => {
    //console.log(`Reset button pressed`);
    
    if (password.length < 8) {
      alert(LocalizedString.passwordLength);
      return;
    }

    if (oldPassword.length < 8) {
      alert(LocalizedString.oldPasswordLength);
      return;
    }

    if (passwordConfirmation.length < 8) {
      alert(LocalizedString.passwordConfirmationLength);
      return;
    }

    if (!passwordsMatch(password, passwordConfirmation)) {
      alert(LocalizedString.passwordsDoNotMatch);
      return;
    }

    let callback = (eventObject) => {
      if (eventObject.success) {
        alert(LocalizedString.passwordReset);
      } else {
        alert(eventObject.message);
      }

      setLoading(false);
    }

    dispatch(createAction('member/loadChangePassword')({memberId,oldPassword,newPassword:password,callback}));
    setLoading(true);
  }

  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState(''); // Assuming you want to use this in the future
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordConfirmation, setHidePasswordConfirmation] = useState(true);
  const [hideOldPassword, setHideOldPassword] = useState(true);

  return (
    <View style={styles.resetPassword}>         
      <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="ChangePassword"/>}> 
      <View style={styles.container}>
     

      <Desktop>      
      <Text style={CommonStyle.title}>{LocalizedString.changePassword}</Text>
        </Desktop>  
        <View>          
          <Text style={styles.subtitle}>
            {LocalizedString.enterPasswordTwice}
          </Text>
        </View>
        <View style={styles.email}>
          <Text style={[styles.label, styles.labelLayout]} numberOfLines={1}>
            {LocalizedString.enterOldPassword}
          </Text>
          <View style={[styles.input1, styles.inputSpaceBlock]}>
            <TextInput style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
            
            required={true}
            value={oldPassword}
            onChangeText={text => setOldPassword(text)}
            secureTextEntry={hideOldPassword}
            inputStyle={{}}
            
            />
            <TouchableOpacity onPress={() => setHideOldPassword(!hideOldPassword)}>
            <Image
              style={[styles.iconeyeoff, styles.labelLayout]}
              resizeMode="cover"
              source={hideOldPassword ? require("../assets/iconeye1.png") : require("../assets/iconeyeoff1.png")}
            />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.email}>
          <Text style={[styles.label, styles.labelLayout]} numberOfLines={1}>
            {LocalizedString.enterNewPassword}
          </Text>
          <View style={[styles.input1, styles.inputSpaceBlock]}>
            <TextInput style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}            
            required={true}
            value={password}
            onChangeText={text => setPassword(text)}
            secureTextEntry={hidePassword}
            inputStyle={{}}            
            />
            <TouchableOpacity onPress={() => setHidePassword(!hidePassword)}>
            <Image
              style={[styles.iconeyeoff, styles.labelLayout]}
              resizeMode="cover"
              source={hidePassword ? require("../assets/iconeye1.png") : require("../assets/iconeyeoff1.png")}
            />
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.email}>
          <Text style={[styles.label, styles.labelLayout]} numberOfLines={1}>
            {LocalizedString.reenterPassword}
          </Text>
          <View style={[styles.input1, styles.inputSpaceBlock]}>
            <TextInput style={[styles.text, styles.textLayout, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
                required={true}
                value={passwordConfirmation}
                onChangeText={text => setPasswordConfirmation(text)}
                secureTextEntry={hidePasswordConfirmation}
                inputStyle={{}}
            />          
            <TouchableOpacity onPress={() => setHidePasswordConfirmation(!hidePasswordConfirmation)}>
            <Image
              style={[styles.iconeyeoff, styles.labelLayout]}
              resizeMode="cover"
              source={hidePasswordConfirmation ? require("../assets/iconeye1.png") : require("../assets/iconeyeoff1.png")}
            />
            </TouchableOpacity>  
          </View>
        </View>
        <TouchableOpacity style={styles.inputSection} onPress={resetButtonPressed}>
          <View style={styles.loginprimary}>
            <Text style={[styles.label2, styles.label2Typo]}>
              {LocalizedString.changePassword}
            </Text>
          </View>
        </TouchableOpacity>     
        
      </View>
      </DesktopWrapper>  
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
      <Desktop>
      <View style={CommonStyle.withDesktopFooter}></View>
        <DesktopFooter navigation={navigation} isDesktop={isDesktop}/>
      </Desktop>
    </View>
  );
};

const styles = StyleSheet.create({
  labelLayout: {
    height: 24,
    overflow: "hidden",
  },
  inputSpaceBlock: {
    marginTop: 8,
    paddingVertical: Padding.p_xs,
    paddingHorizontal: Padding.p_base,
    borderWidth: 1,
    borderStyle: "solid",
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
  },
  textLayout: {
    width: 232,
    height: 24,    
    fontFamily: FontFamily.interRegular,
    textAlign: "left",
  },
  label2Typo: {
    textAlign: "center",
    fontSize: FontSize.size_base,
  },
  login: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    color: Color.black,
    width: 312,
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  subtitle: {
    fontSize: FontSize.size_lg,
    lineHeight: 26,
    color: Color.gray555,    
    fontFamily: FontFamily.interRegular,
    width: 312,
    textAlign: "left",
  },
  label: {
    color: Color.black1,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
    height: 24,
    width: 312,
    fontSize: FontSize.size_base,
    textAlign: "left",
  },
  text: {
    color: Color.gray02,
    fontSize: FontSize.size_base,
  },
  iconeyeoff: {
    width: 24,
    marginLeft: 24,
  }, 
  email: {
    marginTop: 40,
    justifyContent: "center",
  },
  text1: {    
    color: Color.gray01,
  },
  input1: {
    borderColor: "#d0d5dd",
  },
  label2: {
    lineHeight: 16,
    color: Color.white,
    width: 160,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },
  loginprimary: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
    width: 312,
    justifyContent: "center",
    alignItems: "center",
  },
  inputSection: {
    marginTop: 40,
    alignItems: "center",
  },
  createAnAccount: {
    color: Color.steelblue_200,
    height: 24,
    overflow: "hidden",
    lineHeight: 24,
    width: 312,
    marginTop: 40,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    textAlign: "center",
  },
  container: {
    alignItems: "center",
  },
  resetPassword: {
    backgroundColor: Color.white,
    flex: 1,    
    paddingHorizontal: 17,
    overflow: "hidden",
  },
});

export default ResetPassword;
