import * as React from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Platform
} from "react-native";
import {  Color, windowWidth } from "@common";
import {Calendar} from 'react-native-calendars';
import {DateTime} from 'luxon';
import {createAction} from '@utils';
import DesktopFooter from "../components/DesktopFooter";
import { useDispatch, useSelector } from 'react-redux';

const DateSection = ({navigation}) => {
  
  const currentDate = DateTime.now().setZone('Asia/Singapore').toISODate();
  const dispatch = useDispatch();
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  return (
    <ScrollView contentContainerStyle={[styles.dateSection]}>     
        <Calendar
          theme={{
            arrowWidth: 40,
            arrowHeight: 40,
          }}
          style={styles.calendar}
          minDate={currentDate}
          onDayPress={day => {
            dispatch(createAction('trip/setSelectedDate')(day.dateString));
            navigation.goBack()
          }}
        />
        {
             Platform.OS === 'web' ? 
             <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
           : null
         }
    </ScrollView>
  );
};

const styles = StyleSheet.create({ 

  dateSection: {  
    flex: 1,
    width: "100%", 
    alignItems: "center",
    backgroundColor: Color.gray_100,
    overflow: "hidden",
  },
  calendar: {
    width: windowWidth,
    paddingHorizontal: 0,  // Remove any default padding if necessary
    marginBottom: 10,
  }
});

export default DateSection;
