import React, { useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { Color } from '@common';
import { DateTime } from 'luxon';

const HorizontalCalendarView = ({selectedDate, calendarDateSelected}) => {
    const today = DateTime.local().startOf('day');
  const [startDate, setStartDate] = useState(today);
  const current = selectedDate && DateTime.fromFormat(selectedDate, 'yyyy-MM-dd') || today

  const generateDates = (startDate, numberOfDays) => {
    const dates = [];
    for (let i = 0; i < numberOfDays; i++) {
      dates.push(startDate.plus({ days: i }));
    }
    return dates;
  };

  const moveDates = (days) => {
    const newStartDate = startDate.plus({ days });
    if (newStartDate >= today) {
      setStartDate(newStartDate);
    }
  };

  const dateIsSelected = (date) => {
    if (typeof calendarDateSelected === 'function') {
      const dateString = date.toFormat('yyyy-MM-dd');
      calendarDateSelected(date,dateString);
    }
  };

  const dates = generateDates(startDate, 15);

  return (
    <View style={styles.container}>
    <ScrollView horizontal={true} style={styles.dateList}
        contentContainerStyle={styles.contentContainer}
    >
      <TouchableOpacity onPress={() => moveDates(-1)} style={[styles.dateItem]}>
        <Text style={styles.arrowButton}>{"<"}</Text>
      </TouchableOpacity>

      {dates.map((date, index) => {
        const isSelected = date.hasSame(current, 'day');
        return (
          <TouchableOpacity
            key={index}
            style={[styles.dateItem, isSelected ? styles.selectedDate : null]}
            onPress={dateIsSelected.bind(this, date)}
          >
           <Text style={[styles.dateText, isSelected ? styles.selectedDateText : null]}>
              {date.day}
            </Text>
            <Text style={[styles.dateText, isSelected ? styles.selectedDateText : null]}>
              {date.toFormat('LLL')}
            </Text>
          </TouchableOpacity>
        );
      })}

      <TouchableOpacity onPress={() => moveDates(1)} style={[styles.dateItem]}>
        <Text style={styles.arrowButton}>{">"}</Text>
      </TouchableOpacity>
    </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
    contentContainer: {        
        flexDirection: 'row',
    },
    container:{
        height:80,
    },
  dateList: {
    height: 80,
    flexDirection: 'row',    
  },
  dateItem: {
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    backgroundColor: 'white',
  },
  selectedDate: {
    backgroundColor: Color.steelblue_200,
  },
  dateText: {
    color: 'black',
  },
  selectedDateText: {
    color: 'white',
},
  arrowButton: {
    fontSize: 20,
    padding: 10,
  },
});

export default HorizontalCalendarView;
