import * as React from "react";
import { Image, StyleSheet, View } from "react-native";

const Splash = () => {
  return (
    <View style={styles.splash}>
      <Image
        style={styles.logo1Icon}
        resizeMode="cover"
        source={require("../assets/logo-1.png")}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  logo1Icon: {
    width: 297,
    height: 198,
  },
  splash: {
    backgroundColor: "#fbfdff",
    flex: 1,
    width: "100%",
    height: 800,
    overflow: "hidden",
    paddingHorizontal: 31,
    paddingVertical: 168,
    alignItems: "center",
  },
});

export default Splash;
