import React, { useState } from 'react';
import { Text, StyleSheet, View, TouchableOpacity, KeyboardAvoidingView, ScrollView,Platform } from "react-native";
import AccountForm from "../components/AccountForm";
import { Padding, FontFamily, FontSize, Color,LocalizedString } from "@common";
import BtnLanguage from "../components/BtnLanguage";
import { Dialog } from "@rneui/themed";
import { isValidEmail,createAction ,isValidName,isValidPhoneNo,isValidPassword,passwordsMatch } from "@utils";
import {useDispatch,useSelector} from 'react-redux';
import {TERMS_AND_CONDITIONS_URL} from '@constants'
import { useToast } from 'native-base';
import DismissableToast from '../components/DismissableToast';

const Signup = ({navigation}) => {
  const dispatch = useDispatch();
  const [_, forceUpdate] = useState();
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const language = useSelector((state) => state.config.language);
  const toast = useToast();

  const languageSelected = (language) => {
    
      //console.log(`Language selected ${language}`);
      LocalizedString.setLanguage(language);
      forceUpdate({}); // Use this line to force a re-render
    
  };

  const  onSignUpPressed = () => {
       

    if (!isValidEmail(email)) {
      alert(LocalizedString.invalidEmail);
      return;
    }
  
    if (!isValidName(name)) {
      alert(LocalizedString.invalidName);
      return;
    }
  
    if (!isValidPhoneNo(phoneNo)) {
      alert(LocalizedString.invalidPhoneNo);
      return;
    }
  
    if (!isValidPassword(password)) {
      alert(LocalizedString.invalidPassword);
      return;
    }
  
    if (!passwordsMatch(password, passwordConfirmation)) {
      alert(LocalizedString.passwordsNotMatch);
      return;
    }
  
    
    let callback = (eventObject) => {
      if (eventObject.success) {
          toast.show({
            placement: "bottom",
            duration: null,
            render: ({
              id
            }) => {
              return <DismissableToast id={id} toast={toast} description={LocalizedString.registrationSuccessful} />;
            }
          })
        //navigation.navigate('VerifyOTP');
        navigation.popToTop();
        navigation.goBack();
      } else {
        alert(eventObject.message);
      }

      setLoading(false);
    }

    dispatch(createAction('member/loadRegister')({name,email,password,phoneNo,callback}));
    setLoading(true);
          

    // navigation.navigate('VerifyOTP');
  };

  const  onTermsAndConditionPressed = () => {
              
    if (Platform.OS === 'web') {
      window.open(TERMS_AND_CONDITIONS_URL)
    }else{
      navigation.navigate('HTMLScreen', {title: LocalizedString.termsAndConditions, uri: TERMS_AND_CONDITIONS_URL})    
    }
     
  };

  return (
    <KeyboardAvoidingView
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    style={{ flex: 1, backgroundColor: Color.white }}
  >
    <ScrollView>
    <View style={styles.signup}>
      <View style={styles.container}>
      <BtnLanguage onSelectLanguage={languageSelected} language={language} />
        <AccountForm onSignUpPressed={onSignUpPressed} onTermsAndConditionPressed={onTermsAndConditionPressed} 
          name={name} setName={setName} email={email} setEmail={setEmail} password={password} setPassword={setPassword}
          phoneNo={phoneNo} setPhoneNo={setPhoneNo} passwordConfirmation={passwordConfirmation} setPasswordConfirmation={setPasswordConfirmation}        
        />
        <TouchableOpacity onPress={ () => navigation.navigate('EmailLogin')}>
        <Text style={styles.textSignIn} numberOfLines={1}>
          <Text
            style={styles.alreadyHaveAn}
          >{ LocalizedString.alreadyHaveAccount } </Text>
          <Text style={styles.signInHere}>{LocalizedString.signInHere}</Text>
        </Text>
        </TouchableOpacity>
      </View>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
      </View>
      </ScrollView>
    </KeyboardAvoidingView>
    
  );
};

const styles = StyleSheet.create({

  alreadyHaveAn: {
    fontFamily: FontFamily.interRegular,
    color: Color.gray01,
  },
  signInHere: {
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
    color: Color.steelblue_200,
  },
  textSignIn: {
    fontSize: FontSize.size_base,
    textAlign: "center",
    width: 312,
    height: 24,
    marginTop: 20,
    lineHeight: 24,
    overflow: "hidden",
  },
  container: {
    justifyContent: "center",
    alignItems: "flex-end",
  },
  signup: {
    flex: 1,
    width: "100%",    
    paddingHorizontal: Padding.p_5xl,
    paddingVertical: Padding.p_21xl,
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: Color.white,
  },
});

export default Signup;