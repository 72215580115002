import AsyncStorage from '@react-native-async-storage/async-storage';

import EventObject from './event_object';
import handleApiCall from '../shared/util';
import {getRoute,getSeat,getTrip,confirmSeat,queryPayment } from '../../webservice/putrajaya_api';
import { createAction } from "@utils";
import { DEFAULT_FROM_DESTINATION } from "@constants";

const getAuthToken = (state) => state.member.authToken;


export default {
  namespace: 'trip',
  state: {
    fromSelectedStation:DEFAULT_FROM_DESTINATION,
    toSelectedStation:null,
    selectedDate:null,
    selectedTrip:null,
    routes:[],
    trips:[],
    adultTicketQuantity:0,
    childTicketQuantity:0,
    seniorTicketQuantity:0,
    okuTicketQuantity:0,    
    seatResult:null,
    selectedSeats:[],
    contactNo:'',
    contactId:'',
    contactEmail:'',
    passengerType:[],
    passengerId:[],
    passengerName:[],
    includeInsurance:false,
    seatConfirmReference:null
  },
  reducers: {          
    resetTripInfo: (state, {payload}) => {

      return {
        ...state,        
        selectedTrip:null,
        trips:[],
        adultTicketQuantity:0,
        childTicketQuantity:0,
        seniorTicketQuantity:0,
        okuTicketQuantity:0,    
        selectedSeats:[],
        contactNo:'',
        contactId:'',
        contactEmail:'',
        passengerType:[],
        passengerId:[],
        passengerName:[],
        includeInsurance:false,
        seatConfirmReference:null
      };
    },    
    setRoutes: (state, {payload}) => {

      return {
        ...state,
        routes: payload,
      };
    }, 
    setContactInfo: (state, {payload}) => {

      //console.log('payload', payload);
      return {
        ...state,
        contactNo: payload.contactNo,
        contactId: payload.contactId,
        contactEmail: payload.contactEmail,
        passengerType: payload.passengerType,
        passengerId: payload.passengerId,
        passengerName: payload.passengerName
      };
    },
    setSeatConfirmReference: (state, {payload}) => {
      //console.log('payload', payload);
      return {
        ...state,
        seatConfirmRef: payload
      };
    },
    setSeatResult: (state, {payload}) => {
      return {
        ...state,
        seatResult: payload,
      };
    },
    setTrip: (state, {payload}) => {
      return {
        ...state,
        trips: payload.trip,
      };
    },
    setToSelectedStation: (state, {payload}) => {
        
      
      return {
        ...state,
        toSelectedStation: payload,
      };
    },
    setFromSelectedStation: (state, {payload}) => {
        
        
        return {
          ...state,
          fromSelectedStation: DEFAULT_FROM_DESTINATION,
        };
    },
    setIncludeInsurance: (state, {payload}) => {
      return {
        ...state,
        includeInsurance: payload,
      };
    },
    setSelectedDate: (state, {payload}) => {
  
      return {
        ...state,
        selectedDate: payload,
      };
    },
    setSelectedTrip: (state, {payload}) => {
  
      return {
        ...state,
        selectedTrip: payload,
        adultTicketQuantity:0,
        childTicketQuantity:0,
        seniorTicketQuantity:0,
        okuTicketQuantity:0,    
        selectedSeats:[],
        contactNo:'',
        contactId:'',
        passengerType:[],
        passengerId:[],
        passengerName:[],
        includeInsurance:false,
        seatConfirmReference:null
      };
    },
    setSelectedSeats: (state, {payload}) => {
  
      return {
        ...state,
        selectedSeats: payload,
      };
    },
    setSeniorTicketQuantity: (state, {payload}) => {
  
      return {
        ...state,
        seniorTicketQuantity: payload,
      };
    },
    setAdultTicketQuantity: (state, {payload}) => {
          
        return {
          ...state,
          adultTicketQuantity: payload,
        };
    },
    setChildTicketQuantity: (state, {payload}) => {
          
        return {
          ...state,
          childTicketQuantity: payload,
        };
    },
    setOkuTicketQuantity: (state, {payload}) => {
          
        return {
          ...state,
          okuTicketQuantity: payload,
        };
    }
  },
  effects: {  
    *loadGetRoute({payload}, {call, put, select}) {      
      try {                
        const json = yield call(getRoute, {});
                  
        //console.log('json', json);
        const eventObject = new EventObject(json);
        if (eventObject.success) {
          yield put(createAction('setRoutes')(eventObject.result));
        }
      
        typeof callback === 'function' && callback(eventObject);
      } catch (err) {
        //console.log('err', err);

      }
    },  
    *loadGetTrip({payload}, {call, put, select}) {      
        try {
          const {date,from, to, callback} = payload;
        
          const json = yield call(getTrip, {date,from, to});
                    
          const eventObject = new EventObject(json);
          if (eventObject.success) {
            yield put(createAction('setTrip')(eventObject.result));
          }

          typeof callback === 'function' && callback(eventObject);
        } catch (err) {
          //console.log('err', err);

        }
      },     
    
      *loadGetSeat({payload}, {call, put, select}) {      
        try {                
          const {date,trip, callback} = payload;

          //console.log('date', date);
          //console.log('trip', trip);
          const json = yield call(getSeat, {date,trip});
                    
          const eventObject = new EventObject(json);
          if (eventObject.success) {
            yield put(createAction('setSeatResult')(eventObject.result));
          }
        
          typeof callback === 'function' && callback(eventObject);
        } catch (err) {
          //console.log('err', err);
        }
      },  
      *loadConfirmSeat({payload}, {call, put, select}) {      
        try {                
        const { memberId,date,trip,seat
          ,passengerType,passengerId,passengerName,totalPayableAmount,
          insurance,adult,child,senior,oku
          ,contactId,contactNo,contactEmail,isBooking, callback} = payload;
        
        const authToken = yield select(getAuthToken);

        //console.log('date', date);
        //console.log('trip', trip);
        //console.log('seat', seat);
        //console.log('passengerType', passengerType);
        //console.log('passengerId', passengerId);
        //console.log('passengerName', passengerName);
        //console.log('totalPayableAmount', totalPayableAmount);
        //console.log('insurance', insurance);
        //console.log('adult', adult);
        //console.log('child', child);
        //console.log('senior', senior);
        //console.log('oku', oku);
        //console.log('contactId', contactId);
        //console.log('contactNo', contactNo);
        //console.log('isBooking', isBooking);
        //console.log('authToken', authToken);
        //console.log('contactEmail', contactEmail);

          const json = yield call(handleApiCall, confirmSeat, {memberId,date,trip,seat
            ,passengerType,passengerId,passengerName,totalPayableAmount,
            insurance,adult,child,senior,oku
            ,contactId,contactNo,contactEmail,isBooking,authToken});
                    
          const eventObject = new EventObject(json);
          if (eventObject.success) {
            yield put(createAction('setSeatConfirmReference')(eventObject.result));
          }
        
          typeof callback === 'function' && callback(eventObject);
        } catch (err) {
          //console.log('err', err);
        }
      },
      *loadQueryPayment({payload}, {call, put, select}) {
        try {                
          const {memberId,refNo,date,callback} = payload;

          const authToken = yield select(getAuthToken);

          //console.log('refNo', refNo);
          //console.log('date', date);
          const json = yield call(handleApiCall, queryPayment, {memberId,refNo,date,authToken});

          //console.log('json', json);
                    
          const eventObject = new EventObject(json);
        
          typeof callback === 'function' && callback(eventObject);
        } catch (err) {
          //console.log('err', err);
        }
      } 
  },
};
