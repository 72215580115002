class EventObject {
    constructor(jsonData) {      

      const {data, message, status, success, result, error, tokenExpired} = jsonData;

      //console.log('success', success);
      //console.log('result', result);
      //console.log('error', error);
      //console.log('data', data);
      //console.log('message', message);
      //console.log('status', status);
      //console.log('tokenExpired', tokenExpired);

      this.tokenExpired = false

      if (result != null){
        if (result.data != null){
          if (result.data.includes('Auth Token expired')) {
            this.tokenExpired = true
            // Handle the 'Auth Token expired' case here
            //console.log('Authentication token has expired. Refreshing Token...');
          } else {
            // Handle other cases here
            //console.log('An error occurred:', result.message);
          }
        }
      }

      if (error !== undefined) {
        this.success = false
        this.message = error
      }
      else if (result === undefined && message !== undefined && status !== undefined && status !== 'OK') {
        this.success = false;
        this.message = message;
      }
      else if (result.status !== undefined) {
        this.status = result.status;

        // their naming is misleading, i purposely change it to message
        this.data = result.data;
        this.message = `${result.message} - ${result.data}`;
        this.success = false
      }else {
        this.success = true
        this.result = result
      }            
    }

    isTokenExpired() {
      return this.tokenExpired;
    }
  }
  
  export default EventObject;
  