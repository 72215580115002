import { View, Text, StyleSheet } from "react-native";
import React from "react";
import { Color, FontFamily, FontSize, Padding, Border, LocalizedString } from "@common";

const TicketSummary = ({ type, quantity, fare, total }) => {
    return quantity > 0 ? (
      <View style={styles.quantityFlexBox}>
        <View style={[styles.quantity, styles.quantityLayout]}>
          <Text style={[styles.x, styles.xTypo]}>{quantity}x</Text>
        </View>
        <View style={styles.adultParent}>
          <Text style={[styles.adult, styles.xTypo]}>{LocalizedString[type.toLowerCase() == 'disabled' ? 'oku' : type.toLowerCase()]}</Text>
          <Text style={[styles.rm5500, styles.textTypo]}>
            {fare.currency} {fare[type.toLowerCase()].toFixed(2)}
          </Text>
        </View>
        <View style={styles.priceRight}>
          <Text style={[styles.text, styles.textTypo]}>{total.toFixed(2)}</Text>
        </View>
        <View style={[styles.result01Child, styles.result01Layout]} />
      </View>
    ) : null;
  };

const styles = StyleSheet.create({
    quantityFlexBox: {
      width: 289,
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    quantityLayout: {
      alignItems: "center",
      paddingVertical: Padding.p_7xs_5,
      height: 31,
      width: 39,
      backgroundColor: Color.grayD9D9D9,
      borderRadius: Border.br_8xs,
    },
    xTypo: {
      fontSize: FontSize.size_sm,
      color: Color.black,
    },
    adultParent: {
      width: 178,
      marginLeft: 14,
      justifyContent: "center",
    },
    adult: {
      textAlign: "left",
      fontFamily: FontFamily.interMedium,
      fontWeight: "500",
      fontSize: FontSize.size_sm,
    },
    rm5500: {
      fontSize: FontSize.size_xs,
      color: Color.steelblue_200,
      width: 105,
      marginTop: 4,
      textAlign: "left",
    },
    textTypo: {
      fontFamily: FontFamily.interBold,
      fontWeight: "600",
    },
    text: {
      marginLeft: 14,
      fontSize: FontSize.size_sm,
      color: Color.black,
      textAlign: "right",
    },
    result01Child: {
      borderColor: "#e6e8e7",
      marginTop: 16,
    },
    priceRight: {
      flexDirection: "row",
      alignItems: 'right'
    }
  });
  

  export default TicketSummary;