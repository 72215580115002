import { useMediaQuery } from 'react-responsive'; 
import { useSelector } from 'react-redux';

export const useDeviceType = () => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  return { isDesktop, isMobile };
};

export const Desktop = ({ children }) => {
  const isDesktop = useSelector(state => state.member.isDesktopWeb);
    return isDesktop ? children : null;
  };
  

export const Mobile = ({ children }) => {
  const isDesktop = useSelector(state => state.member.isDesktopWeb);
return !isDesktop ? children : null;
};

export const Default = ({ children }) => {
  const isDesktop = useSelector(state => state.member.isDesktopWeb);
return !isDesktop ? children : null;
};
