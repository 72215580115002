import React, { useState } from 'react';
import { Image, StyleSheet, View, Text , TextInput, TouchableOpacity, Platform} from "react-native";
import { Border, Padding, FontFamily, FontSize, Color, CommonStyle } from "@common";
import { LocalizedString } from "@common";
import { createAction } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from "@rneui/themed";
const VerifyOTP = ({navigation}) => {

  const dispatch = useDispatch();
  const [otpCode, setOtpCode] = useState('');
  const [countdown, setCountdown] = useState(30);
  const [isLoading, setLoading] = useState(false);
  const otpMemberId = useSelector((state) => state.member.otpMemberId);

  React.useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [countdown]);

  const backButtonPressed = () => {
    navigation.goBack();
  }

  const onConfirmOTPPressed = () => {

    //console.log(`onConfirmOTPPressed`);

    if (otpCode.length < 6) {
      alert(LocalizedString.invalidOtp);
      return;
    }

    let callback = (eventObject) => {
      setLoading(false);
    }

    dispatch(createAction('member/loadActivation')({code:otpCode,memberId:otpMemberId,callback}));
    setLoading(true);
  }

  const resentOtpPressed = () => {
    //console.log(`resentOtpPressed`);
    setCountdown(30);
  }

  return (
    <View style={styles.verifyCode}>
      <View style={styles.containerBack}>
        <View style={[styles.blank, styles.blankLayout]} />
        <TouchableOpacity style={[styles.btnBack, styles.blankLayout]} onPress={backButtonPressed}>
          <Image
            style={styles.iconLeft}
            resizeMode="cover"
            source={require("../assets/iconleftarrow.png")}
          />
        </TouchableOpacity>
      </View>
      <View style={[styles.container, styles.containerFlexBox]}>
        <View>
          <View>
            <Text style={styles.titleVerification}>{LocalizedString.codeVerification}</Text>
            <Text style={styles.subtitleVerification}>
              {LocalizedString.enterVerificationCode}
            </Text>
          </View>
 
         <View style={[styles.inputName, styles.inputNameLayout]}>
          <TextInput
            style={[styles.text1, styles.textLayout1, Platform.OS === 'web' ? CommonStyle.webTextField : undefined]}
            value={otpCode}
            onChangeText={(text) => setOtpCode(text)}
          />
          </View>       

        </View>
        <TouchableOpacity style={[styles.btnSignUp, styles.inputNameLayout]} onPress={onConfirmOTPPressed}>
        <Text style={[styles.label, styles.labelTypo]}>{LocalizedString.activate}</Text>
      </TouchableOpacity>
        <View style={styles.containerResend}>
          {countdown > 0 ? 
             <Text style={[styles.textResend, styles.resendTypo]}>
             {`${LocalizedString.resentOtpIn} ${countdown}`}
           </Text>          
          : undefined}
       
          <TouchableOpacity style={styles.btnResend} onPress={resentOtpPressed}>
          <Text style={[styles.btnResend, styles.resendTypo]}>Resend OTP</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Dialog isVisible={isLoading} >
        <Dialog.Loading />
      </Dialog>
    </View>
  );
};

const styles = StyleSheet.create({
  inputNameLayout: {
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
    marginTop: 16,
  },
  inputName: {
    borderStyle: "solid",
    borderColor: "#d0d5dd",
    borderWidth: 1,
    paddingHorizontal: Padding.p_base,
    paddingVertical: Padding.p_xs,
  },
  textTypo: {
    textAlign: "center",
    lineHeight: 22,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_lg,
  },
  resendTypo: {
    fontSize: FontSize.size_base,
    textAlign: "center",
  },
  inputNameLayout1: {
    flexDirection: "row",
    height: 48,
    borderRadius: Border.br_5xs,
    marginTop: 16,
  },
  containerFlexBox: {
    justifyContent: "center",
    alignItems: "center",
  }, 
  blank: {
    width: 20,
    backgroundColor: Color.white,
  },
  iconLeft: {
    width: 24,
    height: 24,
    overflow: "hidden",
  },
  labelTypo: {
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  label: {
    lineHeight: 16,
    color: Color.white,
    textAlign: "center",
    width: 160,
  },
  btnBack: {
    backgroundColor: Color.gray05,
    width: 40,
    marginLeft: 10,
    padding: Padding.p_5xs,
    flexDirection: "row",
  },
  containerBack: {
    marginTop: 49,
    flexDirection: "row",
    alignSelf: "stretch",
  },
  titleVerification: {
    fontSize: FontSize.size_9xl,
    lineHeight: 40,
    color: Color.black,
    width: 312,
    textAlign: "left",
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
  },
  subtitleVerification: {
    lineHeight: 26,
    color: Color.gray555,
    marginTop: 12,
    fontFamily: FontFamily.interRegular,
    fontSize: FontSize.size_lg,
    width: 312,
    textAlign: "left",
  },
  text: {
    color: Color.gray02,
  },
  btnSignUp: {
    backgroundColor: Color.steelblue_200,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    justifyContent: "center",
    alignItems: "center",
    width: 312,
  },
  
  textVerify: {
    lineHeight: 16,
    color: Color.white,
    width: 160,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    fontSize: FontSize.size_base,
  },
  btnVerify: {
    backgroundColor: Color.steelblue_200,
    height: 48,
    paddingHorizontal: Padding.p_xs,
    paddingVertical: Padding.p_base,
    marginTop: 64,
    width: 312,
    borderRadius: Border.br_5xs,
    justifyContent: "center",
    flexDirection: "row",
  },
  groupOtp: {
    marginTop: 72,
    alignItems: "center",
  },
  textResend: {
    lineHeight: 24,
    color: Color.black1,
    fontFamily: FontFamily.interRegular,
  },
  btnResend: {
    lineHeight: 20,
    color: Color.gray01,
    marginTop: 16,
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_base,
  },
  text1: {
    // Add specific styles for the text input
    flex: 1, // for occupying full space        
  },
  containerResend: {
    marginTop: 49,
    alignItems: "center",
  },
  container: {
    marginTop: 49,
    alignSelf: "stretch",
  },
  verifyCode: {
    flex: 1,
    width: "100%",
    height: 800,
    overflow: "hidden",
    backgroundColor: Color.white,
  },
});

export default VerifyOTP;
