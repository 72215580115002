import React, { useState , useEffect } from 'react';
import { Image, StyleSheet, View, FlatList, Text, RefreshControl, TouchableOpacity, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Color, Padding, FontSize, FontFamily, Border, LocalizedString, CommonStyle } from "@common";
import { createAction,Desktop } from '@utils';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'native-base';
import DesktopNavbar from '../components/DesktopNavbar';
import DesktopFooter from '../components/DesktopFooter';
import DesktopWrapper from '../components/DesktopWrapper';

const MyBookings = ({navigation}) => {
  const [_, forceUpdate] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useDispatch();
  const memberId = useSelector((state) => state.member.memberId);
  const authToken = useSelector((state) => state.member.authToken);
  const language = useSelector((state) => state.config.language);
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);
  const [isLoading, setLoading] = useState('');
  const [bookings, setBookings] = useState([])
  const [pageNo, setPageNo] = useState(1);
  const [endOfPage, setEndOfPage] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (isDesktop) {
      if (authToken === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
    }
    if (!endOfPage) {
      loadBookings()
    }
  }, [pageNo]);

  const languageSelected = (language) => {
    
    //console.log(`Language selected ${language}`);
    LocalizedString.setLanguage(language);
    forceUpdate({}); // Use this line to force a re-render    
  };


  const loadBookings = (recordPerPage=20) => {
    let callback = (eventObject) => {
      setRefreshing(false);
      setLoadingMore(false);
      if (eventObject.success) {
        if (eventObject.result.trxHistory.length === 0) {
          setEndOfPage(true)
        }
        else {
          setBookings([...bookings, ...eventObject.result.trxHistory])
        }
        if (eventObject.result.trxHistory.length < recordPerPage) {
          setEndOfPage(true)
        }
      }
    }
    dispatch(createAction('member/loadTransactionHistory')({callback,memberId,pageNo,recordPerPage}))
  }

  const handleRefresh = () => {
    setRefreshing(true);
    setEndOfPage(false);
    setPageNo(1)
  };

  const handleLoadMore = () => {
    if (!endOfPage) {
      setLoadingMore(true);
      setPageNo(pageNo + 1)
    }
    else {
      toast.show({
        description: `${LocalizedString.noMoreResult}`,
        placement: "bottom"
      })
    }
  };

  const onBookingPressed = (item) => {
    //console.log('booking item', item)
    if (item.transStatus === 'Paid' && item.paymentStatus === 'Approved') {
      navigation.navigate("BookingDetail", {refNo: item.transRefNo, date: item.tripDateTime, totalAmount: item.totalAmount})
    }
  }

  const renderDesktopBookingView = () => {
    return (
      <Desktop>
        <View style={CommonStyle.textWrapper}>
          <Text style={CommonStyle.title}>{LocalizedString.myBookings}</Text>
        </View>
      </Desktop>
    )
  }
  
  const renderBookingItem = ({ item }) => (
    <TouchableOpacity onPress={() => onBookingPressed(item)}>
      <View style={[styles.frameShadowBox, isDesktop ? styles.desktopWidth : styles.phoneWidth] }>
        <View style={styles.groupParent}>
          <Image style={styles.frameItem} resizeMode="cover" source={require("../assets/group-511.png")} />
          <Text style={[styles.coachJasaPelangiContainer, styles.fromToSeatTypo]}>
            <Text style={styles.coach}>{LocalizedString.refNo}</Text>
            <Text style={styles.jasaPelangi}>{`${item.transRefNo}\n`}</Text>
            <Text style={styles.coach}>{LocalizedString.travelTime}&nbsp;</Text>
            <Text style={styles.jasaPelangi}>{item.tripDateTime}</Text>
          </Text>
        </View>
        <View style={styles.fromToSeatnoOfTicketParent}>
          <Text style={[styles.fromToSeat, styles.fromToSeatTypo]}>{`${LocalizedString.destination}:\n${LocalizedString.noOfTicket}\n${LocalizedString.transactionTime}\n${LocalizedString.transactionStatus}\n${LocalizedString.paymentStatus}\n${LocalizedString.amount}`}</Text>
          <Text style={[styles.kprKamparLar, styles.fromToSeatTypo]}>{`${item.fromTo}\n${item.noOfTicket}\n${item.tripDateTime}\n${item.transStatus}\n${item.paymentStatus}\n${item.totalAmount}`}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
  
  return (
    <DesktopWrapper isLoading={isLoading} navigation={navigation} dispatch={dispatch} memberId={memberId} style={styles.home} footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="MyBookings"/>}> 
    <View style={[styles.myBookings, styles.topBarBg]}>
      {renderDesktopBookingView()}
    <FlatList
  data={bookings}
  renderItem={renderBookingItem}
  keyExtractor={(item, index) => index.toString()}
  style={styles.bookingFlatList}
  contentContainerStyle={styles.bookingsList}
  refreshControl={
    <RefreshControl
      refreshing={refreshing}
      onRefresh={handleRefresh}
    />
  }
  // onEndReached={handleLoadMore}
  // onEndReachedThreshold={0.1} // This will trigger handleLoadMore when 10% of the bottom is reached.
    />
    <View style={styles.loadMoreButton}>
      <Pressable style={styles.buttonBig} onPress={handleLoadMore}>
        <Text style={styles.confirm}>{LocalizedString.loadMore}</Text>
      </Pressable>
    </View>
    <Desktop>
      <View style={CommonStyle.withDesktopFooter}></View>
      <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
    </Desktop>
    </View>
    </DesktopWrapper>
  );
};

const styles = StyleSheet.create({
  topBarBg: {
    backgroundColor: Color.gray_100,    
    overflow: "hidden",
  },
  headerFlexBox: {
    paddingVertical: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  fromToSeatTypo: {
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  frameItem: {
    width: 38,
    height: 38,
  },
  coach: {
    color: Color.black,
  },
  jasaPelangi: {
    color: Color.deeppink,
  },
  coachJasaPelangiContainer: {
    marginLeft: 10,
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
  },
  groupParent: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fromToSeat: {
    fontFamily: FontFamily.interBold,
    fontWeight: "700",
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
    color: Color.black,
  },
  kprKamparLar: {
    fontFamily: FontFamily.interRegular,
    marginLeft: 21,
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_sm,
    color: Color.black,
  },
  fromToSeatnoOfTicketParent: {
    marginTop: 21,
    flexDirection: "row",
  },
  phoneWidth: {
    width: 330 ,
  },
  desktopWidth: {
    width: 660,
  },
  frameShadowBox: {
    marginTop: 11,
    paddingBottom: Padding.p_5xl,
    paddingTop: Padding.p_base,
    shadowOpacity: 1,
    elevation: 10,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowColor: "rgba(89, 27, 27, 0.05)",
    backgroundColor: Color.white,
    borderRadius: Border.br_base,
    alignItems: "center",
    height: 240
  },
  bookingsList: {
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  bookingFlatList: {
    marginBottom: 10
  },
  myBookings: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    overflow: "hidden"
  },
  loadMoreButton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20
  },
  buttonBig: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 350,
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_5xs,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  confirm: {
    fontSize: FontSize.size_lg,
    color: Color.white,
    textAlign: "center",
    width: 120,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    lineHeight: 24,
  },
});

export default MyBookings;
