import { BASE_URL, DEBUG_MODE, MYE_TOKEN, MYE_CLIENT, CLIENT_ID } from '@constants';

import { DateTime } from 'luxon';
import CryptoJS from 'crypto-js';
const getHeader = () => {
  let myHeaders = new Headers();

  let myeClient = `${CLIENT_ID}@${MYE_CLIENT}`
  myHeaders.append("MYE_CLIENT", myeClient);
    
  let date = DateTime.now().setZone('Asia/Singapore').plus({ hours: 0 }).toFormat('HHddMMyy');
  //console.log(date);

  let myeCheck = `${myeClient}${date}`

  myHeaders.append("MYE_STAMP", date);  

  let md5MyeCheck = CryptoJS.MD5(myeCheck).toString(CryptoJS.enc.Hex);
  // let md5MyeCheck = md5(myeCheck);
  myHeaders.append("MYE_CHECK", md5MyeCheck);

  myHeaders.append("Content-Type", "application/json");

  myHeaders.append("Authorization", `Bearer ${MYE_TOKEN}`);

  if (DEBUG_MODE) {
    myHeaders.forEach((value, name) => {
      //console.log(`Header Name: ${name}, Value: ${value}`);
    });
  }
  
  return myHeaders;
}

const get = async (endpoint) => {
 

  let myHeaders = getHeader();

  if (DEBUG_MODE) {
    //console.log('GET Endpoint:', `${BASE_URL}${endpoint}`);
  }

  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);

  

  if (!response.ok) {
    const errorData = await response.json();
    const message = errorData?.message || 'An unexpected error occurred';
    console.error('Error:', message);
    throw new Error(message);
  }

  let data = await response.json();
  if (DEBUG_MODE) console.log('Response:', data);
  return data;
};

const post = async (endpoint, body) => {

  
  let myHeaders = getHeader();

  if (DEBUG_MODE) {
    console.log('POST Endpoint:', `${BASE_URL}${endpoint}`);
    console.log('POST Body:', body);
  }

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);



  if (!response.ok) {
    const errorData = await response.json();
    const message = errorData?.message || 'An unexpected error occurred';
    console.error('Error:', message);
    throw new Error(message);
  }

  let data = await response.json();
  if (DEBUG_MODE) console.log('Response:', data);
  return data;
};

const put = async (endpoint, body) => {

  let myHeaders = getHeader();

  if (DEBUG_MODE) {
    console.log('PUT Endpoint:', `${BASE_URL}${endpoint}`);
    console.log('PUT Body:', body);
  }

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    const message = errorData?.message || 'An unexpected error occurred';
    console.error('Error:', message);
    throw new Error(message);
  }

  let data = await response.json();
  if (DEBUG_MODE) console.log('Response:', data);
  return data;
};

const del = async (endpoint) => {

  let myHeaders = getHeader();

  if (DEBUG_MODE) {
    console.log('DELETE Endpoint:', `${BASE_URL}${endpoint}`);
  }
  
  let requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(`${BASE_URL}${endpoint}`, requestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    const message = errorData?.message || 'An unexpected error occurred';
    console.error('Error:', message);
    throw new Error(message);
  }

  let data = await response.json();
  if (DEBUG_MODE) console.log('Response:', data);
  return data;
};

// Existing getHeader function stays the same

export { post, get, put, del };

