import React, {useState,useEffect} from "react";
import { Image, StyleSheet, View, TouchableOpacity, Text, FlatList, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Color, FontFamily, Padding, FontSize, Border, LocalizedString, CommonStyle } from "@common";
import { useDispatch, useSelector } from "react-redux";
import { createAction, Desktop, useDeviceType } from "@utils";
import {DateTime} from 'luxon';
import DesktopNavbar from "@components/DesktopNavbar";
import DesktopFooter from "../components/DesktopFooter";
import DesktopSearchBar from "../components/DesktopSearchBar";
import HorizontalCalendarView from "../components/HorizontalCalendarView";
import MobileTripItem from "../components/MobileTripItem";
import TripComponent from "../components/TripComponent";
import DesktopWrapper from '../components/DesktopWrapper';
import {DEFAULT_FROM_DESTINATION} from "@constants"
import Breadcrumb from '../components/Breadcrumb';
const SearchResult = () => {
  
  const isDesktop = useSelector((state) => state.member.isDesktopWeb);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const toSelectedStation = useSelector((state) => state.trip.toSelectedStation);
  const fromSelectedStation = useSelector((state) => state.trip.fromSelectedStation);
  const selectedDate = useSelector((state) => state.trip.selectedDate);  
  const trips = useSelector((state) => state.trip.trips);
  const [_, forceUpdate] = useState();
  const memberId = useSelector((state) => state.member.memberId);
   const language = useSelector((state) => state.config.language); 
  const routes = useSelector((state) => state.trip.routes);

   
   const languageSelected = (language) => {

     LocalizedString.setLanguage(language);
     forceUpdate({}); // Use this line to force a re-render    
 };

 const loadTrips = (sDate) => {
  const oldDate = DateTime.fromISO(sDate);
  const newDateString = oldDate.toFormat("dd-MM-yyyy");

  //console.log('to', toSelectedStation)

  dispatch(createAction("trip/loadGetTrip")({
    date: newDateString,
    from: fromSelectedStation.stationCode,
    to: toSelectedStation.stationCode
  }));
};

 
  useEffect(() => {
    if (isDesktop) {
      if (selectedDate === null) {
        navigation.navigate("DesktopHome")
  
        return
      }
    }
 

    //console.log(`SearchResult useEffect called ${selectedDate}}`);

    loadTrips(selectedDate);
  }, [toSelectedStation, selectedDate]);


  const handleItemPress = (item) => {
    // Handle your item press logic here
    //console.log('Item pressed:', item);
    dispatch(createAction("trip/setSelectedTrip")(item));
    navigation.navigate("FlightDetails");    
  };

  const onToItemSelected = (item) => {
    dispatch(createAction('trip/setToSelectedStation')(item));
  }

  const calendarDateSelected = (date,dateString) => {

    //console.log(`calendarDateSelected Date selected ${date}`);
    //console.log(`calendarDateSelected Date selected ${dateString}`);
    

    dispatch(createAction("trip/setSelectedDate")(dateString));
  }

  const searchButtonPressed = () => {
    loadTrips(selectedDate);
  }

  

  const renderItem = ({ item }) => (
    isDesktop ? 
      <TripComponent trip={item} fromSelectedStation={fromSelectedStation} toSelectedStation={toSelectedStation} selectedDate={selectedDate} handleItemPress={handleItemPress} /> :      
      <MobileTripItem item={item} handleItemPress={handleItemPress} />
  );
  

  return (
   
    <View style={[styles.searchResult, styles.topNavBarBg]}>
      
      <DesktopWrapper navigation={navigation} dispatch={dispatch} memberId={memberId}  footer={null} navbar={<DesktopNavbar navigation={navigation} languageSelected={languageSelected} language={language} selectedRoute="SearchResult"/>}> 
      <Desktop>     
        <View style={styles.desktopSearchView}>     
        <Breadcrumb
              items={[
                { label: LocalizedString.home, navigate: () => navigation.navigate('DesktopHome') },
                // { label: LocalizedString.searchResults, navigate: () => //console.log('Category clicked') },
              ]}
            />      
          <DesktopSearchBar dispatch={dispatch} destinationRoutes={routes} toSelectedStation={toSelectedStation} 
          fromSelectedStation={DEFAULT_FROM_DESTINATION} selectedDate={selectedDate} 
          searchButtonPressed={searchButtonPressed} showBanner={false} calendarDateSelected={calendarDateSelected}
          onToItemSelected={onToItemSelected} />   
        </View>  
        <View style={CommonStyle.textWrapper}>
          <HorizontalCalendarView selectedDate={selectedDate} calendarDateSelected={calendarDateSelected}/>
        </View>
        <View style={styles.titleWrapper}>
          <View style={styles.titleDivisionWrapper}>
            <View style={styles.containerFromTo}>
              <View style={styles.frameParent}>
                <View style={styles.fromWrapper}>
                  <Text style={styles.from}>{LocalizedString.fromC}</Text>
                </View>
                <Image
                  style={styles.frameItem}
                  resizeMode="cover"
                  source={require("../assets/group-45.png")}
                />
                <View style={styles.toWrapper}>
                  <Text style={styles.to}>{LocalizedString.toC}</Text>
                </View>
              </View>
              <View style={styles.kprKamparParent}>
                <View style={styles.kprKampar}>
                  <Text style={styles.kpr}>{fromSelectedStation && fromSelectedStation.stationCode}{`\n`}</Text>
                  <Text style={styles.kampar}>{fromSelectedStation && fromSelectedStation.stationName}</Text>
                </View>
                <View style={styles.jbLarkinLarContainer}>
                <Text style={styles.kpr}>{toSelectedStation && toSelectedStation.stationCode}{`\n`}</Text>
                  <Text style={styles.kampar}>{toSelectedStation && toSelectedStation.stationName}</Text>                
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={[CommonStyle.title, styles.resultTitle]}>{LocalizedString.departingBus}</Text>
          </View>
          <View style={styles.titleDivisionWrapper} />
        </View>
        
      </Desktop>
      { trips.length === 0 ? 
        <View style={styles.noTripsContainer}>
          {!isDesktop && <Text style={CommonStyle.subTitle}>{selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()} | {toSelectedStation && toSelectedStation.stationName}</Text>}
        <Text style={styles.searchResultText}>{LocalizedString.noTripsAvailable}</Text>
        </View>
      :  <View style={styles.noTripsContainer}> 
      {!isDesktop && <Text style={CommonStyle.subTitle}>{selectedDate && DateTime.fromFormat(selectedDate, "yyyy-MM-dd").setLocale(language).toFormat('dd/MM/yyyy, EEE').toUpperCase()} | {toSelectedStation && toSelectedStation.stationName}</Text>}
      <FlatList
      data={trips}
      renderItem={renderItem}
      keyExtractor={(item) => item.code}
      ListHeaderComponent={() => isDesktop && <View style={styles.titleSummary}>
        <Text style={[styles.tripTitle, styles.coachNameContainer]}>{LocalizedString.coachNameHeader}</Text>
        <Text style={styles.tripTitle}>{LocalizedString.seatAvailability}</Text>
        <Text style={styles.tripTitle}>{LocalizedString.departTime}</Text>
        <Text style={styles.tripTitle}>{LocalizedString.priceHeader}</Text>
      </View>}     
    />
    </View>
      }
      { Platform.OS === 'web' ? 
          <DesktopFooter navigation={navigation} isDesktop={isDesktop} />
        : null}
      </DesktopWrapper>
    </View>
  );
};


const styles = StyleSheet.create({
  desktopSearchView: {
    height: 200,
    backgroundColor: Color.white,
    width: "100%",
    justifyContent: 'center',  // Aligns children vertically in the center
    alignItems: 'center',  // Aligns children horizontally in the center
  },
  textWrapper:{
    flexDirection: 'row',
    width: "100%",
    justifyContent: 'center',  // Aligns children vertically in the center
    alignItems: 'center',  // Aligns children horizontally in the center
  },
  titleWrapper: {
    flexDirection: 'row',
    width: "100%",
    justifyContent: 'center', 
    alignItems: 'center',
    marginBottom: 20,
  },
  titleDivisionWrapper: {
    width: '33%'
  },
  searchResultText:{
    marginVertical: 24,
  },  
  confirmTypo: {
    textAlign: "center",
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
  },

  standardSpaceBlock: {
    marginTop: 4,
    textAlign: "left",
  },
  amTypo: {
    fontFamily: FontFamily.interBold,
    fontWeight: "600",
    fontSize: FontSize.size_base,
    lineHeight: 24,
    color: Color.black,
  },
  icon: {
    width: 31,
    height: 9,
  },
  header: {
    paddingHorizontal: Padding.p_17xl,
    justifyContent: "center",
  },
  frameItem: {
    width: 76,
    height: 76,
  },
  jasaPelangi: {
    fontWeight: "700",
    fontFamily: FontFamily.interBold,
    color: Color.steelblue_100,
    width: 203,
    textAlign: "left",
    lineHeight: 24,
    fontSize: FontSize.size_xl,
  },
  standard: {
    fontSize: FontSize.size_sm,
    color: Color.black,
    fontFamily: FontFamily.interMedium,
    fontWeight: "500",
    marginTop: 4,
  },
  noTripsContainer:{
    width: "100%",
    justifyContent: 'center',  // Aligns children vertically in the center
    alignItems: 'center',  // Aligns children horizontally in the center
  },
  seatsAvailable: {
    fontSize: FontSize.size_xs,
    fontWeight: "300",
    fontFamily: FontFamily.interLight,
    color: Color.dimgray_200,
    width: 105,
  },
  jasaPelangiParent: {
    marginLeft: 14,
    justifyContent: "center",
  },
  line: {
    opacity: 0.3,
    marginTop: 16,
  },
  am: {
    width: 85,
    textAlign: "left",
  },
  rm5500: {
    textAlign: "right",
    width: 112,
  },
  rm5500Wrapper: {
    width: 119,
    marginLeft: 103,
    flexDirection: "row",
    alignItems: "center",
  },
  frameParent: {
    marginTop: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  confirm: {
    fontSize: FontSize.size_lg,
    color: Color.white,
    width: 120,
    lineHeight: 24,
  },
  buttonBig: {
    borderRadius: Border.br_5xs,
    backgroundColor: Color.steelblue_200,
    width: 296,
    paddingHorizontal: Padding.p_85xl,
    paddingVertical: Padding.p_5xs,
    marginTop: 16,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
  },
  rowResultChild: {
    marginTop: 16,
  },
  searchResult: {
    flex: 1,
    width: "100%",    
    alignItems: "center",    
    overflow: "hidden",
  },
  topNavBarBg: {
    backgroundColor: Color.gray_100,
    overflow: "hidden",
  },
  kprKamparParent: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 12,
  },
  kpr: {
    fontSize: FontSize.size_base,
    fontWeight: "600",
    fontFamily: FontFamily.interBold,
  },
  kampar: {
    fontSize: FontSize.size_xs,
    fontWeight: "300",
    fontFamily: FontFamily.interLight,
  },
  kprKampar: {
    position: "relative",
    color: Color.dimgray_200,
    textAlign: "left",
    width: 97,
  },
  jbLarkinLarContainer: {
    position: "relative",
    color: Color.dimgray_200,
    textAlign: "right",
    width: 105,
    marginLeft: 94,
  },
  containerFromTo: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginHorizontal: 32,
    marginTop: 21,
    paddingLeft: 80,
  },
  from: {
    position: "relative",
    fontSize: FontSize.size_base,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "left",
  },
  fromWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  frameItem: {
    position: "relative",
    width: 193,
    height: 38,
    marginLeft: 14,
  },
  to: {
    position: "relative",
    fontSize: FontSize.size_base,
    fontWeight: "500",
    fontFamily: FontFamily.interMedium,
    color: Color.black,
    textAlign: "right",
  },
  toWrapper: {
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    marginLeft: 14,
  },
  resultTitle: {
    textAlign: 'center'
  },
  titleSummary: {
    borderWidth: 1,
    borderColor: "rgba(89, 27, 27, 0.05)",
    borderRadius: Border.br_base,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tripTitle: {
    padding: 10,
    fontFamily: FontFamily.interBold,
    fontSize: FontSize.size_base,
    textAlign: 'center',
    width: '100%',
  },
  coachNameContainer: {
    //width: '100%',
    //textAlign: 'center',
    //paddingLeft: 30,
  }
});

export default SearchResult;
